import { SIGN_IN_URL } from "@authentic/loyalty-pwa/Pages/unauthenticated/sign-in/sign-in.strings";
import { Fragment, h } from "preact";
import { Link } from "preact-router";
import { PRIVACY_POLICY_URL } from "../privacy-policy/privacy-policy.strings";

export const LA_ROCHE_POSAY_URL = '/la-roche-posay';
export const LA_ROCHE_POSAY_HEADER = 'La Roche Posay Terms & Conditions';
export const LA_ROCHE_POSAY_BODY = (
	<Fragment>
		<ol>
			<li>Information on how to enter the Promotion and the prize form part of these Conditions of Entry. </li>
			<li>
				Entry into this Promotion is deemed to be acceptance of these Conditions of Entry. The Promotion is open to individuals who: i. are residents of New Zealand and ii. are not employees (or immediate family of such employees) of the Promoters, or any of their related corporations or any of the agencies associated with the Promotion. Immediate family members means any of the following: spouse, ex-spouse, de-facto spouse, child or step-child, parent, step-parent, grandparent, step-grandparent, uncle, aunt, niece, nephew, brother, sister, step-brother, step-sister or 1st cousin.
				<ul>
					<li>members of the Living Rewards loyalty programme</li>
				</ul>
			</li>
			<li>By entering this Promotion, Eligible Participants agree to be bound by these terms and conditions. The Promoter reserves the right to verify the validity of entries and to disqualify any entrant who tampers with the entry process or who submits an entry that is not in accordance with these Conditions of Entry. It is a condition of entry that Eligible Participants are able to accept the prizes as stated. Inability to accept the prize as stated, on the dates indicated, will deem the participant an ineligible entrant and the prize won will be forfeited.</li>
			<li>Entry Restrictions: Open only to New Zealand residents aged 18 years or older who are registered holders of a Living Rewards card (and who have a valid phone number or email address associated with their membership) or who sign up to the Living Rewards Loyalty Program during the Promotion Period (but before making a Qualifying Purchase), excluding directors, management and employees (and the immediate families of directors, management and employees) of the Promoter, or of directors, management and employees (and the immediate families of directors, management and employees of Unichem and Life Pharmacy.</li>
			<li>Promoters: L’Oreal Building B Level 2/600 Great South Road, Ellerslie, Auckland, 1051 and Green Cross Health Millennium Centre, Ground Floor, Building B, 602 Great South Road, Ellerslie, Auckland
Private Bag 11 906, Ellerslie, Auckland 1542.</li>
			<li>Promotion Period: 14th August – 10th September.</li>
			<li>Information regarding the offer and how to enter forms part of these Conditions. By entering, entrants accept these Conditions.</li>
			<li>To be eligible to enter, individuals must purchase any 2 La Roche-Posay Products in one transaction at participating Unichem or Life pharmacy or online at www.lifepharmacy.co.nz during the Promotional Period and have their Living Rewards card scanned or provide their Living Rewards membership number at the time of purchase.</li>
			<li>Multiple entries permitted. Eligible entrants will automatically receive one (1) entry into the Promotion for each qualifying transaction (regardless of the number of Eligible Products purchased in excess of one (1) in that transaction).</li>
			<li>The Promoter is not responsible for any entries which are not completed due to technical difficulties with the entry mechanism.</li>
			<li>Entries are deemed to be received at the time of receipt into the Living Rewards database. Should an entrant's personal information change during the Promotion Period, it is the entrant's responsibility to notify Living Rewards by signing in to livingrewards.co.nz and following the steps to update their details, or by visiting a Unichem or Life Pharmacy store and speaking to a staff member, or by contacting 0800 762 672 or <Link href="mailto:hello@livingrewards.co.nz">hello@livingrewards.co.nz</Link>. </li>
			<li>1 prize includes a 9,000 Living Rewards Points which is equivalent to $600 spending money at Life Pharmacy or Unichem Stores with a recommended retail price of NZ$600 including GST. The value of the Prize is accurate and based upon the recommended retail value of the Prizes at 21/07/2023. The Promoter accepts no responsibility for any variation in the value of the Prize after that date.</li>
			<li>A Prize is not exchangeable or transferable and will be couriered to the winner after verification of address. </li>
			<li>If the specified Prize becomes unavailable due to circumstances beyond the control of the Promoter, the Promoter may substitute a Prize of like or equal value.</li>
			<li>1 random electronic prize draws will be conducted by Green Cross Health Limited at Living Rewards, Green Cross Health, 602 Great South Road, Ellerslie, Auckland 1542 7 DAYS AFTER PROMOTION END DATE. If a winner is deemed by the Promoter in its sole discretion not to comply with these Conditions, their entry will be discarded and Green Cross Health Limited will conduct a further random electronic prize draw. Prize winners will be notified by phone and email; and advertised on www.facebook.com/livingrewards from 7 DAYS AFTER PROMOTION END DATE. All reasonable attempts will be made to contact each winner. If a winner cannot be contacted within 7 days, that winner will forfeit any right to the Prize.</li>
			<li>The Promoter’s decision is final and no correspondence will be entered into.</li>
			<li>By accepting a prize a winner agrees to the use of their name, likeness and location in the Promoters advertising material and to co-operate in all advertising, marketing and publicity material and activities the Promoter may produce or arrange.</li>
			<li>Retailers participating in this Promotion are Unichem Pharmacy and Life Pharmacy bannered outlets located in New Zealand.</li>
			<li>Immediately upon submission by an entrant, their entry/entries become the sole property of Living Rewards operated by Green Cross Health.</li>
			<li>Green Cross Health Limited has collected all entrants’ personal information on signing up to the Living Rewards Loyalty Program pursuant to Green Cross Health Limited privacy policy (<Link target='_blank' href="http://www.greencrosshealth.co.nz/privacy-policy">http://www.greencrosshealth.co.nz/privacy-policy</Link>).</li>
			<li>
				Green Cross Health may share this information with the Promoter solely for the purpose of administering this Promotion, which will include:
				<ul>
					<li>contacting and verifying the winners; and</li>
					<li>advertising the name and location of the winners of the promotion following its conclusion.</li>
				</ul>
			</li>
			<li>The Promoter and Green Cross Health Limited may disclose entrants' Personal Information (contact details only) to their contractors and agents to assist in conducting this Promotion and fulfilling prize distribution. </li>
			<li>Entrants may view Living Rewards’ data protection statement by visiting <Link target='_blank' href="https://www.livingrewards.co.nz/data-protection">https://www.livingrewards.co.nz/data-protection</Link></li>
			<li>The Promoter takes measures to ensure compliance with its obligations under the Privacy Act 1993.</li>
			<li>If the competition does not run for its duration due to, but not limited to, computer viruses, tampering, unauthorized intervention, fraud, technical failures or any other cause beyond the Promoters’ control that corrupts or affects administration, security, fairness or proper conduct of the competition, the Promoter reserves the right in their sole discretion to disqualify any individual who tampers with the entry process. The Promoter will not be responsible for: (a) Malfunction, delay or traffic congestion on any telephone network or line, computer on-line system, servers or providers, computer equipment, software, or website; (b) Failure of any entry to be received by the Promoter as a result of those matters; nor (c) Any injury or damage to entrants or any other person related to or resulting from participation in the competition or downloading any materials relating to the competition. </li>
			<li>The Promoter accepts no responsibility for any late, lost or misdirected entries due to technical disruptions, network congestion or for any other reason. </li>
			<li>In the case of the intervention of any outside act, agent or event which prevents or significantly hinders the Promoters’ ability to proceed with the Promotion on the dates and in the manner described in these Conditions of Entry, including but not limited to vandalism, power failures, tempests, natural disasters, acts of God, civil unrest, strike, war, act of terrorism, the Promoter may in its absolute discretion cancel the Promotion and recommence it from the start on the same conditions.</li>
			<li>The Promoter is not responsible for any incorrect or inaccurate information, either caused by the user or for any of the equipment or programming associated with or utilised in this Promotion, or for any technical error, or any combination thereof that may occur in the course of the administration of this Promotion including any omission, interruption, deletion, defect, delay in operation or transmission, communications line or telephone, mobile or satellite network failure, theft or destruction or unauthorised access to or alteration of claims.</li>
			<li>The Promoter reserves the right in their sole discretion to disqualify any individual who the Promoter has reason to believe has breached any of these Conditions of Entry, or engaged in any unlawful or other improper misconduct calculated to jeopardise the fair and proper conduct of the Promotion. The Promoters’ legal rights to recover damages or other compensation from such an offender are reserved.</li>
			<li>The Promoter and its associated agencies and companies assume no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission; communications line failure, theft or destruction or unauthorised access to, or alteration of entries, and reserves the right to take any action that may be available. </li>
			<li>If for any reason this Promotion is not capable of running as planned, including due to infection by computer virus, bugs, tampering, unauthorised intervention, fraud, technical failures or any causes beyond the reasonable control of the Promoter, which corrupt or affect the administration, security, fairness or integrity or proper conduct of this promotion, the Promoter reserves the right in their sole discretion to disqualify any individual who tampers with the entry process, take any action that may be available, and to cancel, terminate, modify or suspend the Promotion, subject to any written direction given under New Zealand Regulation. </li>
			<li>The Promoter assumes no responsibility for: I. any error, omission, interruption, or delay in the operation or transmission of any communication sent to (or by) the Promoter or any Entrant whether caused by problems with communication networks or lines, computer systems, software or internet service providers, congestion on any carrier network or otherwise; or II. Any technical difficulties with the entry mechanism and does not warrant that the entry mechanism will be available at all times; or III. Any theft, destruction or unauthorized access to, or alteration of such communications; or IV. Any problem with, or technical malfunction of, any computer system or other equipment used for the conduct of the Promotion; or V. any incorrect or incomplete information which may be communicated in the course of the administering this Promotion. CAUTION: Any attempt to deliberately undermine the legitimate operation of this Promotion may be a violation of criminal and civil laws and should such an attempt be made, whether successful or not, the Promoter reserves the right to seek damages to the fullest extent permitted by law. </li>
			<li>Green Cross Health Limited has collected all entrants’ personal information on signing up to the Living Rewards Loyalty Program pursuant to Green Cross Health Limited privacy policy (<Link target='_blank' href="http://www.greencrosshealth.co.nz/privacy-policy">http://www.greencrosshealth.co.nz/privacy-policy</Link>).</li>
			<li>
				Green Cross Health may share this information with a third party solely for the purpose of administering this Promotion, which will include:
				<ul>
					<li>Contacting and verifying the winners; and</li>
					<li>Advertising the name and location of the winners of the promotion following its conclusion.</li>
				</ul>
			</li>
			<li>The Promoter and Green Cross Health Limited may disclose entrants' Personal Information (contact details only) to their contractors and agents to assist in conducting this Promotion.</li>
			<li>Entrants may view Living Rewards’ data protection statement by visiting <Link target='_blank' href="https://www.livingrewards.co.nz/data-protection">https://www.livingrewards.co.nz/data-protection</Link></li>
			<li>The Promoter takes measures to ensure compliance with its obligations under the Privacy Act 1993.</li>
		</ol>
	</Fragment>
);