import { h } from 'preact';
import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';

export const WarningIcon = () => (
  <svg
    width="22px"
    height="20px"
    viewBox="0 0 22 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Incorrect-Username/Password----Phase-2"
        transform="translate(-409.000000, -931.000000)"
        fill="#000000"
        fill-rule="nonzero"
      >
        <g id="notification" transform="translate(378.000000, 915.000000)">
          <g id="alert-icon" transform="translate(31.000000, 16.000000)">
            <path
              d="M2.30974985,20 L19.6902501,20 C21.467539,20 22.5754594,18.0361605 21.6868149,16.4721446 L12.9965648,1.17301191 C12.1079203,-0.391003969 9.89207966,-0.391003969 9.00343522,1.17301191 L0.313185076,16.4721446 C-0.575459361,18.0361605 0.532460977,20 2.30974985,20 Z M11,11.7683375 C10.365254,11.7683375 9.84591631,11.2391592 9.84591631,10.5923857 L9.84591631,8.24048214 C9.84591631,7.59370866 10.365254,7.06453035 11,7.06453035 C11.634746,7.06453035 12.1540837,7.59370866 12.1540837,8.24048214 L12.1540837,10.5923857 C12.1540837,11.2391592 11.634746,11.7683375 11,11.7683375 Z M12.1540837,16.4721446 L9.84591631,16.4721446 L9.84591631,14.1202411 L12.1540837,14.1202411 L12.1540837,16.4721446 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
