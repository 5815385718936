// NOTHING IN THIS CLASS will work due to how the mixins work
// This is just to keep Typescript happy for the sake of developers.

import { FetchRequest } from "../../util";

// If you add a shared var or function, it must go in here and then the implementation in cheetah.service
export class CheetahShared {
  protected base_url: string = '';
  protected client_id: string = '';
  protected client_secret: string = '';
  protected access_token?: string;

  protected async fetch({ url, method = 'POST', body, headers = {}, queryParams = {}}: FetchRequest, isPublic = true): Promise<Response> {
		  throw new Error('Method not implemented.');
  }
  
  protected setAccessToken(access_token: string) {
  		throw new Error('Method not implemented.');
  }
}