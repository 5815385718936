import { colors } from "@authentic/loyalty-pwa/design-system/style-guide/color";
import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const MoreAboutPageStyled = styled.div`
  max-width: ${Breakpoints.XL};
  position: relative;
  margin: 0 auto;
  padding: 3rem 0;
  color: #666666;
  font-family: BrandonGrotesque-Medium;
  font-size: 2rem;

  h1,
  h2 {
    color: ${colors.black};
    text-transform: uppercase;
  }
  h1 {
    text-align: center;
    font-weight: bold;
    font-size: 2.5rem;
    font-family: BrandonGrotesque-Bold;
  }
  h2 {
    font-family: OpenSans-Bold;
    font-size: 2.3rem;
    margin: 0;
  }
  img {
    max-width: 100%;
  }
  p:first-of-type {
    margin-top: 0;
  }
  div {
    margin-top: 5rem;
  }
  a {
    font-family: BrandonGrotesque-Medium;
    font-size: 2rem;
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    padding: 3rem 3rem;
    h1 {
      font-size: 2.25rem;
    }

    h2 {
      text-align: center;
    }
  }
	@media screen and (max-width: ${Breakpoints.S}) {
		img {
			max-width: 100%
		}
	}
`;
