// String text
export const VERIFICATION_TITLE = "YOU'RE ALMOST THERE!";
export const DESCRIPTION_TEXT_PARAGRAPH_1 = "Please check if you have received our verification email. If not, click the ‘resend verification email’ button below.";
export const DESCRIPTION_TEXT_PARAGRAPH_2 = "You will be unable to login to your Living Rewards account until this step has been completed.";

// String buttons
export const RESEND_BTN_TEXT = "Resend verification email";
export const BACK_TO_SIGNIN_BTN_TEXT = "Back to sign in";

export const VERIFICATION_EMAIL_URL = '/verification';
