import styled from 'styled-components';
import { DefaultTextFieldStyle } from './text-field.config';
import { Text2 } from '@authentic/loyalty-pwa/design-system/style-guide/typography';
import { colors } from '@greencross/overrides/design-system/style-guide/color';

export const Input = styled.input<{ customStyle: DefaultTextFieldStyle }>`
  width: 100%;
  height: ${(p) => p.customStyle.height};
  border: none;
  border-radius: 0;
  padding: ${(p) => p.customStyle.padding};
  outline: none;
  :disabled {
    cursor: not-allowed;
    color: ${colors.text};
  }
`;

export const InputPrefix = styled.div`
  width: 10%;
  display: flex;
  align-items: center;
`;

export const InputSuffix = styled.div`
  width: 10%;
  display: flex;
  align-items: center;
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const InputGroup = styled.div<{
  error?: any;
  customStyle: DefaultTextFieldStyle;
}>`
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: ${({ customStyle, error }) =>
    error ? 'red' : customStyle.borderColor};
  display: flex;
`;

export const TextFieldStyled = styled.div<{ error?: any }>`
  margin-bottom: 42px;
  position: relative;
  ${Text2} {
    position: absolute;
    top: 10px;
    font-size: 1.625rem;
    left: 0;
    transition: all 0.15s ease-in-out;
    color: ${({ error }) => (error ? 'red' : colors.black)};
    &.active {
      top: -22px;
      font-size: 1.25rem;
      background-color: transparent;
    }
  }
  ${Input} {
    background: transparent;
    font-size: 1.625rem;
    &::placeholder {
      color: #000;
    }
  }
  &.error {
    > div:nth-child(2) {
      border-bottom: 2px solid red;
    }
    label {
      color: red;
    }
  }
`;

export const ErrorStyled = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 6px;
`;

export const EndAdornment = styled.div`
  position: absolute;
  top: 40%;
  right: 10px;
  transform: translateY(-40%);
`;
