import styled from 'styled-components';
import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';
import { Breakpoints } from '@greencross/utils/breakpoints';

export const HeaderContentStyled = styled.div`
  background-color: ${colors.black};
`;

export const HeaderStyled = styled.div`
  max-width: ${Breakpoints.ML};
  margin: 0 auto;
  position: relative;
  justify-content: space-between;
  display: flex;
  @media screen and (max-width: ${Breakpoints.XL}) {
    padding: 10px 20px 20px 10px;
  }
`;

export const LogosStyled = styled.div`
  flex-grow: 1;
  display: flex;
  img {
    max-height: 7rem;
    padding: 10px;
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    img {
      max-height: 80px;
      object-fit: scale-down;
    }
  }
  @media screen and (max-width: ${Breakpoints.XS}) {
    img {
      max-height: 55px;
    }
  }
`;

export const LoginButtonStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  text-align: right;
  text-transform: uppercase;
  color: ${colors.white};
  top: 20px;
  font-weight: 900;
  font-size: 2rem;
  font-family: BrandonGrotesque-Bold;
  a {
    color: ${colors.white};
    letter-spacing: 0px;
    font-family: BrandonGrotesque-Bold;
    position: relative;
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    top: 0;
    font-size: 1.5rem;
    justify-content: space-around;
    a {
      top: -1rem;
      white-space: nowrap;
    }
  }
`;

export const UserContainerStyled = styled.div`
  display: flex;
  font-weight: normal;
  font-family: BrandonGrotesque-Medium;
  .join-button {
    padding-left: 12px;
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    div {
      display: none;
    }
  }
`;

export const MenuButtonContainerStyled = styled.div`
  cursor: pointer;
  height: 3rem;
  width: 4rem;
  margin-left: auto;
`;

export const MenuButtonStyled = styled.div`
  position: relative;
  top: 15px;

  &,
  &::before,
  &::after {
    cursor: pointer;
    display: block;
    background-color: #fff;
    height: 0.3rem;
    width: 3.5rem;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 0px;
  }

  &::before {
    content: '';
    margin-top: -1rem;
    position: absolute;
  }

  &::after {
    content: '';
    margin-top: 1rem;
    position: absolute;
  }

  &.active {
    height: 0;
  }
  &.active::before {
    margin-top: 0px;
    transform: rotate(405deg);
    height: 0.3rem;
  }
  &.active::after {
    margin-top: 0px;
    transform: rotate(-405deg);
    height: 0.3rem;
  }

  @media screen and (min-width: ${Breakpoints.XL}) {
    display: none;
  }
`;
