export const colors = {
  primary: '#256AF6',
  primaryVariant: '#e0e3f7',
  secondary: '#0D0729',
  secondaryVariant: '#BE81EB',
	// we need meaningful color names
	white: '#FFFFFF', // surface, background
  black: '#000000',
  error: '#c2302f',
  gray: '#a4a4a4', // borders
  grayVariant1: '#f1f1f1',
  grayVariant2: '#D8D8D8',
  grayVariant3: '#CFCDD4',
  grayVariant4: '#D4D8E3',
  grayVariant5: '#F6F6F6',
  grayVariant6: '#E7EAF3',
  green: '#018647',
  pink: 'ff00c1',
  steelBlue: '#4583ab',
  steelBlueVariant1: '#4488a4',
  steelBlueVariant2: '#4583ab',
  steelBlueVariant3: '#457cb1',
  darkSlateBlue: '#2a3387',
  darkSlateBlueVariant1: '#2e6daa',
  fuchsia: '#ff00c2',
  accentPurple: '#BE81EB'
};
