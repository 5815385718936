import { FunctionalComponent, h } from 'preact';
import { useContext, useEffect } from 'preact/hooks';
import {
  ForgotPasswordContainer,
  SignInStyle,
  SignUpContainer,
} from '@authentic/loyalty-pwa/design-system/components/cheetah/unauthenticated/sign-in/sign-in.style';

import {
  DefaultChangeSignInFormConfig,
  DefaultChangeSignInFormProps,
} from '@authentic/loyalty-pwa/design-system/components/cheetah/unauthenticated/sign-in/sign-in.config';

import { SubmitHandler, useForm } from 'react-hook-form';
import { UserServiceContext } from '@authentic/loyalty-pwa/providers';

import { link } from '@authentic/loyalty-pwa/util';
import {
  TextField,
  TextFieldType,
} from '@authentic/loyalty-pwa/design-system/components/base/text-field/text-field.component';
import {
  FormLayout,
  Layout,
} from '@authentic/loyalty-pwa/design-system/style-guide';
import {
  Heading1,
  Link1,
} from '@authentic/loyalty-pwa/design-system/style-guide/typography';
import { FORGOT_PASSWORD_URL } from '@authentic/loyalty-pwa/design-system/components/cheetah/unauthenticated/sign-in/sign-in.strings';
import {
  Button,
  ButtonType,
  ButtonVariant,
} from '@greencross/overrides/design-system/components/base/button/button.component';
import { SIGNUP_URL } from '@greencross/pages/unauthenticated/sign-up/sign-up.strings';

export type SignInFormProps = {
  onSubmit?: (data: any) => void;
  onCancel?: () => void;
  forgotPasswordAnalyticsId?: string;
  signUpAnalyticsId?: string;
};

export const SignInForm: FunctionalComponent<SignInFormProps> = (props) => {
  const userService = useContext(UserServiceContext);
  let changeSignInFormConfig: DefaultChangeSignInFormProps =
    DefaultChangeSignInFormConfig;

  type FormValues = {
    [x: string]: string;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm();

  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
    userService
      ?.login({
        email: data.email,
        password: data.password,
      })
      .then((res) => {
        if (props.onSubmit) {
          if (res.code === 4005) {
            setError('email', { message: '' });
            setError('password', { message: res.message });
          }
          props.onSubmit(res);
        }
      });
  };

  const onError = (error: any) => {
    console.log('error: ', error);
  };

  const onFocusInput = (name: string, e: any) => {
    document
      .getElementsByClassName(`${name}-field`)[0]
      .getElementsByTagName('label')[0]
      .classList.add('active');
  };

  return (
    <SignInStyle>
      {changeSignInFormConfig.config.heading.text && (
        <Heading1>{changeSignInFormConfig.config.heading.text}</Heading1>
      )}

      <FormLayout
        onSubmit={handleSubmit(onSubmit, onError)}
        customStyle={{
          headingAlign: changeSignInFormConfig.style.heading.textAlign,
        }}
        autoComplete='off'
      >
        <input name='email' class='visually-hidden' type='text' />
        <input name='fake_password' class='visually-hidden' type='password' />
        <TextField
          error={errors?.email}
          defaultValue={''}
          id='email'
          control={control}
          name='email'
          onFocus={onFocusInput.bind(this, 'email')}
          label={changeSignInFormConfig.config.inputs.email.label}
          rules={{
            required: {
              value: true,
              message:
                changeSignInFormConfig.config.inputs.email.errors.required,
            },
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: changeSignInFormConfig.config.inputs.email.errors.format,
            },
          }}
        />

        <TextField
          error={errors?.password}
          control={control}
          variant='password'
          name='password'
          id='password'
          onFocus={onFocusInput.bind(this, 'password')}
          label={changeSignInFormConfig.config.inputs.password.label}
          rules={{
            required: {
              value: true,
              message:
                changeSignInFormConfig.config.inputs.password.errors.required,
            },
          }}
          type={TextFieldType.PASSWORD}
        />

        <Layout cg={24}>
          <Button variant={ButtonVariant.PRIMARY} type={ButtonType.SUBMIT}>
            {changeSignInFormConfig.config.submitButton.text}
          </Button>
        </Layout>

        <ForgotPasswordContainer>
          <Link1
            href={link(FORGOT_PASSWORD_URL)}
            data-analytics={props.forgotPasswordAnalyticsId}
          >
            {changeSignInFormConfig.config.forgotPassword.label}
          </Link1>
        </ForgotPasswordContainer>
        <SignUpContainer>
          <p>{changeSignInFormConfig.config.signup.subtitle.label}</p>
          <Link1 href={SIGNUP_URL} data-analytics={props.signUpAnalyticsId}>
            {changeSignInFormConfig.config.signup.button.label}
          </Link1>
        </SignUpContainer>
      </FormLayout>
    </SignInStyle>
  );
};
