import styled from "styled-components";
import {
  ButtonContainer,
  Description,
  Title
} from "@greencross/overrides/design-system/components/base/cta-info/variant2/cta-info.variant2.style";
import {Card} from "@greencross/components/card/card.style";
import {Breakpoints} from "@greencross/utils/breakpoints";
import {ButtonStyled} from "@greencross/overrides/design-system/components/base/button/button.style";

export const PageNotFoundStyled = styled.div`
  ${Title} {
    font-family: OpenSans-Bold,serif;
    font-size: 3.3125rem;
    font-weight: bold;
    letter-spacing: 0;
  }
  ${Description} {
    padding-top: 1.375em;
    font-size: 1.375rem;
    margin-bottom: 1.6875em;
  }
  ${Card} {
    padding: 8.3125em 14.8156em;
    max-height: 604px;
    @media screen and (max-width: ${Breakpoints.S}) {
      padding: 6em 3em;
      margin: 6em auto;
    }
  }
  ${ButtonContainer} {
    padding: 1.6875em 0;
    &.links-container {
      a {
        font-size: 1.375rem;
      }
      padding: 0.909em 5.091em;
      @media screen and (max-width: ${Breakpoints.S}) {
        padding: 1.182em 0;
        ${ButtonStyled} {
          font-size: 1.375rem;
        }
      }
    }
    @media screen and (max-width: ${Breakpoints.S}) {
      ${ButtonStyled} {
        padding-left: 0;
        padding-right: 0;
        font-size: 1.625rem;
      }
    }
  }
  & .content-container {
    position: relative;
    padding: 9.727em 0;
    @media screen and (max-width: ${Breakpoints.S}) {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
  }
`;