import { Fragment, FunctionalComponent, h } from 'preact';
import { BackgroundImage } from '../../../components/background-image/background-image.component';
import { ProfilePageContentStyled, ProfilePageStyled } from './profile.style';
import { getImageSrc } from '@authentic/loyalty-pwa/util';
import { BaseLayout } from '../../shared/base-layout/base-layout';
import { ProfileForm } from './form/profile-form';

interface ProfileProps {}
export const ProfilePage: FunctionalComponent<ProfileProps> = (props) => {
  return (
    <ProfilePageStyled className='profile-page'>
      <BaseLayout>
        <ProfilePageContentStyled className='profile-content'>
          {
            <BackgroundImage
              desktop={`url(${getImageSrc(
                '/assets/images/backgrounds/background-profile-desktop.jpg'
              )})`}
              mobile={`url(${getImageSrc(
                '/assets/images/backgrounds/background-profile-mobile.jpg'
              )})`}
            ></BackgroundImage>
          }

          <ProfileForm></ProfileForm>
        </ProfilePageContentStyled>
      </BaseLayout>
    </ProfilePageStyled>
  );
};
