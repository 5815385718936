import { h } from 'preact';
import styled, { createGlobalStyle } from 'styled-components';
import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';
import { fontFamily } from '@authentic/loyalty-pwa/design-system/style-guide/font';

// This is a Global Style of the Abbvie portal custom template
export const GlobalStyle = createGlobalStyle`
	@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800&display=swap');
  body {
    margin: 0;
    padding: 0;
    font-family: ${fontFamily};
		color: ${colors.secondary};
  }

	a {
		color: inherit;
	}

	* {
		box-sizing: border-box;
	}
`;
