import { BaseLayout } from '@greencross/pages/shared/base-layout/base-layout';
import { FunctionalComponent, h } from 'preact';
import { getImageSrc } from '@authentic/loyalty-pwa/util';
import { BackgroundImage } from '@greencross/components/background-image/background-image.component';
import { Title } from '@greencross/overrides/design-system/components/base/cta-info/variant2/cta-info.variant2.style';

import { Card } from '@greencross/components/card/card.style';
import {
  CHANGE_PASSWORD_TITLE,
  FIELDS,
  SUCCESSFUL_MESSAGE,
  VALIDATION_MESSAGES,
} from './change-password.strings';
import {
  ChangePasswordPageStyled,
  DescriptionStyled,
} from '@greencross/pages/unauthenticated/change-password/change-password.style';
import {
  TextField,
  TextFieldType,
} from '@authentic/loyalty-pwa/design-system/components/base/text-field/text-field.component';
import {
  Button,
  ButtonType,
  ButtonVariant,
} from '@greencross/overrides/design-system/components/base/button/button.component';
import { useCallback, useContext, useEffect, useState } from 'preact/hooks';
import { SubmitHandler, useForm } from 'react-hook-form';
import { PasswordStrength } from '@greencross/components/password-strength/password-strength.component';
import { Paragraph1 } from '@authentic/loyalty-pwa/design-system/style-guide/typography';
import { UserServiceContext } from '@authentic/loyalty-pwa/providers';
import zxcvbn from 'zxcvbn';
import { PASSWORD_DISCLAIMER } from '../sign-up/form/sign-up-form.config';

export type FormValues = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

interface ChangePasswordPageProps {}

export const ChangePasswordPage: FunctionalComponent<
  ChangePasswordPageProps
> = () => {
  const [displaySuccessfulMessage, setDisplaySuccessfulMessage] =
    useState(false);
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    setError,
    getValues,
    reset,
    formState: { errors },
  } = useForm();
  const userService = useContext(UserServiceContext);

  const onSubmit: SubmitHandler<any> = useCallback(
    async (data: FormValues) => {
      setLoading(true);
      try {
        const response = await userService?.changePasswordProfile({
          current_password: data.currentPassword,
          password: data.newPassword,
          password_confirmation: data.confirmPassword,
        });
        console.log('Response', response);
        if (response?.success) {
          setDisplaySuccessfulMessage(true);
          resetForm();
        } else {
          if (response?.code === 4078) {
            setError(FIELDS.CURRENT_PASSWORD.ID, {
              message: response?.message,
            });
          }
          if (
            response?.code === 4400 ||
            response?.code === 6947 ||
            response?.code === 4412
          ) {
            setError(FIELDS.NEW_PASSWORD.ID, { message: response?.message });
          }
        }
      } catch (error) {
        onError(error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading]
  );

  const onError = useCallback((error: any) => {
    console.log('On Error', error);
  }, []);

  const handleOnChangePassword = useCallback(
    ($event: any) => {
      const { value } = $event.target;
      setPassword(value);
    },
    [setPassword]
  );

  const verifyPassword = useCallback((value: string) => {
    const currentPassword = getValues(FIELDS.CURRENT_PASSWORD.ID);
    if (value === currentPassword) {
      return VALIDATION_MESSAGES.SAME_OLD_PASSWORD;
    }
    return zxcvbn(value).score >= 2;
  }, []);

  const verifySamePassword = useCallback((value: string) => {
    const newPassword = getValues(FIELDS.NEW_PASSWORD.ID);
    if (value !== newPassword) {
      return VALIDATION_MESSAGES.DIFFERENT_PASSWORD;
    }
  }, []);

  const resetForm = () => {
    reset({
      [FIELDS.CURRENT_PASSWORD.ID]: '',
      [FIELDS.NEW_PASSWORD.ID]: '',
      [FIELDS.CONFIRM_PASSWORD.ID]: '',
    });
  };

  useEffect(() => {
    if (displaySuccessfulMessage) {
      setTimeout(() => {
        setDisplaySuccessfulMessage(false);
      }, 10000);
    }
  }, [displaySuccessfulMessage]);

  return (
    <ChangePasswordPageStyled>
      <BaseLayout>
        <div className='content-container'>
          <BackgroundImage
            desktop={`url(${getImageSrc(
              '/assets/images/backgrounds/background-password-desktop.jpg'
            )})`}
            mobile={`url(${getImageSrc(
              '/assets/images/backgrounds/background-password-mobile.jpg'
            )})`}
          />
          <Card>
            <Title>{CHANGE_PASSWORD_TITLE}</Title>
            <div className='change-password-container'>
              <Paragraph1>* Mandatory fields</Paragraph1>
              {
                <form
                  // @ts-ignore
                  onSubmit={handleSubmit(onSubmit, onError)}
                  autoComplete='off'
                >
                  <TextField
                    label={FIELDS.CURRENT_PASSWORD.LABEL}
                    name={FIELDS.CURRENT_PASSWORD.ID}
                    id={FIELDS.CURRENT_PASSWORD.ID}
                    type={TextFieldType.PASSWORD}
                    variant='password'
                    control={control}
                    error={errors[FIELDS.CURRENT_PASSWORD.ID]}
                    disabled={loading}
                    rules={{
                      required: {
                        value: true,
                        message: VALIDATION_MESSAGES.REQUIRED,
                      },
                    }}
                  />

                  <TextField
                    label={FIELDS.NEW_PASSWORD.LABEL}
                    name={FIELDS.NEW_PASSWORD.ID}
                    id={FIELDS.NEW_PASSWORD.ID}
                    type={TextFieldType.PASSWORD}
                    variant='password'
                    control={control}
                    error={errors[FIELDS.NEW_PASSWORD.ID]}
                    onChange={handleOnChangePassword}
                    disabled={loading}
                    rules={{
                      required: {
                        value: true,
                        message: VALIDATION_MESSAGES.REQUIRED,
                      },
                      validate: verifyPassword,
                    }}
                    description={<PasswordStrength password={password} />}
                  />

                  <TextField
                    label={FIELDS.CONFIRM_PASSWORD.LABEL}
                    name={FIELDS.CONFIRM_PASSWORD.ID}
                    id={FIELDS.CONFIRM_PASSWORD.ID}
                    type={TextFieldType.PASSWORD}
                    variant='password'
                    control={control}
                    error={errors[FIELDS.CONFIRM_PASSWORD.ID]}
                    disabled={loading}
                    rules={{
                      required: {
                        value: true,
                        message: VALIDATION_MESSAGES.REQUIRED,
                      },
                      validate: verifySamePassword,
                    }}
                  />

                  <DescriptionStyled className='password-disclaimer' dangerouslySetInnerHTML={{ __html: PASSWORD_DISCLAIMER }}></DescriptionStyled>

                  <Button
                    variant={ButtonVariant.PRIMARY}
                    type={ButtonType.SUBMIT}
                    disabled={loading}
                  >
                    Change Password
                  </Button>

                  {displaySuccessfulMessage && (
                    <DescriptionStyled className='center'>
                      {SUCCESSFUL_MESSAGE}
                    </DescriptionStyled>
                  )}
                </form>
              }
            </div>
          </Card>
        </div>
      </BaseLayout>
    </ChangePasswordPageStyled>
  );
};
