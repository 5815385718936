import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const ForgotPasswordPageStyled = styled.div``;

export const ForgotPasswordPageContentStyled = styled.div`
  position: relative;
  min-height: 729px;
  padding: 136px 0;
  p {
    font-size: 1.9rem;
    text-align: center;
  }
  .background-image {
    background-color: #F89DAB;
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    padding: 63px 0;
    display: flex;
    align-items: center;
  }
`;
