import {Fragment, FunctionalComponent, h} from "preact";
import {BaseLayout} from "@greencross/pages/shared/base-layout/base-layout";
import {Card} from "@greencross/components/card/card.style";
import {
		ButtonContainer,
		Description,
		Title
} from "@greencross/overrides/design-system/components/base/cta-info/variant2/cta-info.variant2.style";
import {
		Button,
		ButtonType,
		ButtonVariant
} from "@greencross/overrides/design-system/components/base/button/button.component";
import {VerificationEmailStyled} from "@greencross/pages/unauthenticated/verification-email/verification-email.style";
import {useCallback, useContext, useEffect, useState} from "preact/hooks";
import {route} from "preact-router";
import {SIGN_IN_URL} from "@greencross/overrides/Pages/unauthenticated/sign-in/sign-in.strings";
import {getImageSrc} from "@authentic/loyalty-pwa/util";
import {BackgroundImage} from "@greencross/components/background-image/background-image.component";
import {UserServiceContext} from "@authentic/loyalty-pwa/providers";
import { BACK_TO_SIGNIN_BTN_TEXT, VERIFICATION_FAILED_TEXT, VERIFICATION_FAILED_TITLE, VERIFICATION_PROGRESS_TEXT, VERIFICATION_PROGRESS_TITLE, VERIFICATION_SUCCESS_TEXT, VERIFICATION_SUCCESS_TITLE } from "./verification-completed.strings";
import { UserAccountConfirmationResult } from "@authentic/loyalty-pwa/interfaces";
import { getQueryString } from "@greencross/utils/querystring";


export const VerificationCompletedPage: FunctionalComponent = () => {
	const [isValidConfirmationToken, setIsValidConfirmationToken] = useState<true|false|null>(null);
	const userService = useContext(UserServiceContext);
	const queryParams = getQueryString();

	useEffect(() => {
		if (queryParams.confirmation_token) {
			userService
				?.confirmationToken({ confirmationToken: queryParams.confirmation_token })
				.then((res: UserAccountConfirmationResult) => {
					if (res.success) {
						setIsValidConfirmationToken(true);
					} else {
						if (res.code === 6002 && res.name == 'already_confirmed') {
							setIsValidConfirmationToken(true);
						} else {
							setIsValidConfirmationToken(false);
						}
					}
				})
		} else {
			setIsValidConfirmationToken(false);
		}
	});


	const handleBackToSignIn = useCallback(() => {
			route(SIGN_IN_URL);
	}, []);

	return (
		<VerificationEmailStyled>
			<BaseLayout>
				<div className="content-container">
					<BackgroundImage
						desktop={`url(${getImageSrc('/assets/images/backgrounds/GXH_Background_Desktop_4_OldCouple_75c5d7.jpg')})`}
						mobile={`url(${getImageSrc('/assets/images/backgrounds/GXH_Background_Mobile_4_OldCouple_75c5d7.jpg')})`}
					/>
					<Card>
                        {isValidConfirmationToken === null && (
                            <Fragment>
                                <Title>{VERIFICATION_PROGRESS_TITLE}</Title>
                                <Description>{VERIFICATION_PROGRESS_TEXT}</Description>
                            </Fragment>
                        )}
                        {isValidConfirmationToken === true && (
                            <Fragment>
        						<Title>{VERIFICATION_SUCCESS_TITLE}</Title>
        						<div className="verification-email-container">
        							<Description>{VERIFICATION_SUCCESS_TEXT}</Description>
        							<ButtonContainer>
        								<Button variant={ButtonVariant.PRIMARY_OUTLINE} type={ButtonType.BUTTON} onClick={handleBackToSignIn}>
        									{BACK_TO_SIGNIN_BTN_TEXT}
        								</Button>
        							</ButtonContainer>
        						</div>
                            </Fragment>
                        )}
                        {isValidConfirmationToken === false && (
                            <Fragment>
                                <Title>{VERIFICATION_FAILED_TITLE}</Title>
                                <Description>{VERIFICATION_FAILED_TEXT}</Description>
                                <ButtonContainer>
                                    <Button variant={ButtonVariant.PRIMARY_OUTLINE} type={ButtonType.BUTTON} onClick={handleBackToSignIn}>
                                        {BACK_TO_SIGNIN_BTN_TEXT}
                                    </Button>
                                </ButtonContainer>
                          </Fragment>
                        )}
					</Card>
				</div>
			</BaseLayout>
		</VerificationEmailStyled>
	);
}