import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';
import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const FooterStyled = styled.ul`
  background-color: ${colors.lightblack};
  display: flex;
  font-weight: 900;
  font-size: 1.5rem;
  font-family: BrandonGrotesque-Bold;
  justify-content: space-evenly;
  margin: 0;
  padding: 10px 200px;
  > li {
    text-align: center;
    list-style: none;
    flex-grow: 1;
    > img {
      max-width: 230px;
      padding: 20px;
    }
  }
  > li.large {
    &.logos img:first-child {
      max-width: 230px;
    }
    &.logos img:last-child {
      max-width: 320px;
    }
    flex-grow: 3;
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    padding: 10px 0;
    flex-direction: column;
    > li.large.logos {
      display: flex;
      justify-content: center;
      img:first-child {
        max-width: 100px;
      }
      img:last-child {
        max-width: 175px;
      }
    }
  }
`;

export const FooterLogoListStyled = styled.ul`
  padding: 100px 50px;
  li {
    list-style: none;
    text-transform: uppercase;
    text-align: left;
    padding: 10px;
    color: ${colors.white};
  }
  img {
    max-width: 150px;
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    display: flex;
    padding: 0;
    li {
      margin: auto;
      font-weight: 900;
      font-size: 0.857rem;
      font-family: BrandonGrotesque-Bold;
    }
    img {
      max-width: 150px;
      width: 100%;
    }
  }
`;

export const RightSideStyled = styled.ul`
  color: ${colors.white};
  font-weight: medium;
  font-size: 1.5rem;
  font-family: 'BrandonGrotesque-Medium';
  padding: 50px;
  li {
    list-style: none;
    padding-top: 20px;
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    padding: 0;
    font-size: 1.125rem;
  }
`;

export const FooterMenuStyled = styled.ul`
  display: flex;
  font-family: BrandonGrotesque-Bold;
  justify-content: flex-end;
  padding-left: 0;
  li {
    font-weight: normal;
    font-size: 1.5rem;
    color: ${colors.orange};
    padding: 2px;
    a {
      font-size: 1.375rem;
      font-family: BrandonGrotesque-Bold;
      font-weight: 900;
      text-transform: uppercase;
      @media screen and (max-width: ${Breakpoints.XS}) {
        font-size: 0.9rem;
      }
    }
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    font-size: 1.125rem;
    justify-content: center;
  }
`;

export const SocialLinksStyled = styled.ul`
  display: flex;
  font-size: 1.5625rem;
  justify-content: flex-end;
  padding-left: 0;
  > li {
    a {
      color: ${colors.white};
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      margin: 10px;
      max-width: 25px;
    }
    list-style: none;
    padding: 10px;
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    font-size: 1.125rem;
    justify-content: center;
  }
`;


export const GXHLinksStyled = styled.ul`
  display: flex;
  font-weight: medium;
  font-size: 1.5625rem;
  justify-content: flex-end;
  padding-left: 0;
  > li {
    a {
      font-family: BrandonGrotesque-Medium;
      color: ${colors.white};
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      margin: 10px;
      max-width: 150px;
    }
    list-style: none;
    padding: 10px;
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    font-size: 1.125rem;
    justify-content: center;
  }
`;