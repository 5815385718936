import styled from 'styled-components';
import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';
import { breakpoints } from '@authentic/loyalty-pwa/design-system/style-guide/layout';

export const SnackbarContentStyled = styled.div<{ isFixed: boolean }>`
  z-index: 1;
  max-width: 688px;
  width: 688px;
  margin: 0 auto;
  ${({ isFixed }) => !isFixed && `position: relative;`}
  ${({ isFixed }) => isFixed && `position: fixed;`}
  ${({ isFixed }) => isFixed && `top: -350px;`}
  ${({ isFixed }) => isFixed && `left: 50%;`}
  ${({ isFixed }) => isFixed && `margin-left: -344px;`}
  transition: transform 0.5s;
  font-family: UntitledSansMedium;
  font-size: 18px;
  min-height: 54px;
  display: flex;
  justify-content: center;
  padding: 0 46px;
  &.opened {
    transform: translateY(370px);
  }

  &.closed {
    transform: translateY(0);
  }

  &.success {
    background-color: ${colors.primaryVariant};
  }

  &.error {
    border: 1px solid ${colors.white};
    background-color: ${colors.error};
    > div {
      color: ${colors.secondary} !important;
    }
    svg g g {
      fill: ${colors.secondary} !important;
    }
  }
  > div {
    color: ${colors.primary};
    display: flex;

    .column {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
      &.center {
        flex: 1;
        font-style: normal;
        font-weight: bold;
        line-height: 22px;
        letter-spacing: 0.01em;
        text-align: left;
      }
      p {
        margin: 0.5em;
        text-align: center;
      }
    }
  }

  > svg {
    position: absolute;
    left: 24px;
    top: 50%;
    margin-top: -10px;
  }
  > div svg {
    position: absolute;
    right: 24px;
    top: 50%;
    margin-top: -7px;
    cursor: pointer;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    max-width: inherit;
    margin-left: 0;
    left: 0;
  }
`;

export const BoldCloseIconStyled = styled.div``;
