import { Fragment, FunctionalComponent, h, options } from 'preact';
import { Control, useController, UseControllerProps } from 'react-hook-form';
import { CheckboxFieldStyled } from './checkbox.style';
export type CheckboxFieldProps = {
  label: string;
  id: string;
  name: string;
  checked: boolean;
} & UseControllerProps;
export const CheckboxField: FunctionalComponent<CheckboxFieldProps> = (
  props
) => {
  const {
    field: { value, ref, onChange, ...inputProps },
  } = useController(props);

  return (
    <CheckboxFieldStyled className='checkbox-field'>
      <label htmlFor={props.id}>
        <input
          ref={ref}
          type='checkbox'
          {...inputProps}
          onChange={onChange}
          checked={value}
          name={props.id}
          id={props.id}
        />
        <span>{props.label}</span>
      </label>
    </CheckboxFieldStyled>
  );
};
