import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const MyRewardsPageStyled = styled.div``;
export const MyRewardsPageContentStyled = styled.div`
  position: relative;
  min-height: 1160px;
  padding: 48px 0;
  .background-image {
    background-color: #d85079;
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    min-height: 1000px;
  }
`;
