import styled from "styled-components";

export const ButtonStyled = styled.button<{ buttonType: string }>`
  border: ${props => props.theme.components.button[props.buttonType].style.borderWidth} solid ${props => props.theme.components.button[props.buttonType].style.border};
  color: ${props => props.theme.components.button[props.buttonType].style.color};
  background: ${props => props.theme.components.button[props.buttonType].style.background};
  font-size: ${props => props.theme.components.button[props.buttonType].style.fontSize};
  font-family: ${props => props.theme.components.button[props.buttonType].style.fontFamily};
  font-weight: ${props => props.theme.components.button[props.buttonType].style.fontWeight};
  width: ${props => props.theme.components.button[props.buttonType].style.width};
  padding: ${props => props.theme.components.button[props.buttonType].style.padding};
  letter-spacing: ${props => props.theme.components.button[props.buttonType].style.letterSpacing};
  height: ${props => props.theme.components.button[props.buttonType].style.height};
  cursor: pointer;
  letter-spacing: 1px;
  text-transform: ${props => props.theme.components.button[props.buttonType].style.textTransform};
  border-radius: ${props => props.theme.components.button[props.buttonType].style.borderRadius};;
	&[disabled] {
		cursor: not-allowed;
    opacity: 0.8;
	}
`;
