import { Submenu } from "@greencross/components/submenu/submenu.component";
import { BaseLayout } from "@greencross/pages/shared/base-layout/base-layout";
import { h } from "preact";
import { FAQ_URL } from "../faq/faq.strings";
import { MORE_ABOUT_LIVING_REWARDS, MORE_ABOUT_URL } from "../more-about/more-about.strings";
import { ABOUT_US_BODY, ABOUT_US_FAQ, ABOUT_US_SUBHEAD, FAQS } from "./about-us.strings";
import { AboutUsPageStyled } from "./about-us.style";
import { AboutUsPageImageStyled } from "./about-us.style";
import { getImageSrc } from '@authentic/loyalty-pwa/util';

export const AboutUsPage = () => (
	<BaseLayout>
		<AboutUsPageStyled>
			<h1>{ABOUT_US_FAQ}</h1>
			<h2>{ABOUT_US_SUBHEAD}</h2>
			{ABOUT_US_BODY}
			<AboutUsPageImageStyled>
				<div><img src={getImageSrc('/assets/images/backgrounds/LR_6_reasons_why.png')} /></div>
			</AboutUsPageImageStyled>
			<Submenu>
				<li><a href={MORE_ABOUT_URL}>{MORE_ABOUT_LIVING_REWARDS}</a></li>
				<li><a href={FAQ_URL}>{FAQS}</a></li>
			</Submenu>
		</AboutUsPageStyled>
	</BaseLayout>
);