import { colors } from "@authentic/loyalty-pwa/design-system/style-guide/color";
import { BackgroundImageStyled } from "@greencross/components/background-image/background-image.style";
import { Breakpoints } from "@greencross/utils/breakpoints";
import styled from "styled-components";

export const PrivacyPolicyPageStyled = styled.div`
	position: relative;
	min-height: 729px;
	padding: 48px 0;
	text-align: center;
	color: #666666;
	font-family: BrandonGrotesque-Medium;
	font-size: 2rem;
	
	${BackgroundImageStyled} {
		background-color: #7eb4cf;
  	}
	h1 {
		color: ${colors.black};
	    text-transform: uppercase;
	    font-weight: bold;
	    font-size: 2.5rem;
	    font-family: OpenSans-Bold;
	    margin-bottom: 0;
  	}
  	p {
  		padding: 1rem 5rem 5rem 5rem;
  	}
  	a {
  		font-family: BrandonGrotesque-Bold;
  	}
	@media screen and (max-width: ${Breakpoints.XL}) {
		padding: 3rem;
		display: flex;
		align-items: center;
	}
`;