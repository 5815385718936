import { DEAFULT_ERROR_MESSAGE } from './form/contact-us-form.strings';

export const CONTACT_US_SUBJECTS = [
  'Update my details',
  'Query about my account',
  'Query about a promotion',
  'I need a new card',
  'Compliment',
  'Complaint',
  'Website issues',
  'Other',
];

export const FIELD_FIRST_NAME = 'firstname';
export const FIELD_LAST_NAME = 'lastname';
export const FIELD_EMAIL = 'email';
export const FIELD_PHONE_NUMBER = 'phonenumber';
export const FIELD_MEMBERSHIP_NUMBER = 'membershipnumber';
export const FIELD_SUBJECT = 'subject';
export const FIELD_MESSAGE = 'message';
export const FIELD_RECAPTCHA = 'recaptcha';

export type CONTACT_US_FORM = {
  [FIELD_FIRST_NAME]: string;
  [FIELD_LAST_NAME]: string;
  [FIELD_EMAIL]: string;
  [FIELD_PHONE_NUMBER]: string;
  [FIELD_MEMBERSHIP_NUMBER]: number;
  [FIELD_SUBJECT]: typeof CONTACT_US_SUBJECTS;
  [FIELD_MESSAGE]: string;
  [FIELD_RECAPTCHA]: string;
};

export const CONTACT_US_RULES: Record<keyof CONTACT_US_FORM, {}> = {
  [FIELD_FIRST_NAME]: {
    required: {
      value: true,
      message: DEAFULT_ERROR_MESSAGE,
    },
    pattern: {
      value: /^[A-Za-z ']*$/,
      message:
        'Format is invalid, please use only A-Z, spaces, and apostrophes.',
    },
    maxLength: {
      value: 100,
      message: 'Please use less than 100 characters',
    },
  },
  [FIELD_LAST_NAME]: {
    required: {
      value: true,
      message: DEAFULT_ERROR_MESSAGE,
    },
    pattern: {
      value: /^[A-Za-z ']*$/,
      message:
        'Format is invalid, please use only A-Z, spaces, and apostrophes.',
    },
    maxLength: {
      value: 100,
      message: 'Please use less than 100 characters',
    },
  },
  [FIELD_EMAIL]: {
    required: {
      value: true,
      message: DEAFULT_ERROR_MESSAGE,
    },
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: 'Email must be in the format "name@domain.tld"',
    },
    maxLength: {
      value: 250,
      message: 'Please use less than 250 characters',
    },
  },
  [FIELD_PHONE_NUMBER]: {
    maxLength: {
      value: 10,
      message: 'Please enter a valid phone number',
    },
  },
  [FIELD_MEMBERSHIP_NUMBER]: {
    pattern: {
      value: /^\d+$/,
      message: 'Please enter only the membership number',
    },
    maxLength: {
      value: 20,
      message: 'Please enter only the membership number',
    },
  },
  [FIELD_SUBJECT]: {
    required: {
      value: true,
      message: DEAFULT_ERROR_MESSAGE,
    },
    validate: (value: string) =>
      CONTACT_US_SUBJECTS.indexOf(value) !== -1 ||
      'You must select a subject from the list',
  },
  [FIELD_MESSAGE]: {
    required: {
      value: true,
      message: DEAFULT_ERROR_MESSAGE,
    },
    maxLength: {
      value: 4096,
      message: 'Please shorten your message to under 4096 characters',
    },
  },
  [FIELD_RECAPTCHA]: {
    required: {
      value: true,
      message: DEAFULT_ERROR_MESSAGE,
    },
  },
};
