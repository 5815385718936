import { LocationServiceInterface, LocationsBySearchProps, LocationsBySearchResults } from "@authentic/loyalty-pwa/interfaces";
import { CheetahShared } from "@authentic/loyalty-pwa/services/cheetah/cheetah.shared"

export class CheetahLocationService extends CheetahShared implements LocationServiceInterface {
    async searchLocations({
		query,
		city,
		zip_code,
		page = 1,
		results_per_page = 10,
		sort_by = 'label',
		sort_dir = 'asc',
		integration_id
	}: LocationsBySearchProps): Promise<LocationsBySearchResults> {
		const filter = (city)?{city}:zip_code?{zip_code}:integration_id?{integration_id}:{};
		return this.fetch({
			url: '/api/places',
			method: 'GET',
			queryParams: {
				...filter,
				results_per_page,
				sort_by,
				sort_dir,
				q: query
			}
		})
		.then(result => result.json())
		.then(result => ({
				total: result.data.total_entries,
				page: page,
				pages: result.data.total_pages,
				locations: result.data.places.map((place: any) => ({
					id: place.id,
					integration_id: place.integration_id,
					label: place.label,
					name: place.name,
					url: place.url,
					address: place.address,
					country_code: place.country_code,
					country: place.country,
					state: place.state,
					city: place.city,
					zip_code: place.zip_code,
					latitude: place.latitude,
					longitude: place.longitude,
					email: place.email,
					phone: place.phone,
					image: place.original_image_url,
					thumbnail: place.thumb_image_url
				}))
			}))
	}
}