import { FunctionalComponent, h } from 'preact';
import {
  OverlayStyled,
  ContactUsFormContentStyled,
  ContactUsFormFieldsStyled,
  ContactUsFormStyled,
  PersonalDataContentStyled,
  NeedHelpContentStyled,
} from './contact-us-form.style';
import { TextField } from '@authentic/loyalty-pwa/design-system/components/base/text-field/text-field.component';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  DEAFULT_ERROR_MESSAGE,
  PAGE_TITLE,
  PHONENUMBER_ERROR_MESSAGE,
  PHONENUMBER_MASK,
  PHONENUMBER_PLACEHOLDER,
} from './contact-us-form.strings';
import { SelectField } from '@greencross/components/select-field/select-field.component';
import { useEffect, useRef, useState } from 'preact/hooks';
import {
  Button,
  ButtonType,
  ButtonVariant,
} from '@authentic/loyalty-pwa/design-system/components/base/button/button.component';
import { TextAreaField } from '@greencross/components/text-area-field/text-area-field.component';
import ReCAPTCHA from 'react-google-recaptcha';
import { ErrorStyled } from '@greencross/components/text-area-field/text-area-field.style';
import {
  CONTACT_US_FORM,
  CONTACT_US_RULES,
  CONTACT_US_SUBJECTS,
  FIELD_EMAIL,
  FIELD_FIRST_NAME,
  FIELD_LAST_NAME,
  FIELD_MEMBERSHIP_NUMBER,
  FIELD_MESSAGE,
  FIELD_PHONE_NUMBER,
  FIELD_RECAPTCHA,
  FIELD_SUBJECT,
} from '../contact-us.constants';
import { route } from '@authentic/loyalty-pwa/util';
import { CONTACT_US_THANK_YOU_URL } from '../thank-you/thank-you-info.strings';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
  cleanPhoneNumberToValidate,
} from '@greencross/utils/format-phone-number';
type OptionalDataType = {
  [FIELD_MEMBERSHIP_NUMBER]: number;
};

interface ContactUsFormProps {}
export const ContactUsForm: FunctionalComponent<ContactUsFormProps> = (
  props
) => {
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);
  const [displaySuccessfulMessage, setDisplaySuccessfulMessage] =
    useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    getValues,
    setError,
    register,
    watch,
    setValue: setValueManually,
  } = useForm();
  const onFocusInput = (name: string, e: any) => {
    console.log('onInputFocus: ', e.target.value, name);
    document
      .getElementsByClassName(`${name}-field`)[0]
      .getElementsByTagName('label')[0]
      .classList.add('active');
  };

  const onSubmit: SubmitHandler<any> = (data: CONTACT_US_FORM) => {
    console.log('data: ', data);
    setIsProcessingRequest(true);
    let optionalData: OptionalDataType = {
      [FIELD_MEMBERSHIP_NUMBER]: data[FIELD_MEMBERSHIP_NUMBER],
    };
    let contactData = {
      [FIELD_FIRST_NAME]: data[FIELD_FIRST_NAME],
      [FIELD_LAST_NAME]: data[FIELD_LAST_NAME],
      [FIELD_EMAIL]: data[FIELD_EMAIL],
      [FIELD_PHONE_NUMBER]: data[FIELD_PHONE_NUMBER],
      [FIELD_SUBJECT]: data[FIELD_SUBJECT],
      [FIELD_MESSAGE]: data[FIELD_MESSAGE],
      [FIELD_RECAPTCHA]: data[FIELD_RECAPTCHA],
      ...optionalData,
    };
    console.log('contactData: ', contactData);
    fetch('/api/contact', {
      method: 'POST',
      body: JSON.stringify(contactData),
    })
      .then((res) => res.json())
      .then((res: any) => {
        console.log('Contact res: ', res);
        if (res.success) {
          route(CONTACT_US_THANK_YOU_URL);
        }
      });
  };

  const onError = (error: any) => {
    console.log('error: ', error);
  };

  const scrollTo = (name: string) => {
    window.scrollTo({
      //@ts-ignore
      top: document.getElementsByClassName(`${name}-field`)[0].offsetTop,
      behavior: 'smooth',
    });
  };

  const onRecpatchaChange = (value: string | null) =>
    setValueManually(FIELD_RECAPTCHA, value);

  useEffect(() => {
    if (displaySuccessfulMessage) {
      setTimeout(() => {
        setDisplaySuccessfulMessage(false);
      }, 3000);
    }
  }, [displaySuccessfulMessage]);

  useEffect(() => {
    register('recaptcha', {
      required: DEAFULT_ERROR_MESSAGE,
    });
  }, []);

  return (
    <ContactUsFormStyled className='contact-us-form'>
      <ContactUsFormContentStyled>
        <h1>{PAGE_TITLE}</h1>
        <ContactUsFormFieldsStyled>
          <h3>Get In Touch!</h3>
          <p>* Mandatory fields</p>
          {
            //@ts-ignore
            <form onSubmit={handleSubmit(onSubmit, onError)} autoComplete='off'>
              <input name='email' class='visually-hidden' type='text' />

              <PersonalDataContentStyled>
                <TextField
                  name={FIELD_FIRST_NAME}
                  defaultValue={''}
                  placeholder={''}
                  label={'First Name'}
                  control={control}
                  error={errors[FIELD_FIRST_NAME]}
                  onFocus={onFocusInput.bind(this, FIELD_FIRST_NAME)}
                  rules={CONTACT_US_RULES[FIELD_FIRST_NAME]}
                />

                <TextField
                  name={FIELD_LAST_NAME}
                  defaultValue={''}
                  placeholder={''}
                  label={'Last Name'}
                  control={control}
                  error={errors[FIELD_LAST_NAME]}
                  onFocus={onFocusInput.bind(this, FIELD_LAST_NAME)}
                  rules={CONTACT_US_RULES[FIELD_LAST_NAME]}
                />

                <TextField
                  name={FIELD_EMAIL}
                  defaultValue={''}
                  placeholder={''}
                  label={'Email'}
                  control={control}
                  error={errors[FIELD_EMAIL]}
                  onFocus={onFocusInput.bind(this, FIELD_EMAIL)}
                  rules={CONTACT_US_RULES[FIELD_EMAIL]}
                />

                <TextField
                  name={FIELD_PHONE_NUMBER}
                  defaultValue={''}
                  placeholder={PHONENUMBER_PLACEHOLDER}
                  label={'Mobile Number'}
                  control={control}
                  error={errors[FIELD_PHONE_NUMBER]}
                  onFocus={onFocusInput.bind(this, FIELD_PHONE_NUMBER)}
                  rules={{
                    required: false,
                    /* NOTE:
                     * this custom validate is only valid for this contact-us form since
                     * this form don't use a cheetah api which make the phone number validations on the anothers forms.
                     * that's why we've integrated the same library CLP uses to validate it and get the same behavior
                     **/
                    validate: (value: string) =>
                      !value || // Allow empty
                      isValidPhoneNumber(cleanPhoneNumberToValidate(value)) ||
                      PHONENUMBER_ERROR_MESSAGE,
                  }}
                  mask={PHONENUMBER_MASK}
                />

                <TextField
                  name={FIELD_MEMBERSHIP_NUMBER}
                  defaultValue={''}
                  placeholder={''}
                  label={'Living Rewards Membership Number'}
                  control={control}
                  error={errors[FIELD_MEMBERSHIP_NUMBER]}
                  onFocus={onFocusInput.bind(this, FIELD_MEMBERSHIP_NUMBER)}
                  rules={CONTACT_US_RULES[FIELD_MEMBERSHIP_NUMBER]}
                />
              </PersonalDataContentStyled>

              <SelectField
                placeholder='Subject'
                defaultValue={''}
                control={control}
                name={FIELD_SUBJECT}
                error={errors[FIELD_SUBJECT]}
                options={CONTACT_US_SUBJECTS.map((item) => {
                  return {
                    label: item,
                    value: item,
                  };
                })}
                rules={CONTACT_US_RULES[FIELD_SUBJECT]}
              ></SelectField>

              <TextAreaField
                defaultValue={''}
                placeholder={''}
                label={'Message'}
                rows={6}
                control={control}
                name={FIELD_MESSAGE}
                error={errors[FIELD_MESSAGE]}
                rules={CONTACT_US_RULES[FIELD_MESSAGE]}
              ></TextAreaField>

              <ReCAPTCHA
                sitekey={process.env.RECAPTCHA_SITE_KEY || ''}
                onChange={onRecpatchaChange}
                className={`${FIELD_RECAPTCHA}-field`}
                //@ts-ignore the types for recaptcha doesn't include ref though ref works
                ref={reCaptchaRef}
              />
              {errors[FIELD_RECAPTCHA] && (
                <ErrorStyled>{errors[FIELD_RECAPTCHA].message}</ErrorStyled>
              )}

              <Button
                disabled={isProcessingRequest}
                variant={ButtonVariant.PRIMARY}
                type={ButtonType.SUBMIT}
              >
                SUBMIT
              </Button>
            </form>
          }
        </ContactUsFormFieldsStyled>

        <NeedHelpContentStyled>
          <div>
            <h3>Need help? Contact us</h3>
            <p>
              <strong>Customer service</strong>
              <br />
              Contact centre hours are <br />
              Monday to Friday, 9am to 5pm
            </p>
            <p>
              Phone <strong>0800 762 672</strong>
              <br />
              Fax <strong>(09) 571 9081</strong>
              <br />
              Email <a href="mailto:hello@livingrewards.co.nz">hello@livingrewards.co.nz</a>
            </p>
            <p>
              You may also find a solution to your problem&nbsp;in our
              troubleshooting list for FAQs
            </p>
          </div>
        </NeedHelpContentStyled>
      </ContactUsFormContentStyled>
      <OverlayStyled></OverlayStyled>
    </ContactUsFormStyled>
  );
};
