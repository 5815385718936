if (process.env.NODE_ENV==='development') {
  require("preact/debug");
}
import { App } from '@authentic/loyalty-pwa';
import {
  DefaultRoutes,
  Router,
} from '@authentic/loyalty-pwa/components';
import { ThemeDefault } from '@authentic/loyalty-pwa/design-system/themes/theme-default';
import { CheetahService, Events, EventsService } from '@authentic/loyalty-pwa/services';
import { h, render } from 'preact';
import { route, RouterOnChangeArgs } from 'preact-router';
import { ThemeProvider } from 'styled-components';
import { ProjectRoutes } from './routing/routes';
import { GlobalStyle } from './style/global-style';
import './style/index.css';

const cheetahService = new CheetahService({
  base_url: process.env.CHEETAH_BASE_URL ?? '',
  client_id: process.env.CHEETAH_CLIENT_ID ?? '',
  client_secret: process.env.CHEETAH_CLIENT_SECRET ?? '',
});

EventsService.on(Events.USER_LOGOUT).then(() => { route('/') })

render(
  <App
    receiptService={cheetahService}
    userService={cheetahService}
    locationService={cheetahService}
    rewardsService={cheetahService}
    offersService={cheetahService}
    contentPageService={cheetahService}
    historyService={cheetahService}
    contentBlockService={cheetahService}
  >
    <ThemeProvider theme={ThemeDefault}>
      <GlobalStyle />
      <Router
        onChange={() => {
          // Sometimes, this gets called before the new page is loaded. So we async:
          setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 1);
        }}
      >
        {ProjectRoutes}
      </Router>
    </ThemeProvider>
  </App>,
  document.body
);
