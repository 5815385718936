import { colors } from "@authentic/loyalty-pwa/design-system/style-guide/color";
import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const TermsAndConditionsPageStyled = styled.div`
  max-width: ${Breakpoints.XL};
  position: relative;
  margin: 0 auto;
  padding: 3rem 0;
  color: #666666;
  font-family: BrandonGrotesque-Medium;
  font-size: 2rem;

	a {
		font-family: 'BrandonGrotesque-Medium';
	}

  h1,
  h2 {
    color: ${colors.black};
    text-transform: uppercase;
  }
  h1 {
    text-align: center;
    font-weight: bold;
    font-size: 2.5rem;
    font-family: BrandonGrotesque-Bold;
    margin-bottom: 3rem;
  }
  h2 {
    font-family: OpenSans-Bold;
    font-size: 2rem;
    margin: 0;
  }
  ol {
    max-width: 96rem;
    margin: auto;
  }

  @media screen and (max-width: ${Breakpoints.XL}) {
    padding: 55px 0;
    width: 90%;
  }
`;
