import { useUser } from '@authentic/loyalty-pwa/providers';
import { MY_REWARDS_URL } from '@greencross/pages/authenticated/my-rewards/my-rewards.strings';
import { ABOUT_US_URL } from '@greencross/pages/unauthenticated/about-us/about-us.strings';
import { MEMBER_EXCLUSIVES_URL } from '@greencross/pages/authenticated/member-exclusives/member-exclusives.strings';
import { MORE_ABOUT_URL } from '@greencross/pages/unauthenticated/more-about/more-about.strings';
import { PHARMACY_LOCATOR_URL } from '@greencross/pages/unauthenticated/pharmacy-locator/pharmacy-locator.strings';
import { PRIVACY_POLICY_URL } from '@greencross/pages/unauthenticated/privacy-policy/privacy-policy.strings';
import { SIGNUP_URL } from '@greencross/pages/unauthenticated/sign-up/sign-up.strings';
import { Fragment, h } from 'preact';
import { getCurrentUrl, Link } from 'preact-router';
import {
  ABOUT_US_FAQ,
  CONTACT_US,
  HOME,
  MEMBER_EXCLUSIVES,
  MY_REWARDS,
  PHARMACY_LOCATOR,
  SIGN_OUT,
} from './header-menu.strings';
import { HeaderMenuContentStyled, HeaderMenuMobileStyled, HeaderMenuStyled } from './header-menu.style';
import { SPECIAL_INTERESTS_URL } from '@greencross/pages/authenticated/special-interests/special-interests.strings';

export const HeaderMenu = () => {
  return (
    <HeaderMenuContentStyled>
      <HeaderMenuStyled>
        <HeaderMenuLinks mobile={false} />
      </HeaderMenuStyled>
    </HeaderMenuContentStyled>
  );
};

export const HeaderMenuMobile = ({ active }: { active: boolean }) => {
  if (!active) return null;

  return (
    <HeaderMenuMobileStyled>
      <HeaderMenuLinks mobile={true} />
    </HeaderMenuMobileStyled>
  );
};

const pages: Record<string, string[]> = {
  aboutUs: [ABOUT_US_URL, MORE_ABOUT_URL],
  home: ['/', PRIVACY_POLICY_URL],
  myRewards: [MY_REWARDS_URL, SPECIAL_INTERESTS_URL],
  signup: [SIGNUP_URL],
  memberExclusives: [MEMBER_EXCLUSIVES_URL]
};

const getPageClass = (page: string): string => {
  const currentUrl = getCurrentUrl();
  return pages[page] && pages[page].indexOf(currentUrl) !== -1 ? 'active' : '';
};

const HeaderMenuLinks = ({ mobile }: { mobile: boolean }) => {
  const user = useUser();

  return (
    <Fragment>
      {mobile && (!user || !user.authenticated) && (
        <li>
          <Link className={getPageClass('signup')} href={SIGNUP_URL}>
            Join
          </Link>
        </li>
      )}
      <li>
        <Link className={getPageClass('home')} href='/'>
          {HOME}
        </Link>
      </li>
      {user && user.authenticated && (
        <li>
          <Link className={getPageClass('myRewards')} href={MY_REWARDS_URL}>
            {MY_REWARDS}
          </Link>
        </li>
      )}
      {user && user.authenticated && (
        <li>
          <Link
            className={getPageClass('memberExclusives')}
            href={MEMBER_EXCLUSIVES_URL}
          >
            {MEMBER_EXCLUSIVES}
          </Link>
        </li>
      )}
      {/*
      <li>
        <Link href='/member-exclusives'>{MEMBER_EXCLUSIVES}</Link>
      </li>
      */}
      <li>
        <Link className={getPageClass('aboutUs')} href={ABOUT_US_URL}>
          {ABOUT_US_FAQ}
        </Link>
      </li>
      <li>
        <Link href='/contact-us'>{CONTACT_US}</Link>
      </li>
      {
        <li>
          <Link target='_blank' href={PHARMACY_LOCATOR_URL}>
            {PHARMACY_LOCATOR}
          </Link>
        </li>
      }
      {mobile && user && user.authenticated && (
        <li>
          <Link href='/sign-out' className='button'>
            {SIGN_OUT}
          </Link>
        </li>
      )}
    </Fragment>
  );
};
