// String text
export const VERIFICATION_PROGRESS_TITLE = 'Verifying';
export const VERIFICATION_PROGRESS_TEXT = "Please wait one moment while we verify your email address.";

export const VERIFICATION_SUCCESS_TITLE = "Email Verified";
export const VERIFICATION_SUCCESS_TEXT = "Thank you for verifying your email. You are now ready to sign-in.";

export const VERIFICATION_FAILED_TITLE = 'Verification Failed';
export const VERIFICATION_FAILED_TEXT = 'Email could not be verified. If you have previously completed this step, please try signing in. If you have not previously completed this step, please try clicking the link in your email again, or if you are copying and pasting please ensure that you have copied the entire link.';

// String buttons
export const BACK_TO_SIGNIN_BTN_TEXT = "Back to sign in";
export const RESEND_VERIFICATION_BTN_TEXT = 'Resend verification email';

export const VERIFICATION_COMPLETED_URL = '/verify-email';
