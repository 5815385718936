import styled from "styled-components";
import {
    ButtonContainer,
    Description,
    Title
} from "@greencross/overrides/design-system/components/base/cta-info/variant2/cta-info.variant2.style";
import {Card} from "@greencross/components/card/card.style";
import {Breakpoints} from "@greencross/utils/breakpoints";
import {ButtonStyled} from "@greencross/overrides/design-system/components/base/button/button.style";

export const SuccessfulSignupStyled = styled.div`
  ${Title} {
    font-family: OpenSans-Bold,serif;
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    text-transform: uppercase;
    @media screen and (max-width: ${Breakpoints.S}) {
      padding-left: 1.6em;
      padding-right: 1.6em;
    }
  }
  ${Card} {
    margin: 4.727em auto;
    padding: 12em 7em;
    max-height: 589px;
    @media screen and (max-width: ${Breakpoints.S}) {
      padding: 6em 2em;
      margin: 6em auto;
      max-height: 710px;
    }
  }
  .background-image {
    background-color: #64bbd1;
  }
  ${ButtonContainer} {
    padding: 1.364em 0;
    ${ButtonStyled} {
      padding: 0 1em;
      @media screen and (max-width: ${Breakpoints.S}) {
        padding-left: 1em;
        padding-right: 1em;
        font-size: 1.5rem;
      }
    }
    
  }
  .successful-signup-container {
    padding: 2em 7.2em;
    display: flex;
    flex-direction: column;
    position: relative;
    @media screen and (max-width: ${Breakpoints.S}) {
      padding-left: 1em;
      padding-right: 1em;
    }
  }
  & .content-container {
    position: relative;
    padding: 10.727em 0;
    @media screen and (max-width: ${Breakpoints.S}) {
      padding: 0.727em .5em;
    }
  }
`;