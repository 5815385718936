export const CONTACT_US_HEADER = 'Contact us';
export const CONTACT_US_SUBHEAD = 'Get in touch!';
export const MANDATORY_FIELDS_NOTICE = '* Mandatory fields';

export const LABEL_FIRST_NAME = 'First Name';
export const LABEL_LAST_NAME = 'Last Name';
export const LABEL_EMAIL = 'Email';
export const LABEL_MOBILE_NUMBER = 'Mobile Number';
export const LABEL_MEMBERSHIP_NUMBER = 'Living Rewards Membership Number';
export const LABEL_SUBJECT = 'Subject';
export const LABEL_MESSAGE = 'Message';
export const CONTACT_US_URL = '/contact-us';
