import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';
import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const SignInStyle = styled.div`
  h1 {
    color: ${colors.black};
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
  }

  label {
    top: 10px;
  }
  label.active {
    bottom: unset;
    top: -15px;
  }

  .visually-hidden {
    height: 0;
    padding: 0;
    margin: 0;
    border: none;
  }

  button {
    font-family: OpenSans-Bold;
    @media screen and (max-width: ${Breakpoints.XS}) {
      font-size: 1.6rem !important;
    }
  }
`;

export const ForgotPasswordContainer = styled.div`
  padding-top: 30px;
  text-align: center;
  a {
    font-weight: 800;
    font-size: 1.375rem;
    font-family: OpenSans-Bold;
  }
`;

export const SignUpContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;

  font-weight: 600;
  font-size: 1.375rem;
  font-family: OpenSans-Bold;
  a {
    color: ${colors.orange};
    font-weight: 600;
    font-size: 1.375rem;
    font-family: OpenSans-Bold;
  }
  @media screen and (max-width: ${Breakpoints.XS}) {
    font-size: 1.2rem;
    a {
      font-size: 1.2rem;
    }
  }
`;
