import { FunctionalComponent, h } from 'preact';
import { BackgroundImageStyled } from './background-image.style';

interface BackgroundImageProps {
  desktop: string;
  mobile: string;
}
export const BackgroundImage: FunctionalComponent<BackgroundImageProps> = (
  props
) => {
  return (
    <BackgroundImageStyled
      className='background-image'
      desktop={props.desktop}
      mobile={props.mobile}
    ></BackgroundImageStyled>
  );
};
