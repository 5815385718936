import { CheetahSignupProps } from '@authentic/loyalty-pwa/services/cheetah/cheetah.types';
import {
	ChangePasswordProps,
	EmailValidationProps,
	EmailValidationResult,
	LoggedOutUserServiceInterface,
	LoginProps,
	LoginResult,
	PasswordResetProps,
	PasswordResetResult, ProfileProps, ProfileUpdateResult,
	SignupResult,
	SocialSignupProps,
	UserAccountConfirmationProps,
	UserAccountConfirmationResult,
} from '@authentic/loyalty-pwa/interfaces';
import { CheetahShared } from '@authentic/loyalty-pwa/services/cheetah/cheetah.shared';
import { EventsService, Events } from '@authentic/loyalty-pwa/services/events.service';
import { UserData } from '@authentic/loyalty-pwa/model/user.data';
import { UserProvider } from '@authentic/loyalty-pwa/providers';

// implements only calls that do not require a logged-in user
export class CheetahLoggedOutUserService extends CheetahShared implements LoggedOutUserServiceInterface {
	async emailValidation({ email }: EmailValidationProps): Promise<EmailValidationResult> {
		return this.fetch({
			url: '/api/confirmations',
			body: {
				email: email
			}
		})
			.then(response => response.json())
	}

	async login({ email, password }: LoginProps): Promise<LoginResult> {
		return this.fetch({
			url: '/oauth/token',
			body: {
				grant_type: 'password',
				email: email,
				password: password
			}
		}).then((response) => {
			if (!response.ok) {
				return response.json().then((res) => ({ ...res, success: false }));
			}
			return response.json().then((res) => ({ ...res, success: true }));
		}).then(response => {
			if (response.success) {
				return UserProvider.update(new UserData({ token: response })).then(_ => response)
			}
			return response
		})
	}

	async passwordReset({ email }: PasswordResetProps): Promise<PasswordResetResult> {
		return this.fetch({
			url: '/api/passwords',
			body: {
				email: email
			}
		}).then((response) => {
			if (!response.ok) {
				return response.json().then((res) => ({ ...res, success: false }));
			}
			return response.json().then(res => ({
				success: res.success,
				message: res.data.message,
			}));
		});
	}

	async socialLogin({ access_token, provider, ...body }: SocialSignupProps): Promise<LoginResult> {
		console.log('Triggering social login', provider)
		return this.fetch({
			url: '/oauth/token',
			body: {
				assertion: access_token,
				grant_type: 'assertion',
				provider: provider,
				...body
			}
		})
			.then(response => response.json())
			.then(response => {
				if (response.token_type && response.access_token)
					UserProvider.update({ token: response });
				EventsService.emit(Events.USER_SSO, response)
				return response;
			})
	}

	async signup(data: CheetahSignupProps): Promise<SignupResult> {
		return this.fetch({
			url: '/api/sign_up',
			body: data
		})
			.then(response => response.json())
	}

	async changePassword({ password, passwordConfirmation, resetPasswordToken }: ChangePasswordProps): Promise<PasswordResetResult> {
		return this.fetch({
			url: '/api/passwords',
			method: 'PUT',
			body: {
				password,
				password_confirmation: passwordConfirmation,
				reset_password_token: resetPasswordToken
			}
		}).then((response) => {
			if (!response.ok) {
				return response.json().then((res) => ({ ...res, success: false }));
			}
			// when is successful response this api only returns a unique attribute (success: true)
			return response.json().then(res => ({
				success: res.success
			}));
		});
	}

	async confirmationToken({ confirmationToken }: UserAccountConfirmationProps): Promise<UserAccountConfirmationResult> {
		return this.fetch({
			url: '/api/confirmations',
			method: 'PUT',
			body: {
				confirmation_token: confirmationToken
			}
		}).then((response) => {
			if (!response.ok) {
				return response.json().then((res) => ({ ...res, success: false }));
			}
			// when is successful response this api only returns a unique attribute (success: true)
			return response.json().then(res => ({
				success: res.success
			}));
		});
	}

	async updateProfile(data: ProfileProps): Promise<ProfileUpdateResult> {
		console.log('updateProfile: ', data);
		return this.fetch({
			url: '/api/profile',
			method: 'PUT',
			body: data
		}, false)
			.then((response) => {
				return response.json().then((res) => ({ ...res, success: response.ok }));
			});
	}

}
