import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';
import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const SubmenuContainerStyled = styled.div``;
export const SubmenuStyled = styled.ul`
  padding: 0;
  list-style: none;
  font-size: 3.0625rem;
  text-transform: uppercase;
  width: 768px;
  margin: 2em auto 0;

  a {
    display: block;
    padding: 0.5em 0.8em;
    border-bottom: 4px solid ${colors.grayVariant9};
    position: relative;
    font-size: 1.7rem;
    &:after {
      content: '';
      background-image: url('/assets/icons/chevron-right.svg');
      width: 32px;
      height: 32px;
      position: absolute;
      right: 0.5em;
      top: 0.4em;
      background-repeat: no-repeat;
      z-index: 3;
      background-size: contain;
      svg {
        fill: green;
      }
    }
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    width: 95%;
  }
  @media screen and (max-width: ${Breakpoints.M}) {
    width: 95%;
  }
`;
