import { colors } from "@authentic/loyalty-pwa/util/variables";
import styled from "styled-components";

export const AddressFieldStyled = styled.div`
	label {
		position: absolute;
		font-size: 1.625rem;
		left: 0;
		top: -5px;
		transition: all 0.15s ease-in-out;
		&.active {
			top: -22px;
			font-size: 1.25rem;
			background-color: transparent;
  		}
	}
	input {
		background: transparent;
		border: none;
		border-bottom: 2px solid #000;
		padding: 6px;
		outline: none;
		font-size: 1.625rem;
		width: 100%;
		&::placeholder {
			color: #000;
		}
	}
`;

export const AddressFieldItemStyled = styled.p`
	font-size: 1.625rem;
	padding: 1rem;
	margin: 0;
	&.active {
		background-color: ${colors.lightGray};
	}
`;