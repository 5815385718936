import { colors } from "@authentic/loyalty-pwa/design-system/style-guide/color";
import { SubmenuStyled } from '@greencross/components/submenu/submenu.styled';
import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const AboutUsPageStyled = styled.div`
  max-width: ${Breakpoints.XL};
  position: relative;
  margin: 0 auto;
  padding: 3rem 0;
  color: #666666;
  font-family: BrandonGrotesque-Medium;
  font-size: 2rem;
  h1,
  h2 {
    color: ${colors.black};
    text-transform: uppercase;
  }

  h1 {
    text-align: center;
    font-weight: bold;
    font-size: 2.5rem;
    font-family: BrandonGrotesque-Bold;
    margin-bottom: 3rem;
  }
  h2 {
    font-family: OpenSans-Bold;
    font-size: 2.3rem;
    margin: 0;
  }
  p {
  }
  p:first-of-type {
    margin-top: 0;
  }
  a {
    font-family: BrandonGrotesque-Black;
    &:hover {
      text-decoration: none;
    }
  }

  @media screen and (max-width: ${Breakpoints.XL}) {
    padding: 3rem 3rem;

    h2 {
      text-align: center;
    }
  }

  ${SubmenuStyled} {
    margin: 3rem auto 5rem;
  }
`;

export const AboutUsPageImageStyled = styled.div`
  img {
    width: 100%;
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    
  }
`;
