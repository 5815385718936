// parse from DD/MM/YYYY to YYYY-MM-DD
export const parseDateToSendAPI = (birthdate: string) => {
  const dateItems = birthdate.split('/');
  return `${dateItems[2]}-${dateItems[1]}-${dateItems[0]}`;
};

// parse from YYYY-MM-DD to DD/MM/YYYY
export const parseDateToDisplayOnTheUI = (birthdate: string) => {
  if (birthdate) {
    const dateItems = birthdate.split('-');
    return `${dateItems[2]}/${dateItems[1]}/${dateItems[0]}`;
  }
  return '';
};
