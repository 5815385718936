import { BaseLayout } from "@greencross/pages/shared/base-layout/base-layout";
import { h } from "preact";
import { LA_ROCHE_POSAY_BODY, LA_ROCHE_POSAY_HEADER } from "./la-roche-posay.strings";
import { LaRochePosayPageStyled } from "./la-roche-posay.style";

export const LaRochePosayPage = () => (
	<BaseLayout>
		<LaRochePosayPageStyled>
			<h1>{LA_ROCHE_POSAY_HEADER}</h1>
			<h2>L’Oreal, promoter of La Roche-Posay, in conjunction with Green Cross Health, are giving Living Rewards members the chance to win 1 of 10 Prizes of 9,000 points. For your chance to win, purchase any 2 La Roche-Posay products in one transaction at a participating Unichem or Life Pharmacy, and swipe your Living Rewards card to automatically enter.</h2>
			<br/>
			{LA_ROCHE_POSAY_BODY}
		</LaRochePosayPageStyled>
	</BaseLayout>
);