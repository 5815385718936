import { Heading1 } from '@greencross/overrides/design-system/style-guide/typography';
import { BaseLayout } from '@greencross/pages/shared/base-layout/base-layout';
import { FunctionalComponent, h } from 'preact';
import {
  PHARMACY_LOCATOR_PAGE_TITLE,
  SEARCH_BUTTON,
} from './pharmacy-locator.strings';
import {
  EnterPostcodeInputStyled,
  EnterServiceInputStyled,
  PharmacyLocationSearchFormStyled,
  PharmacyLocatorContentDetailStyled,
  PharmacyLocatorDetailStyled,
  PharmacyLocatorLayoutStyled,
  PharmacyLocatorPageContentStyled,
  PharmacyLocatorPageStyled,
  PharmacyLocatorResultDetailLeftSectionStyled,
  PharmacyLocatorResultDetailRightSectionStyled,
  PharmacyLocatorResultDetailStyled,
  PharmacyLocatorResultLeftSectionStyled,
  PharmacyLocatorResultRightSectionStyled,
  PharmacyLocatorResultStyled,
  PlaceItemStyled,
  ResultDetailItem,
} from './pharmacy-locator.style';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useUser } from '@authentic/loyalty-pwa/providers';
import { useEffect, useState } from 'preact/hooks';
import {
  Button,
  ButtonType,
  ButtonVariant,
} from '@authentic/loyalty-pwa/design-system/components/base/button/button.component';
import { TextField } from '@authentic/loyalty-pwa/design-system/components/base/text-field/text-field.component';
import {
  ENTER_POSTCODE_INPUT,
  ENTER_SERVICE_INPUT,
} from './pharmacy-location.constants';

interface PharmacyLocatorProps {}
export const PharmacyLocatorPage: FunctionalComponent<PharmacyLocatorProps> = (
  props
) => {
  const [isProcessingRequest, setIsProcessingRequest] =
    useState<boolean>(false);
  const user = useUser();
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    getValues,
    setError,
    register,
    watch,
    setValue: setValueManually,
  } = useForm();

  useEffect(() => {
    if (user) {
      console.log('user: ', user.token?.access_token);
      getAllPharmacyLocator();
    }
  }, [user]);

  const getAllPharmacyLocator = () => {};

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    console.log('data: ', data);
    setIsProcessingRequest(true);
  };

  const onError = async (error: any) => {
    console.log('error: ', error);
  };
  return (
    <PharmacyLocatorPageStyled className='member-exclusives-page'>
      <BaseLayout>
        <PharmacyLocatorPageContentStyled>
          <PharmacyLocatorDetailStyled>
            <PharmacyLocatorContentDetailStyled>
              <PharmacyLocatorLayoutStyled>
                <Heading1>{PHARMACY_LOCATOR_PAGE_TITLE}</Heading1>

                <PharmacyLocationSearchFormStyled>
                  <EnterPostcodeInputStyled>
                    <TextField
                      name={ENTER_POSTCODE_INPUT}
                      defaultValue={''}
                      placeholder={'Enter a Postcode or Suburb/town/city'}
                      label={''}
                      control={control}
                      error={errors[ENTER_POSTCODE_INPUT]}
                      rules={{
                        required: false,
                      }}
                    />
                  </EnterPostcodeInputStyled>
                  <EnterServiceInputStyled>
                    <TextField
                      name={ENTER_SERVICE_INPUT}
                      defaultValue={''}
                      placeholder={'Enter a Postcode or Suburb/town/city'}
                      label={''}
                      control={control}
                      error={errors[ENTER_SERVICE_INPUT]}
                      rules={{
                        required: false,
                      }}
                    />
                  </EnterServiceInputStyled>
                  <Button
                    disabled={isProcessingRequest}
                    variant={ButtonVariant.PRIMARY}
                    type={ButtonType.SUBMIT}
                  >
                    {SEARCH_BUTTON}
                  </Button>
                </PharmacyLocationSearchFormStyled>

                <PharmacyLocatorResultStyled>
                  <PharmacyLocatorResultLeftSectionStyled>
                    <PlaceItemStyled className='active'>
                      <div className='place-item--header'>
                        <h2>UNICHEM QUEEN ST PHARMACY</h2>
                        <span>0.27km</span>
                      </div>
                      <p>280 Queen Street, Auckland CBD, Auckland, 1010</p>
                    </PlaceItemStyled>
                    <PlaceItemStyled>
                      <div className='place-item--header'>
                        <h2>UNICHEM QUEEN ST PHARMACY</h2>
                        <span>0.27km</span>
                      </div>
                      <p>280 Queen Street, Auckland CBD, Auckland, 1010</p>
                    </PlaceItemStyled>
                    <PlaceItemStyled>
                      <div className='place-item--header'>
                        <h2>UNICHEM QUEEN ST PHARMACY</h2>
                        <span>0.27km</span>
                      </div>
                      <p>280 Queen Street, Auckland CBD, Auckland, 1010</p>
                    </PlaceItemStyled>
                  </PharmacyLocatorResultLeftSectionStyled>
                  <PharmacyLocatorResultRightSectionStyled>
                    <div className='right-section--header'>
                      <h2>UNICHEM QUEEN ST PHARMACY</h2>
                      <a href='#'>Open in maps</a>
                    </div>
                    {/** TODO: Add map here */}

                    <PharmacyLocatorResultDetailStyled>
                      <PharmacyLocatorResultDetailLeftSectionStyled>
                        <ResultDetailItem>
                          <h2>ADDRESS</h2>
                          <p>280 Queen Street, Auckland CBD, Auckland, 1010</p>
                        </ResultDetailItem>
                      </PharmacyLocatorResultDetailLeftSectionStyled>
                      <PharmacyLocatorResultDetailRightSectionStyled>
                        <ResultDetailItem>
                          <h2>OPENING HOURS</h2>
                          <p>280 Queen Street, Auckland CBD, Auckland, 1010</p>
                        </ResultDetailItem>
                      </PharmacyLocatorResultDetailRightSectionStyled>
                    </PharmacyLocatorResultDetailStyled>
                  </PharmacyLocatorResultRightSectionStyled>
                </PharmacyLocatorResultStyled>
              </PharmacyLocatorLayoutStyled>
            </PharmacyLocatorContentDetailStyled>
          </PharmacyLocatorDetailStyled>
        </PharmacyLocatorPageContentStyled>
      </BaseLayout>
    </PharmacyLocatorPageStyled>
  );
};
