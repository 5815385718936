import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';
import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const MyRewardsDetailStyled = styled.div`
  position: relative;
  width: 1170px;
  margin: 0 auto;
  border: 2px solid ${colors.grayVariant8};
  border-radius: 24px;
  padding: 4em 4em 8em;
  font-family: 'OpenSans-Bold';
  @media screen and (max-width: ${Breakpoints.ML}) {
    width: 95%;
  }

  @media screen and (max-width: ${Breakpoints.XS}) {
    padding: 4em 1em 8em;
  }
`;

export const MyRewardsDetailContentStyled = styled.div`
  z-index: 1;
  position: relative;
`;

export const TitleStyled = styled.h1`
  color: ${colors.black};
  font-family: 'BrandonGrotesque-Black';
  font-size: 2.5rem;
  text-transform: uppercase;
  text-align: center;
  @media screen and (max-width: ${Breakpoints.XS}) {
    font-size: 2.2em;
  }
`;

export const DescriptionStyled = styled.p`
  font-size: 2em;
  font-family: 'BrandonGrotesque-Medium';
  margin: 0;
  text-align: center;
  &.bold,
  strong {
    font-family: 'BrandonGrotesque-Bold';
  }
  &.username {
    margin-top: 2.5em;
  }

  @media screen and (max-width: ${Breakpoints.XS}) {
    font-size: 1.8em;
  }
`;

export const OverlayStyled = styled.div`
  z-index: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 0.9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 23px;
`;

export const CardsContainerStyled = styled.div`
  display: flex;
  gap: 4em;
  margin: 6em 0 4em;
  @media screen and (max-width: ${Breakpoints.L}) {
    flex-direction: column;
  }
`;
export const CardContentStyled = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 3px solid ${colors.grayVariant8};
  border-radius: 24px;
  padding: 2em 6.5em;
  background-color: ${colors.white};
  @media screen and (max-width: ${Breakpoints.XL}) {
    padding: 2em 6em;
  }

  @media screen and (max-width: ${Breakpoints.XS}) {
    padding: 2em;
  }
`;
export const CardTitleStyled = styled.h2`
  font-size: 1.7rem;
  color: ${colors.black};
`;
export const CardBalanceStyled = styled.span`
  font-size: 4rem;
  color: ${colors.primary};
`;

export const ProgressBarWrapperStyled = styled.div<{
  leftValue: number;
  rightValue: number;
}>`
  width: 100%;
  background-color: ${colors.grayVariant2};
  margin: 3em 0 2em;
  position: relative;
  height: 7px;
  font-family: 'OpenSans-Regular';
  font-size: 1.1rem;
  &:before {
    content: '${(p) => p.leftValue}';
    position: absolute;
    bottom: -17px;
    left: 0;
  }
  &:after {
    content: '${(p) => p.rightValue}';
    position: absolute;
    bottom: -17px;
    right: 0;
  }
`;
export const LevelLineStyled = styled.div<{
  percentage: string;
}>`
  position: absolute;
  top: 1px;
  left: 0;
  height: 6px;
  width: ${(p) => p.percentage};
  background-color: #14b2ef;
`;

export const CircularOfferStyled = styled.div`
  width: 170px;
  height: 170px;
  border-radius: 50%;
  border: 4px solid ${colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const CircularOfferTextStyled = styled.span`
  font-size: 4.2rem;
  color: ${colors.primary};
  display: block;
`;
export const CircularOfferLabelStyled = styled.span`
  font-size: 1.5em;
  color: ${colors.black};
`;
