import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';
import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const SignInPageStyled = styled.div``;

export const SignInContentStyled = styled.div`
  position: relative;
  padding: 100px 0;
  .background-image {
    background-color: ${colors.grandmaBackground};
  }
  @media screen and (max-width: ${Breakpoints.XS}) {
    padding: 24px 0;
  }
`;

export const SignInFormStyled = styled.div`
  position: relative;
  width: 600px;
  margin: 0 auto;
  border: 2px solid #dadada;
  border-radius: 24px;
  padding: 55px 103px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 0.9;
  h1 {
    font-weight: bold;
    font-size: 2.5rem;
    font-family: OpenSans-Bold;
    text-transform: uppercase;
  }
  form {
    width: 100%;
    max-width: 360px;

    button {
      margin: 0 auto;
      width: 100%;
      background-color: ${colors.orange};
      border: none;
      font-size: 1.625rem;
      @media screen and (max-width: ${Breakpoints.XS1}) {
        font-size: 1.4rem !important;
      }
    }
    > div {
      position: relative;
    }
    input {
      background: transparent;
      border: none;
      border-bottom: 2px solid #000;
      font-size: 1.625rem;
      &::placeholder {
        color: #000;
      }
    }
    > div > label {
      position: absolute;
      bottom: 10px;
      font-size: 1.625rem;
      left: 0;
      color: ${colors.black};
      transition: all 0.15s ease-in-out;
      &.active {
        bottom: 50px;
        font-size: 1.25rem;
        background-color: transparent;
      }
    }
    @media screen and (max-width: ${Breakpoints.XS}) {
      padding: 0 24px;
    }
    @media screen and (max-width: ${Breakpoints.XS1}) {
      a {
        font-size: 1.1rem;
        padding-left: 5px;
      }
    }
  }

  @media screen and (max-width: ${Breakpoints.XL}) {
    padding: 55px 0;
    width: 90%;
  }
  @media screen and (max-width: ${Breakpoints.XS}) {
    padding: 24px 0;
  }
`;
