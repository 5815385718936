import { CheetahShared } from "@authentic/loyalty-pwa/services/cheetah/cheetah.shared";
import {
	ContentBlockResponse,
	ContentBlockServiceInterface,
} from '@authentic/loyalty-pwa/interfaces/content-block.interface';

export class ContentBlockService extends CheetahShared implements ContentBlockServiceInterface {
	async getContentBlock(): Promise<ContentBlockResponse> {
		return this.fetch({
			url: '/api/content_blocks',
			method: 'GET'
		}, false).then((response) => {
			if (!response.ok) {
				return response.json().then((res) => ({ ...res, success: false }));
			}
			return response.json().then((res) => res);
		});
	}
}
