import {
	EmailValidationProps,
	EmailValidationResult,
	LoginProps,
	LoginResult,
	PasswordResetProps,
	PasswordResetResult,
	RewardServiceInterface,
	RewardsQuery,
	RewardsResponse,
	SignupProps,
	SignupResult,
	SocialSignupProps,
	OfferServiceInterface,
	UserServiceInterface,
	SummaryResult,
	OffersQuery,
	OffersResponse,
	UserOAuthMethods,
	OfferResponsesResult,
	WhatsNewOffersQuery,
	WhatsNewOffersResponse,
	ProfileResult,
	FavoriteLocationResult,
	ChangePasswordProps,
	OfferCouponCodeResponse,
	UserAccountConfirmationProps,
	UserAccountConfirmationResult,
	ChangePasswordProfileProps,
	ChangePasswordProfileResult, ProfileProps, ProfileUpdateResult,
} from '@authentic/loyalty-pwa/interfaces';
import { ContentPageResponse, ContentPageServiceInterface } from '@authentic/loyalty-pwa/interfaces/content-page.interface';
import { LocationsBySearchProps, LocationsBySearchResults, LocationServiceInterface } from "@authentic/loyalty-pwa/interfaces/location.service.interface";
import { UserData } from '@authentic/loyalty-pwa/model/user.data';
import { UserProvider } from '@authentic/loyalty-pwa/providers';
import { fetchHelper, FetchRequest } from '@authentic/loyalty-pwa/util';
import { applyMixins } from '@authentic/loyalty-pwa/util/mixin.util';
import { CheetahServiceConfig } from '@authentic/loyalty-pwa/services/cheetah/cheetah.types';
import { CheetahContentPageService } from '@authentic/loyalty-pwa/services/cheetah/content-page.service';
import { CheetahLocationService } from '@authentic/loyalty-pwa/services/cheetah/location.service';
import { CheetahLoggedInUserService } from '@authentic/loyalty-pwa/services/cheetah/loggedin.user.service';
import { CheetahLoggedOutUserService } from '@authentic/loyalty-pwa/services/cheetah/loggedout.user.service';
import { CheetahOfferService } from '@authentic/loyalty-pwa/services/cheetah/offer.service';
import { CheetahRewardService } from '@authentic/loyalty-pwa/services/cheetah/reward.service';
import { Events, EventsService } from '@authentic/loyalty-pwa/services/events.service';
import {
	CustomActivityResponse,
	HistoryQuery, HistoryResponse,
	HistoryServiceInterface,
} from '@authentic/loyalty-pwa/interfaces/history.service.interface';
import { HistoryService } from '@authentic/loyalty-pwa/services/cheetah/history.service';
import {
	ReceiptRequest, ReceiptResponse,
	ReceiptServiceInterface,
	SubmitReceiptRequest,
} from '@authentic/loyalty-pwa/interfaces/receipt.service.interface';
import { ReceiptService } from '@authentic/loyalty-pwa/services/cheetah/receipt.service';
import {
	ReferrerServiceInterface,
	SubmitReferrerRequest, SubmitReferrerResponse,
} from '@authentic/loyalty-pwa/interfaces/refferer.service.interface';
import { ReferrerService } from '@authentic/loyalty-pwa/services/cheetah/referrer.service';
import { ContentBlockResponse } from '../interfaces/content-block.interface';
import { ContentBlockService } from './cheetah/content-block.service';

export class CheetahService implements UserServiceInterface, LocationServiceInterface, RewardServiceInterface, OfferServiceInterface, ContentPageServiceInterface, HistoryServiceInterface, ReceiptServiceInterface, ReferrerServiceInterface {
	protected base_url: string;
	protected client_id: string;
	protected client_secret: string;

	constructor({ base_url, client_id, client_secret, oauth_services }: CheetahServiceConfig) {
		this.base_url = base_url;
		this.client_id = client_id;
		this.client_secret = client_secret;

		const existingUser = UserProvider.get()
		if (existingUser && existingUser.authenticated) {
			// User existed already in localstorage, let's refresh!
			const stack: Promise<any>[] = [Promise.resolve()]
			if (existingUser.isExpired)
				stack.push(this.refreshUserToken(existingUser))

			Promise.all(stack).then(() => this.updateUserSummary(false))
		}
		// If the user logs in later, update our access token and get user data
		EventsService.on(Events.USER_CHANGED)
			.when((updatedUser: UserData) => {
				if (!updatedUser.authenticated)
					return
				if (updatedUser.isExpired)
					return this.refreshUserToken(updatedUser)
				if (!updatedUser.summary)
					this.updateUserSummary(true)
			})

		if (oauth_services) {
			Object.entries(oauth_services).forEach(([key, service]) => {
				service.when.then(response => {
					EventsService.emit(Events.SSO_RESPONSE, response)
					return this.socialLogin({
						access_token: response.access_token,
						provider: key as UserOAuthMethods
					})
				})
			})
		}
	}

	protected async fetch({ url, method = 'POST', body, headers = {}, queryParams = {} }: FetchRequest, isPublic = true): Promise<Response> {
		const request: FetchRequest = {
			method,
			headers: {
				Accept: 'application/vnd.stellar-v1+json',
				...headers
			},
			url: `${this.base_url}${url}`
		}

		const user = UserProvider.get()

		if (!isPublic && (!user || !user.access_token)) {
			throw new Error("user accessing private resource while not logged in")
		}

		if (!isPublic && user.isExpired) {
			console.error("expired user cannot access private resource, waiting til refresh")
			return this.refreshUserToken(user).then(_ => this.fetch({ url, method, body, headers, queryParams }))
		}

		let authParams = isPublic ? {
			client_id: this.client_id,
			client_secret: this.client_secret
		} : {
			access_token: user.access_token
		}

		request.queryParams = {
			...authParams,
			...queryParams
		};

		if (request.method !== 'GET') {
			if (!(body instanceof FormData)) {
				request.body = {
					...authParams,
					...body,
				}
			} else {
				request.body = body;
			}
		}
		return fetchHelper(request)
	}

	private updateUserSummary(updateFavoriteLocation: boolean) {

		const offersProps: OffersQuery = {
			page: 1,
			results_per_page: 100,
			sort_by: 'asc',
			category: 'mobile_offers'
		};

		const rewardsProps = (category: string): RewardsQuery => {
			return {
				page: 1,
				results_per_page: 100,
				sort_by: 'required_metric',
				sort_dir: 'asc',
				category: category,
				result_with_categories: true
			}
		}

		Promise.all([
			this.summary(),
			this.profile(),
			this.getOffers(offersProps),
			this.getRewards(rewardsProps("mobile_reward")),
			this.getRewards(rewardsProps("del_yeah")),
		]).then(([summary, profile, offers, mobile_rewards, rewards]) => {
			UserProvider.update({ summary: summary, profile: profile, offers: offers, mobile_rewards: mobile_rewards, rewards: rewards });
			if (!updateFavoriteLocation) {
				this.searchLocations({ integration_id: profile.favorite_location }).then(
					(favoriteLocation) => {
						if (favoriteLocation && favoriteLocation.locations && favoriteLocation.locations.length > 0) {
							UserProvider.update({
								favorite_location: {
									success: true,
									...favoriteLocation.locations[0]
								}
							});
						}
					}
				);
			}
		});
	}

	/** Below here, none of this matters as it will be overriden at runtime; but it satisfies TypeScript */
	getRewards(data: RewardsQuery): Promise<RewardsResponse> {
		throw new Error('Method not implemented.');
	}
	login(data: LoginProps): Promise<LoginResult> {
		throw new Error('Method not implemented.');
	}
	signup(data: SignupProps): Promise<SignupResult> {
		throw new Error('Method not implemented.');
	}
	emailValidation(data: EmailValidationProps): Promise<EmailValidationResult> {
		throw new Error('Method not implemented.');
	}
	socialLogin(data: SocialSignupProps): Promise<LoginResult> {
		throw new Error('Method not implemented.');
	}
	passwordReset(data: PasswordResetProps): Promise<PasswordResetResult> {
		throw new Error('Method not implemented.');
	}
	changePassword(data: ChangePasswordProps): Promise<PasswordResetResult> {
		throw new Error('Method not implemented.');
	}
	confirmationToken(data: UserAccountConfirmationProps): Promise<UserAccountConfirmationResult> {
		throw new Error('Method not implemented.');
	}
	searchLocations(data: LocationsBySearchProps): Promise<LocationsBySearchResults> {
		throw new Error('Method not implemented.');
	}
	summary(): Promise<SummaryResult> {
		throw new Error('Method not implemented.');
	}
	profile(): Promise<ProfileResult> {
		throw new Error('Method not implemented.');
	}
	getFavoriteLocationById(id: number): Promise<FavoriteLocationResult> {
		throw new Error('Method not implemented.');
	}
	redeemReward(rewardId: number): Promise<{ success: boolean; }> {
		throw new Error('Method not implemented.');
	}
	getOffers(data: OffersQuery): Promise<OffersResponse> {
		throw new Error('Method not implemented.');
	}
	saveOffer(offerId: number): Promise<{ success: boolean; }> {
		throw new Error('Method not implemented.');
	}
	getOffersResponses(data: OffersQuery): Promise<OfferResponsesResult> {
		throw new Error('Method not implemented.');
	}
	getWhatsNewOffers(data: WhatsNewOffersQuery): Promise<WhatsNewOffersResponse> {
		throw new Error('Method not implemented.');
	}
	refreshUserToken(updatedUser: UserData): Promise<LoginResult> {
		throw new Error('Method not implemented.');
	}
	clipOfferResponse(offerResponseId: number): Promise<{ success: boolean; }> {
		throw new Error('Method not implemented.');
	}
	unclipOfferResponse(offerResponseId: number): Promise<{ success: boolean; }> {
		throw new Error('Method not implemented.');
	}
	redeemOfferCode(code: string): Promise<OfferCouponCodeResponse> {
		throw new Error('Method not implemented.');
	}
	getContentPageBySlug(slug: string): Promise<ContentPageResponse> {
		throw new Error('Method not implemented.');
	}
	getContentBlock(): Promise<ContentBlockResponse> {
		throw new Error('Method not implemented.');
	}
	getHistory(data: HistoryQuery): Promise<HistoryResponse> {
		throw new Error('Method not implemented.');
	}
	changePasswordProfile(data: ChangePasswordProfileProps): Promise<ChangePasswordProfileResult> {
		throw new Error('Method not implemented.');
	}

	registerClaim(data: ReceiptRequest): Promise<ReceiptResponse> {
		throw new Error('Method not implemented.');
	}

	submitReceipt(data: FormData): Promise<ReceiptResponse> {
		throw new Error('Method not implemented.');
	}

	updateProfile(data: ProfileProps): Promise<ProfileUpdateResult> {
		throw new Error('Method not implemented.');
	}

	submitReferrer(referrer: SubmitReferrerRequest): Promise<{ success: SubmitReferrerResponse }> {
		throw new Error('Method not implemented.');
	}

	getCustomActivities(data: HistoryQuery): Promise<CustomActivityResponse> {
		throw new Error('Method not implemented.');
	}

}

applyMixins(CheetahService, [CheetahLoggedInUserService, CheetahLoggedOutUserService, CheetahOfferService, CheetahRewardService, CheetahLocationService, CheetahContentPageService, HistoryService, ReceiptService, ReferrerService, ContentBlockService])
