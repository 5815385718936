import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';
import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const ForgotPasswordFormStyled = styled.div`
  position: relative;
  width: 1023px;
  height: 604px;
  margin: 0 auto;
  border: 2px solid #dadada;
  border-radius: 24px;
  padding: 55px 103px;
  display: flex;
  align-items: center;
  @media screen and (max-width: ${Breakpoints.XL}) {
    width: 90%;
    padding: 48px 24px;
    height: 420px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ForgotPasswordFormFieldsStyled = styled.div`
  max-width: 542.95px;
  margin: 0 auto;
  button {
    margin: 0 auto;
    width: 100%;
    background-color: ${colors.orange};
    border: none;
    font-size: 1.625rem;
  }
  .visually-hidden {
    height: 0;
    padding: 0;
    margin: 0;
    border: none;
  }
`;

export const ForgotPasswordFormContentStyled = styled.div`
  z-index: 1;
  position: relative;
  width: 100%;

  h1 {
    color: ${colors.black};
    font-size: 2.5rem;
    text-align: center;
    margin-top: 0;
    font-family: 'OpenSans-Bold';
    text-transform: uppercase;
    @media screen and (max-width: ${Breakpoints.S}) {
      font-size: 2.3125rem;
    }
  }

  button {
    text-transform: uppercase;
  }

  form {
    > div {
      position: relative;
    }
    input {
      background: transparent;
      border: none;
      border-bottom: 2px solid #000;
      font-size: 1.625rem;
      &::placeholder {
        color: #000;
      }
    }
    > div > label {
      position: absolute;
      top: 10px;
      font-size: 1.625rem;
      left: 0;
      transition: all 0.15s ease-in-out;
      color: ${colors.black};
      &.active {
        top: -22px;
        font-size: 1.25rem;
        background-color: transparent;
      }
    }
  }
`;

export const OverlayStyled = styled.div`
  z-index: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 0.9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 23px;
`;