import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';
import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const ProfileFormStyled = styled.div`
  position: relative;
  width: 1023px;
  margin: 0 auto;
  border: 2px solid #dadada;
  border-radius: 24px;
  padding: 60px 103px;
  @media screen and (max-width: ${Breakpoints.XL}) {
    width: 90%;
    padding: 48px 24px;
  }
`;

export const ProfileFormFieldsStyled = styled.div`
  max-width: 542.95px;
  margin: 0 auto;
  h3 {
    color: ${colors.black};
  }
  button {
    margin: 0 auto;
    width: 100%;
    background-color: ${colors.orange};
    border: none;
    font-size: 1.625rem;
    margin-top: 32px;
  }
  .visually-hidden {
    height: 0;
    padding: 0;
    margin: 0;
    border: none;
  }
  .select-field {
    margin-top: 0;
  }
  .message {
    text-align: center;
  }
`;

export const ProfileFormContentStyled = styled.div`
  z-index: 1;
  position: relative;

  h1 {
    color: ${colors.black};
    font-size: 2.5rem;
    text-align: center;
    margin-top: 0;
    margin-bottom: 2em;
    font-family: 'OpenSans-Bold';
  }

  .disclaimer {
    margin: 38px 0;
  }

  p {
    color: #666666;
    font-size: 1.375rem;
  }
  h3 {
    font-size: 1.8125rem;
    font-family: 'OpenSans-Bold';
  }

  form {
    > div {
      position: relative;
      margin-top: 32px;
    }
    .lastname-field {
      margin-bottom: 36px;
    }

    .birthdate-field {
      margin-top: 41px;
      margin-bottom: 56px;
    }
    .firstname-field {
      margin-top: 41px;
    }

    #email {
      color: ${colors.black};
    }

    .mailing_address-field {
      label {
        top: inherit;
      }
    }

    .select-field {
      width: 100%;
      border: none;
      outline: none;
      font-size: 1.625rem;
      background-color: transparent;
      background-image: url('../../../../assets/icons/chevron-down.svg');
      background-repeat: no-repeat;
      background-position: calc(100% - 0px) calc(22px);
      background-size: 15px;
    }
    input {
      background: transparent;
      border: none;
      border-bottom: 2px solid #000;
      font-size: 1.625rem;
      &::placeholder {
        color: #000;
      }
    }
    > div > label {
      position: absolute;
      top: 10px;
      font-size: 1.625rem;
      left: 0;
      transition: all 0.15s ease-in-out;
      color: ${colors.black};
      &.active {
        top: -22px;
        font-size: 1.25rem;
        background-color: transparent;
      }
    }
  }
`;

export const OverlayStyled = styled.div`
  z-index: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 0.9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 23px;
`;

export const CommunicationsPreferencesStyle = styled.div`
  .communication-pref-checks {
    display: flex;
    .checkbox-field {
      flex: 1;
      @media screen and (max-width: ${Breakpoints.XL}) {
        flex: inherit;
        width: 32%;
      }
    }
    @media screen and (max-width: ${Breakpoints.XL}) {
      flex-wrap: wrap;
      gap: 24px;
    }
  }
`;

export const TermsAndConditionsStyle = styled.div`
  label {
    margin-top: 16px;
    position: relative !important;
    display: flex;
    input {
      flex: 0 0 22px;
      margin-top: 7px;
      margin-right: 10px;
    }
    p {
      margin: 0;
    }
  }
`;
