import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';
import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const CheckboxFieldStyled = styled.div`
  label {
    color: #666666;
    font-size: 1.375rem;
    display: flex;
    align-items: center;
    input {
      margin-right: 10px;
    }
  }
`;
