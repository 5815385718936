import { Fragment, FunctionalComponent, h } from 'preact';
import { BackgroundImage } from '../../../components/background-image/background-image.component';
import { UnauthenticatedWrapper } from '../unauthenticated.wrapper';
import { ForgotPasswordPageContentStyled, ForgotPasswordPageStyled } from './forgot-password.style';
import { getImageSrc } from '@authentic/loyalty-pwa/util';
import { BaseLayout } from '../../shared/base-layout/base-layout';
import { ForgotPasswordForm } from './form/forgot-password-form';
import { getQueryString } from '@greencross/utils/querystring';
import { ForgotPasswordUpdateForm } from './update-form/forgot-password-update-form';

interface ForgotPasswordProps {}
export const ForgotPasswordPage: FunctionalComponent<ForgotPasswordProps> = () => {
  const qs = getQueryString();
  const token: string = qs.reset_password_token;
  console.log('Got token', token);
  return (
    <ForgotPasswordPageStyled className='forgotpassword-page'>
      <BaseLayout>
        <UnauthenticatedWrapper>
          <ForgotPasswordPageContentStyled className='forgotpassword-content'>
            {
              <BackgroundImage
                desktop={`url(${getImageSrc('/assets/images/backgrounds/Background-Forgotpassword-desktop.png')})`}
                mobile={`url(${getImageSrc('/assets/images/backgrounds/Background-Forgotpassword-mobile.jpg')})`}
              ></BackgroundImage>
            }
            {!token && (
              <ForgotPasswordForm></ForgotPasswordForm>
            )}
            {token && (
              <ForgotPasswordUpdateForm token={token} />
            )}
          </ForgotPasswordPageContentStyled>
        </UnauthenticatedWrapper>
      </BaseLayout>
    </ForgotPasswordPageStyled>
  );
};
