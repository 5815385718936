import { Breakpoints } from '../../utils/breakpoints';
import styled from 'styled-components';

export const BackgroundImageStyled = styled.div<{
  desktop: string;
  mobile: string;
}>`
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 20% 20%;
  background-image: ${(p) => p.desktop};
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  @media screen and (max-width: ${Breakpoints.XL}) {
    background-image: ${(p) => p.mobile};
    background-size: cover;
  }
`;
