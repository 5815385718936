import {
	LoggedInUserServiceInterface,
	ProfileResult,
	SummaryResult,
} from '@authentic/loyalty-pwa/interfaces';
import { ChangePasswordProfileProps, ChangePasswordProfileResult, FavoriteLocationResult, LoginResult } from "@authentic/loyalty-pwa/interfaces/user.service.interface";
import { UserData } from "@authentic/loyalty-pwa/model/user.data";
import { UserProvider } from "@authentic/loyalty-pwa/providers";
import { CheetahShared } from "@authentic/loyalty-pwa/services/cheetah/cheetah.shared";
import { CheetahServiceConfig } from "@authentic/loyalty-pwa/services/cheetah/cheetah.types";

export class CheetahLoggedInUserService extends CheetahShared implements LoggedInUserServiceInterface {
	async getFavoriteLocationById(placeId: number): Promise<FavoriteLocationResult | null> {
		return this.fetch({
			url: `/api/places/${placeId}`,
			method: 'GET',
		}, false)
			.then((response) => {
				if (!response.ok) {
					return response.json().then((res) => ({ ...res, success: false }));
				}
				return response.json().then((res) => ({ ...res, success: true }));
			})
			.then(result => {
				return {
					id: result.data.id,
					integration_id: result.data.integration_id,
					label: result.data.label,
					address: result.data.address,
					city: result.data.city,
					state: result.data.state,
					zip_code: result.data.zip_code,
					success: result.success
				}
			});
	}

	async summary(): Promise<SummaryResult | null> {
		return this.fetch({
			url: '/api/summary',
			method: 'GET',
		}, false)
			.then(result => result.json())
			.then(result => {
				const tierIndex = result.data.tier.tiers
					.findIndex((tier: any) => tier.name === result.data.tier.current_tier.name);

				let tierPoints = 0;
				if (tierIndex > -1 && result.data.tier.tiers.length > 1) {
					if (result.data.tier.tiers[tierIndex + 1] && result.data.tier.tiers[tierIndex].id + 1 === result.data.tier.tiers[tierIndex + 1].id) {
						tierPoints = result.data.tier.tiers[tierIndex + 1].metric_thresholds.tier_points;
					} else {
						tierPoints = result.data.tier.tiers[tierIndex].metric_thresholds.tier_points;
					}
				}

				return {
					...result.data,
					first_name: result.data.member.first_name,
					last_name: result.data.member.last_name,
					points: result.data.metrics.point.balance,
					tier: result.data.tier.current_tier.name,
					user_tier_data: {
						current_tier_name: result.data.tier.current_tier.name,
						earn_x_points: tierPoints - result.data.metrics.tier_points?.life_time,
						points_label: result.data.tier.primary_metric_name,
						expiration_date: result.data.tier.current_tier.expiration_date,
						next_tier: (tierIndex > -1 && result.data.tier.tiers.length > 1) ? result.data.tier.tiers[tierIndex + 1].label : result.data.tier.tier_in_progression,
						current_balance: result.data.metrics.point.balance,
						level_pts: tierPoints //TODO: we should define where we can get the 0 value of 0/thresholds.tier_points.
					}
				}
			});
	}

	profile(): Promise<ProfileResult | null> {
		return this.fetch({
			url: '/api/profile',
			method: 'GET',
		}, false)
			.then(result => result.json())
			.then(result => ({
				...result.data,
				card_id: result.data.card_id,
				member_id: result.data.member_id,
				favorite_location: result.data.favorite_location,
				total_points_in_period: result.data.total_points_in_period,
				first_name: result.data.first_name,
				last_name: result.data.last_name,
				mobile_phone: result.data.mobile_phone,
				birthdate: result.data.birthdate,
				receive_sms_offers: result.data.receive_sms_offers,
				receive_mail_offers: result.data.receive_mail_offers,
				email: result.data.email,
				mailing_postal_code: result.data.mailing_postal_code
			}))
	}

	async refreshUserToken(updatedUser: UserData): Promise<LoginResult> {
		return this.fetch({
			url: '/oauth/token',
			method: 'POST',
			body: {
				grant_type: 'refresh_token',
				refresh_token: updatedUser.token?.refresh_token
			}
		}).then((response) => {
			if (!response.ok) {
				return response.json().then((res) => ({ ...res, success: false }));
			}
			return response.json().then((res) => ({ ...res, success: true }));
		}).then(response => {
			if (response.success) {
				UserProvider.update(new UserData({ token: response }))
			} else
				UserProvider.logout()
			return response
		})
	}

	async changePasswordProfile(changePasswordRequest: ChangePasswordProfileProps): Promise<ChangePasswordProfileResult> {
		return this.fetch({
			url: '/api/profile/change_password',
			method: 'PUT',
			body: {
				...changePasswordRequest
			}
		}, false).then((response) => {
			if (!response.ok) {
				return response.json().then((res) => ({ ...res, success: false }));
			}
			return response.json().then((res) => ({ ...res, success: true }));
		});
	}
}
