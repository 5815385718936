import {FunctionalComponent, h} from "preact";
import {BaseLayout} from "@greencross/pages/shared/base-layout/base-layout";
import {Card} from "@greencross/components/card/card.style";
import {
    ButtonContainer,
    Description,
    Title
} from "@greencross/overrides/design-system/components/base/cta-info/variant2/cta-info.variant2.style";
import {
    Button,
    ButtonType,
    ButtonVariant
} from "@greencross/overrides/design-system/components/base/button/button.component";
import {VerificationEmailStyled} from "@greencross/pages/unauthenticated/verification-email/verification-email.style";
import {useCallback, useContext, useState} from "preact/hooks";
import {route} from "preact-router";
import {SIGN_IN_URL} from "@greencross/overrides/Pages/unauthenticated/sign-in/sign-in.strings";
import {getImageSrc} from "@authentic/loyalty-pwa/util";
import {BackgroundImage} from "@greencross/components/background-image/background-image.component";
import {
    BACK_TO_SIGNIN_BTN_TEXT,
    RESEND_BTN_TEXT,
    DESCRIPTION_TEXT_PARAGRAPH_1,
    DESCRIPTION_TEXT_PARAGRAPH_2,
    VERIFICATION_TITLE
} from "@greencross/pages/unauthenticated/verification-email/verification-email.strings";
import {UserServiceContext} from "@authentic/loyalty-pwa/providers";


interface VerificationEmailPageProps {
    matches?: { [key: string]: string }
}

export const VerificationEmailPage: FunctionalComponent<VerificationEmailPageProps> = ({matches = {}}) => {
    const { email } = matches;
    const userService = useContext(UserServiceContext);
    const [success, setSuccess] = useState(false);

    const handleResendVerificationEmail = useCallback(async () => {
       try {
           const response = await userService?.emailValidation({ email });
           if (response?.success) {
               setSuccess(true);
               route(SIGN_IN_URL);
           }
       } catch (e) {
           setSuccess(false);
           // TODO: action when the request fails
       }
    }, [setSuccess]);

    const handleBackToSignIn = useCallback(() => {
        route(SIGN_IN_URL);
    }, []);

    return (
        <VerificationEmailStyled>
            <BaseLayout>
                <div className="content-container">
                    <BackgroundImage
                        desktop={`url(${getImageSrc('/assets/images/backgrounds/GXH_Background_Desktop_4_OldCouple_75c5d7.jpg')})`}
                        mobile={`url(${getImageSrc('/assets/images/backgrounds/GXH_Background_Mobile_4_OldCouple_75c5d7.jpg')})`}
                    />
                    <Card>
                        <Title>{VERIFICATION_TITLE}</Title>
                        <div className="verification-email-container">
                            <Description>
                                {DESCRIPTION_TEXT_PARAGRAPH_1}
                            </Description>
                            <Description>
                                {DESCRIPTION_TEXT_PARAGRAPH_2}
                            </Description>
                            <ButtonContainer>
                                <Button variant={ButtonVariant.PRIMARY} type={ButtonType.BUTTON} onClick={handleResendVerificationEmail} disabled={success}>
                                    {RESEND_BTN_TEXT}
                                </Button>
                            </ButtonContainer>
                            <ButtonContainer>
                                <Button variant={ButtonVariant.PRIMARY_OUTLINE} type={ButtonType.BUTTON} onClick={handleBackToSignIn}>
                                    {BACK_TO_SIGNIN_BTN_TEXT}
                                </Button>
                            </ButtonContainer>
                        </div>
                    </Card>
                </div>
            </BaseLayout>
        </VerificationEmailStyled>
    );
}