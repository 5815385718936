import { BaseLayout } from "@greencross/pages/shared/base-layout/base-layout";
import { h } from "preact";
import { HOW_IT_WORKS_BODY, HOW_IT_WORKS_HEADER, MANAGE_ACCOUNT_BODY, MANAGE_ACCOUNT_HEADER, MORE_ABOUT_LIVING_REWARDS } from "./more-about.strings";
import { MoreAboutPageStyled } from "./more-about.style";

export const MoreAboutPage = () => (
	<BaseLayout>
		<MoreAboutPageStyled>
			<h1>{MORE_ABOUT_LIVING_REWARDS}</h1>
			<div>
				<h2>{HOW_IT_WORKS_HEADER}</h2>
				{HOW_IT_WORKS_BODY}
			</div>
			<div>
				<h2>{MANAGE_ACCOUNT_HEADER}</h2>
				{MANAGE_ACCOUNT_BODY}
			</div>
		</MoreAboutPageStyled>
	</BaseLayout>
);