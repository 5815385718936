import { Heading1 } from '@greencross/overrides/design-system/style-guide/typography';
import { BaseLayout } from '@greencross/pages/shared/base-layout/base-layout';
import { FunctionalComponent, h } from 'preact';
import {
  SPECIAL_INTERESTS_DESCRIPTION,
  SPECIAL_INTERESTS_PAGE_TITLE,
  SPECIAL_INTERESTS_SUCCESSFUL_MESSAGE,
  SUBMIT_BUTTON,
} from './special-interests.strings';
import {
  SpecialInterestDetailStyled,
  SpecialInterestFormStyled,
  SpecialInterestItemsStyled,
  SpecialInterestsContentDetailStyled,
  SpecialInterestsDetailStyled,
  SpecialInterestsLayoutStyled,
  SpecialInterestsPageContentStyled,
  SpecialInterestsPageStyled,
} from './special-interests.style';
import { CheckboxField } from '@greencross/components/checkbox/checkbox.component';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useUser } from '@authentic/loyalty-pwa/providers';
import { useEffect, useState } from 'preact/hooks';
import {
  Button,
  ButtonType,
  ButtonVariant,
} from '@authentic/loyalty-pwa/design-system/components/base/button/button.component';
import {
  SPECIAL_INTERESTS_CATEGORY_NAMES,
  SPECIAL_INTERESTS_DATA,
} from './special-interests.data';

interface SpecialInterestsProps {}
export const SpecialInterestsPage: FunctionalComponent<
  SpecialInterestsProps
> = (props) => {
  const baseURL = process.env.CHEETAH_BASE_URL;
  const customObjectInternalName = 'special_interests';
  const [specialInterests, setSpecialInterests] = useState(
    SPECIAL_INTERESTS_DATA
  );
  const [userSpecialInterests, setUserSpecialInterests] =
    useState<{ [T: string]: boolean }>();
  const [isProcessingRequest, setIsProcessingRequest] =
    useState<boolean>(false);
  const [displaySuccessfulMessage, setDisplaySuccessfulMessage] =
    useState(false);
  const user = useUser();
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    getValues,
    setError,
    register,
    watch,
    setValue: setValueManually,
  } = useForm();

  useEffect(() => {
    if (user) {
      console.log('user: ', user.token?.access_token);
      getAllSpecialInterests();
    }
  }, [user]);

  const getAllSpecialInterests = () => {
    fetch(
      `${baseURL}/api/custom_objects/${customObjectInternalName}?access_token=${user.token?.access_token}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/vnd.stellar-v1+json',
        },
      }
    )
      .then((res) => res.json())
      .then((res: any) => {
        console.log('getAllSpecialInterests result: ', res.data[0]);
        if (res.success) {
          setUserSpecialInterests(res.data.length > 0 ? res.data[0] : {});
        }
      });
  };

  const onSubmit: SubmitHandler<any> = async (_data: any) => {
    setIsProcessingRequest(true);
    fetch(
      `${baseURL}/api/custom_objects/${customObjectInternalName}?access_token=${user.token?.access_token}`,
      {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/vnd.stellar-v1+json',
        },
        body: JSON.stringify({
          [customObjectInternalName]: {
            ..._data,
            member_id: user.profile?.member_id,
          },
        }),
      }
    )
      .then((res) => res.json())
      .then((res: any) => {
        setIsProcessingRequest(false);
        console.log('getAllSpecialInterests result: ', res);
        if (res.success) {
          setDisplaySuccessfulMessage(true);
        }
      });
  };

  const onError = async (error: any) => {
    console.log('error: ', error);
  };

  useEffect(() => {
    if (displaySuccessfulMessage) {
      setTimeout(() => {
        setDisplaySuccessfulMessage(false);
      }, 5000);
    }
  }, [displaySuccessfulMessage]);
  return (
    <SpecialInterestsPageStyled className='member-exclusives-page'>
      <BaseLayout>
        <SpecialInterestsPageContentStyled>
          <SpecialInterestsDetailStyled>
            <SpecialInterestsContentDetailStyled>
              <SpecialInterestsLayoutStyled>
                <Heading1>{SPECIAL_INTERESTS_PAGE_TITLE}</Heading1>
                <p>{SPECIAL_INTERESTS_DESCRIPTION}</p>

                <SpecialInterestFormStyled
                  onSubmit={handleSubmit(onSubmit, onError)}
                  autoComplete='off'
                >
                  {userSpecialInterests &&
                    specialInterests.map((item) => (
                      <SpecialInterestDetailStyled>
                        <h2>
                          {
                            SPECIAL_INTERESTS_CATEGORY_NAMES.filter(
                              (c) => c.code === item.category
                            )[0].name
                          }
                        </h2>
                        <SpecialInterestItemsStyled>
                          {item.items.map((specialInterest) => (
                            <CheckboxField
                              label={specialInterest.name}
                              id={specialInterest.id}
                              name={specialInterest.id}
                              defaultValue={
                                !!userSpecialInterests[specialInterest.id]
                              }
                              checked={
                                !!userSpecialInterests[specialInterest.id]
                              }
                              control={control}
                            ></CheckboxField>
                          ))}
                        </SpecialInterestItemsStyled>
                      </SpecialInterestDetailStyled>
                    ))}

                  <Button
                    disabled={isProcessingRequest}
                    variant={ButtonVariant.PRIMARY}
                    type={ButtonType.SUBMIT}
                  >
                    {SUBMIT_BUTTON}
                  </Button>
                  {displaySuccessfulMessage && (
                    <p className='message'>
                      {SPECIAL_INTERESTS_SUCCESSFUL_MESSAGE}
                    </p>
                  )}
                </SpecialInterestFormStyled>
              </SpecialInterestsLayoutStyled>
            </SpecialInterestsContentDetailStyled>
          </SpecialInterestsDetailStyled>
        </SpecialInterestsPageContentStyled>
      </BaseLayout>
    </SpecialInterestsPageStyled>
  );
};
