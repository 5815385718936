import { FunctionalComponent, h } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import {
  Control,
  FieldError,
  FieldErrors,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import InputMask from 'react-input-mask';
import { Text2 } from '@authentic/loyalty-pwa/design-system/style-guide/typography';
import {
  DefaultTextFieldConfig,
  DefaultTextFieldStyle,
} from '@authentic/loyalty-pwa/design-system/components/base/text-field/text-field.config';
import {
  ErrorStyled,
  EndAdornment,
  Input,
  InputContainer,
  InputGroup,
  InputPrefix,
  InputSuffix,
  TextFieldStyled,
} from '@authentic/loyalty-pwa/design-system/components/base/text-field/text-field.style';
import { ReactNode } from 'react';

export enum TextFieldType {
  PASSWORD = 'password',
  TEXT = 'text',
  NUMBER = 'tel',
  DATE = 'date',
}

export const ShowPassword = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z'
      fill='#139beb'
      fill-opacity='0.87'
    />
  </svg>
);

export const HidePassword = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 21 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.5048 3.78947C13.1405 3.78947 15.2797 5.91158 15.2797 8.52632C15.2797 9.14211 15.1555 9.72 14.9359 10.26L17.7244 13.0263C19.1664 11.8326 20.3029 10.2884 21 8.52632C19.3479 4.36737 15.2701 1.42105 10.4952 1.42105C9.15825 1.42105 7.87858 1.65789 6.69441 2.08421L8.75716 4.13053C9.3015 3.91263 9.88404 3.78947 10.5048 3.78947ZM0.95498 1.20316L3.13233 3.36316L3.57162 3.79895C1.98636 5.02105 0.744884 6.65053 0 8.52632C1.65211 12.6853 5.72988 15.6316 10.5048 15.6316C11.985 15.6316 13.3984 15.3474 14.6876 14.8358L15.0887 15.2337L17.8868 18L19.0996 16.7968L2.1678 0L0.95498 1.20316ZM6.23602 6.44211L7.71623 7.91053C7.66849 8.10947 7.63984 8.31789 7.63984 8.52632C7.63984 10.0989 8.91951 11.3684 10.5048 11.3684C10.7149 11.3684 10.925 11.34 11.1255 11.2926L12.6057 12.7611C11.9659 13.0737 11.2592 13.2632 10.5048 13.2632C7.86903 13.2632 5.72988 11.1411 5.72988 8.52632C5.72988 7.77789 5.92087 7.07684 6.23602 6.44211ZM10.352 5.70316L13.3602 8.68737L13.3793 8.53579C13.3793 6.96316 12.0996 5.69368 10.5143 5.69368L10.352 5.70316Z'
      fill='#139beb'
    />
  </svg>
);

export type TextFieldProps = {
  id?: string;
  label: string;
  error?: FieldError | FieldErrors<FieldValues> | undefined;
  placeholder?: string;
  type?: TextFieldType;
  value?: string;
  required?: string;
  variant?: string;
  mask?: string;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  onChange?: (ev: any) => void;
  style?: DefaultTextFieldStyle;
  control?: Control<any>;
  description?: ReactNode;
  disabled?: boolean;
  prefix?: ReactNode;
  suffix?: ReactNode;
} & UseControllerProps;

export const TextField: FunctionalComponent<TextFieldProps> = ({
  onFocus,
  onBlur,
  onChange,
  disabled,
  prefix,
  suffix,
  ...props
}) => {
  const [showText, setShowText] = useState<boolean>(
    props.variant !== TextFieldType.PASSWORD
  );
  const [isActive, setIsActive] = useState<boolean>(false);
  const {
    field: { value, ref, ...inputProps },
  } = useController(props);
  let textFieldStyle: DefaultTextFieldStyle = DefaultTextFieldConfig.style;

  const handleActive = useCallback(
    ($event: any) => {
      setIsActive(true);
      if (onFocus && typeof onFocus === 'function') {
        onFocus($event);
      }
    },
    [onFocus, setIsActive]
  );

  const handleInactive = useCallback(
    ($event: any) => {
      if (!$event.target.value) {
        setIsActive(false);
      }
      if (onBlur && typeof onBlur === 'function') {
        onBlur($event);
      }
      inputProps.onBlur();
    },
    [setIsActive, onBlur]
  );

  const handleOnChange = useCallback(
    ($event: any) => {
      console.log('handleOnChange: ', $event);
      if (onChange && typeof onChange === 'function') {
        onChange($event);
      }
      inputProps.onChange($event);
    },
    [onChange]
  );

  useEffect(() => {
    if (value) {
      setIsActive(true);
    }
  }, [value]);

  return (
    <TextFieldStyled className={`${props.name}-field`} error={props.error}>
      <InputGroup error={props.error} customStyle={textFieldStyle}>
        {prefix && <InputPrefix>{prefix}</InputPrefix>}
        <InputContainer>
          <Text2
            htmlFor={props.name}
            className={`${isActive || props.value ? 'active' : ''}`}
          >
            {props.label}

            {
              //@ts-ignore
              props.rules?.required === true || props.rules?.required?.value ? '*' : ''
            }
          </Text2>
          {props.mask ? (
            <InputMask
              mask={props.mask}
              maskPlaceholder={props.placeholder}
              {...inputProps}
              onFocus={handleActive}
              value={value}
            >
              <Input
                ref={ref}
                type={showText ? TextFieldType.TEXT : props.type}
                placeholder={''}
                {...inputProps}
                id={props.name}
                customStyle={textFieldStyle}
                value={value}
                disabled={disabled}
              />
            </InputMask>
          ) : (
            <Input
              ref={ref}
              {...inputProps}
              type={showText ? TextFieldType.TEXT : props.type}
              placeholder={props.placeholder}
              onFocus={handleActive}
              onBlur={handleInactive}
              onChange={handleOnChange}
              customStyle={textFieldStyle}
              id={props.name}
              value={value}
              disabled={disabled}
            />
          )}
          {props.variant == 'password' && (
            <EndAdornment onClick={() => setShowText(!showText)}>
              {showText ? <ShowPassword /> : <HidePassword />}
            </EndAdornment>
          )}
        </InputContainer>
        {suffix && <InputSuffix>{suffix}</InputSuffix>}
      </InputGroup>
      {props.error && <ErrorStyled>{props.error.message}</ErrorStyled>}
      {props.description}
    </TextFieldStyled>
  );
};
