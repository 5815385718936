export const TITLE_INPUT_ID = 'title';
export const FIRST_NAME_INPUT_ID = 'firstname';
export const LAST_NAME_INPUT_ID = 'lastname';
export const DATE_OF_BIRTH_INPUT_ID = 'birthdate';
export const GENDER_INPUT_ID = 'gender';
export const MAILING_ADDRESS_INPUT_ID = 'mailing_address';
export const EMAIL_INPUT_ID = 'email';
export const MOBILENUMBER_INPUT_ID = 'mobilenumber';
export const COMMUNICATION_PREF_EMAIL_ID = 'suspend_email';
export const COMMUNICATION_PREF_MAIL_ID = 'mail_opt_out';
export const COMMUNICATION_PREF_TEXT_ID = 'suspend_sms';
export const COMMUNICATION_PREF_PHONE_ID = 'phone_opt_out';

export type FormValues = {
  [TITLE_INPUT_ID]: string;
  [FIRST_NAME_INPUT_ID]: string;
  [LAST_NAME_INPUT_ID]: string;
  [DATE_OF_BIRTH_INPUT_ID]: string;
  [GENDER_INPUT_ID]: string;
  [MAILING_ADDRESS_INPUT_ID]: string;
  [EMAIL_INPUT_ID]: string;
  [MOBILENUMBER_INPUT_ID]: string;
  [COMMUNICATION_PREF_EMAIL_ID]: boolean;
  [COMMUNICATION_PREF_MAIL_ID]: boolean;
  [COMMUNICATION_PREF_TEXT_ID]: boolean;
  [COMMUNICATION_PREF_PHONE_ID]: boolean;
};
