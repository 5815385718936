export const FlexCenter = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlexLeftCenter = `
  display: flex;
  align-items: flex-start;
`;

export const FlexRightCenter = `
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;