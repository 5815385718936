const listeners: Record<string, EventsService> = {}


export enum Events {
	USER_CHANGED = 'user_changed',
	USER_LOGOUT = "user_logout",
    USER_SAVED = "user_saved",
	USER_SSO = "user_sso",
	SSO_RESPONSE = "sso_response"
}

export type EventsServiceListener = (data: any, previous: any, evt: string) => void
export type EventsModifier<T> = (data: T, preventEvent?: () => void) => Promise<T> | T

export class EventsService<InputType = any, ResultType = InputType> {
    private listeners: EventsServiceListener[] = []
    private afterModifiers: EventsModifier<ResultType>[] = []
    private beforeModifiers: EventsModifier<InputType>[] = []

    private _name: string
    private _previous: any = undefined

    constructor(name: string) {
        this._name = name
    }

    static before<InputType>(evt: string, data: InputType): Promise<InputType> {
        let halt = false
        const preventEvent = () => halt = true
        if (!(evt in listeners))
            return Promise.resolve(data)

        const result = listeners[evt].emitBefore(data, preventEvent)
        if (!halt)
            return result
        else
            // A bit wonky but since we halted we're just going to end the promise chain here
            return new Promise<InputType>(resolve => {})
    }

    static emit(evt: string, data: any): Promise<any> {
        if (!(evt in listeners))
            listeners[evt] = new EventsService(evt)
        return listeners[evt].emit(data)
    }

    static on<InputType = any, ResultType = InputType>(evt: string): EventsService<InputType, ResultType>
    static on<InputType = any, ResultType = InputType>(evt: string[]): MultiEventEmitter
    static on<InputType = any, ResultType = InputType>(evt: string | string[]): EventsService<InputType, ResultType> | MultiEventEmitter {
        if (Array.isArray(evt))
            return new MultiEventEmitter(evt)
        if (!(evt in listeners))
            listeners[evt] = new EventsService<InputType, ResultType>(evt)
        return listeners[evt]
    }

    private emit(data: ResultType): Promise<ResultType> {
        console.log('event', this.name, data)
        // The after modifiers will modify the final output before it makes it to the listeners
        const chain = this.afterModifiers.reduce((chain, fn) => chain.then(fn), Promise.resolve(data));
        return chain.then(data => {
            this.listeners.forEach(fn => Promise.resolve(fn(data, this.previous, this.name)))
            return data
        }).then(data => {
            this._previous = data
            return data
        })
    }

    private emitBefore(data: InputType, preventEvent: () => void) {
        return this.beforeModifiers.reduce((chain, fn) => chain.then(data => fn(data, preventEvent)), Promise.resolve(data))
    }

    get name() {
        return this._name
    }

    get previous() {
        return this._previous
    }

    after(fn: EventsModifier<ResultType>): EventsService<InputType, ResultType> {
        this.afterModifiers.push(fn)
        return this
    }

    before(fn: EventsModifier<InputType>): EventsService<InputType, ResultType> {
        this.beforeModifiers.push(fn)
        return this
    }

    then(fn: EventsServiceListener): EventsService<InputType, ResultType> {
        this.listeners.push(fn)
        return this
    }

    when(fn: EventsServiceListener): EventsService<InputType, ResultType> {
        this.listeners.push(fn)
        if (this.previous)
            fn(this.previous, null, this.name)
        return this
    }
}

export class MultiEventEmitter {
    private emitters: EventsService[] = []

    constructor(names: string | string[]) {
        this.and(names)
    }

    then(fn: EventsServiceListener) {
        this.emitters.forEach(emitter => emitter.then(fn))
    }

    when(fn: EventsServiceListener) {
        this.emitters.forEach(emitter => emitter.when(fn))
    }

    and(names: string | string[]) {
        if (!Array.isArray(names))
            names = [names]

        names.forEach(name => {
            const existing = this.emitters.find(emitter => emitter.name === name)
            if (!existing)
                // Since we're not pushing an array, this will always be an EventsService
                this.emitters.push(EventsService.on(name) as EventsService)
        })

        return this
    }
}