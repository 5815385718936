import { Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { createRef, FunctionalComponent, h, render } from 'preact';
import { BoldCloseIconStyled, SnackbarContentStyled } from '@authentic/loyalty-pwa/design-system/components/base/snackbar/snackbar.style';
import { CircleCheckIcon } from '@authentic/loyalty-pwa/design-system/style-guide/icons/circle-check.icon';
import { WarningIcon } from '@authentic/loyalty-pwa/design-system/style-guide/icons/warning.icon';
import { BoldCloseIcon } from '@authentic/loyalty-pwa/design-system/style-guide/icons/bold-close.icon';

export enum SnackbarType {
  Success = 'success',
  Error = 'error',
}

interface SnackbarProps {
  isFixed: boolean;
  type: SnackbarType;
  message: string;
  timeout: number;
  isActive?: boolean;
  onCloseEvent?: () => void;
}

let hideSnakbarTimeoutId: number;
let removeSnackbarTimeoutAfterAnimation: number;
export const Snackbar: FunctionalComponent<SnackbarProps> = ({
  isFixed,
  type,
  message,
  timeout,
  isActive,
  onCloseEvent,
}) => {
  const snackbarRef = createRef();
  useEffect(() => {
    if (isFixed && snackbarRef.current) {
      snackbarRef.current.classList.remove('closed');
      snackbarRef.current.classList.add('opened');
      hideSnakbarTimeoutId = window.setTimeout(() => {
        snackbarRef.current.classList.add('closed');
        removeSnackbarTimeoutAfterAnimation = window.setTimeout(() => {
          const snackbarElement = document.getElementById('snackbar');
          if (snackbarElement) {
            snackbarElement.innerHTML = '';
          }
          clearTimeout(removeSnackbarTimeoutAfterAnimation);
        }, 500); //animation time is 500
        clearTimeout(hideSnakbarTimeoutId);
      }, timeout);
    }
  });

  const closeSnackbar = () => {
    if (onCloseEvent) {
      onCloseEvent();
    }
  };

  return (
    <Fragment>
      {isActive && (
        <SnackbarContentStyled ref={snackbarRef} isFixed={isFixed} className={`${type}`}>
          {type === SnackbarType.Success && <CircleCheckIcon></CircleCheckIcon>}
          {type === SnackbarType.Error && <WarningIcon></WarningIcon>}
          <div>
            <div class="column center">
              <p>{message}</p>
            </div>
          </div>
          <BoldCloseIconStyled onClick={closeSnackbar}>
            <BoldCloseIcon></BoldCloseIcon>
          </BoldCloseIconStyled>
        </SnackbarContentStyled>
      )}
    </Fragment>
  );
};

export abstract class SnackbarHandler {
  static open(isFixed: boolean, type: SnackbarType, message: string, timeout: number) {
    const div = document.createElement('div');
    div.setAttribute('id', 'snackbar');
    document.body.appendChild(div);
    const customSnackbar = h(
      Snackbar,
      { isFixed: isFixed, type: type, message: message, timeout: timeout, isActive: isFixed },
      null,
    );
    const SnackbarParent = document.getElementById('snackbar');
    if (SnackbarParent) {
      render(customSnackbar, SnackbarParent);
    }
  }
}
