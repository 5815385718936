import { getImageSrc } from '@authentic/loyalty-pwa/util';
import { BackgroundImage } from '@greencross/components/background-image/background-image.component';
import { BaseLayout } from '@greencross/pages/shared/base-layout/base-layout';
import { FunctionalComponent, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import {
  ContactUsPageContentStyled,
  ContactUsPageStyled,
} from './contact-us.style';
import { ContactUsForm } from './form/contact-us-form';
import { ContactUsThankYouInfo } from './thank-you/thank-you-info';
import { CONTACT_US_THANK_YOU_URL } from './thank-you/thank-you-info.strings';

interface ContactUsPageProps {}
export const ContactUsPage: FunctionalComponent<ContactUsPageProps> = (
  props
) => {
  const [displayContactUsThankYouInfo, setDisplayContactUsThankYouInfo] =
    useState(false);
  useEffect(() => {
    console.log('props: ', props.path);
    if (props.path && props.path.indexOf(CONTACT_US_THANK_YOU_URL) > -1) {
      setDisplayContactUsThankYouInfo(true);
    }
  }, []);
  return (
    <ContactUsPageStyled>
      <BaseLayout>
        <ContactUsPageContentStyled>
          <BackgroundImage
            desktop={`url(${getImageSrc(
              '/assets/images/backgrounds/Background-Contactus-desktop.jpg'
            )})`}
            mobile={`url(${getImageSrc(
              '/assets/images/backgrounds/Background-Contactus-mobile.jpg'
            )})`}
          ></BackgroundImage>

          {displayContactUsThankYouInfo ? (
            <ContactUsThankYouInfo></ContactUsThankYouInfo>
          ) : (
            <ContactUsForm></ContactUsForm>
          )}
        </ContactUsPageContentStyled>
      </BaseLayout>
    </ContactUsPageStyled>
  );
};
