export const validateMyAge = (year: string, month: string, day: string) => {
  const min_year = new Date().getFullYear() - 13;
  const _month = new Date().getMonth() + 1;
  const _day = new Date().getDay();

  const dateofbirthDate = new Date(year + '/' + month + '/' + day);
  const mindate = new Date(min_year + '/' + _month + '/' + _day);
  if (dateofbirthDate <= mindate) {
    return true;
  }
  return false;
};

export const validateAge = (dateOfBirth: string) => {
  const birthdateItems = dateOfBirth.split('/');
  const yearAsANumber = parseInt(birthdateItems[2]);
  if (typeof yearAsANumber === 'number' && yearAsANumber > 999) {
    return validateMyAge(
      birthdateItems[2],
      birthdateItems[1],
      birthdateItems[0]
    )
      ? true
      : false;
  }
  return true;
};