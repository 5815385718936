import { DefaultCtaInfoVariant3Config } from "@authentic/loyalty-pwa/design-system/components/base/cta-info/variant3/cta-info.variant3.config";
import { colors } from "@greencross/overrides/design-system/style-guide/color"
import {
	ButtonsConfigs,
} from "@greencross/overrides/design-system/components/base/button/button.config";
import {ButtonVariant} from "@greencross/overrides/design-system/components/base/button/button.component";

export const ThemeDefault = {
	components: {
		menu: {
			colors: {
				background: colors.secondary,
				title: colors.white,
				description: colors.grayVariant3,
				caption: colors.white,
				icon: colors.accentPurple,
				backgroundIcon: colors.white,
				dropdownActiveItemBackground: colors.white,
				dropdownActiveItemText: colors.secondary
			},
		},
		qrOptions: {
			colors: {
				link: colors.primary,
				icon: colors.primary,
				description: colors.secondary,
				backgroundPage: colors.grayVariant5
			}
		},
		activities: {
			colors: {
				backgroundActivityIcon: colors.primary,
				points: colors.primary,
				itemBorder: colors.grayVariant4,
				pageContentToolbarText: colors.primary,
				pageContentToolbarBorderBottom: colors.grayVariant4,
				backgroundPage: colors.grayVariant5,
				backgroundPageContent: colors.white
			}
		},
		cta_info: {
			colors: {
				white: colors.white,
				gray: colors.gray
			},
			variant3: DefaultCtaInfoVariant3Config
		},
		button: {
			colors: {
				background: colors.primary
			},
			[ButtonVariant.PRIMARY]: ButtonsConfigs[ButtonVariant.PRIMARY],
			[ButtonVariant.PRIMARY_SMALL]: ButtonsConfigs[ButtonVariant.PRIMARY_SMALL],
			[ButtonVariant.SECONDARY]: ButtonsConfigs[ButtonVariant.SECONDARY],
			[ButtonVariant.SECONDARY_SMALL]: ButtonsConfigs[ButtonVariant.SECONDARY_SMALL],
			[ButtonVariant.PRIMARY_OUTLINE] : ButtonsConfigs[ButtonVariant.PRIMARY_OUTLINE],
			[ButtonVariant.PRIMARY_OUTLINE_SMALL] : ButtonsConfigs[ButtonVariant.PRIMARY_OUTLINE_SMALL],
			[ButtonVariant.SECONDARY_OUTLINE] : ButtonsConfigs[ButtonVariant.SECONDARY_OUTLINE],
			[ButtonVariant.SECONDARY_OUTLINE_SMALL] : ButtonsConfigs[ButtonVariant.SECONDARY_OUTLINE_SMALL],
			[ButtonVariant.PRIMARY_LINK] : ButtonsConfigs[ButtonVariant.PRIMARY_LINK],
			[ButtonVariant.PRIMARY_LINK_SMALL] : ButtonsConfigs[ButtonVariant.PRIMARY_LINK_SMALL],
			[ButtonVariant.SECONDARY_LINK] : ButtonsConfigs[ButtonVariant.SECONDARY_LINK],
			[ButtonVariant.SECONDARY_LINK_SMALL] : ButtonsConfigs[ButtonVariant.SECONDARY_LINK_SMALL],
		},
		title: {
			color: colors.black,
		},
	},
};
