import { FunctionalComponent, h } from 'preact';
import {
  CallToActionModel1Styled,
  ContentStyled,
  ImageStyled,
  LinkStyled,
  TitleStyled,
} from './call-to-action-model1.style';

interface CallToActionModel1Props {
  image: string;
  title: string;
  link: JSX.Element;
}
export const CallToActionModel1: FunctionalComponent<
  CallToActionModel1Props
> = (props) => {
  return (
    <CallToActionModel1Styled className='call-to-action-model1'>
      <ImageStyled>
        <img src={props.image} alt='' />
      </ImageStyled>
      <ContentStyled className='content'>
        <TitleStyled className='title'>{props.title}</TitleStyled>
        <LinkStyled>{props.link}</LinkStyled>
      </ContentStyled>
    </CallToActionModel1Styled>
  );
};
