import { Fragment, FunctionalComponent, h, options } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import {
  Control,
  FieldError,
  FieldErrors,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import {
  ErrorStyled,
  OptionsStyled,
  SelectFieldStyled,
  SelectorInputContainerStyled,
  SelectorInputStyled,
} from './select-field.style';

type Option = {
  label: string;
  value: string;
};

export type SelectFieldProps = {
  options: Option[];
  placeholder: string;
  error?: FieldError | FieldErrors<FieldValues> | undefined;
} & UseControllerProps;
export const SelectField: FunctionalComponent<SelectFieldProps> = (props) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const {
    field: { value, ref, onChange, ...inputProps },
  } = useController(props);
  const [inputValue, setInputValue] = useState<string>(value);
  const [inputLabel, setInputLabel] = useState<string>(value);

  const onSelectedOption = (value: string, e: any) => {
    onChange(value);
    setInputValue(value);
    setInputLabel(
      props.options.find((item) => item.value === value)?.label || ''
    );
    setShowOptions(false);
  };

  const showSelectorOptions = () => {
    setShowOptions(!showOptions);
  };

  useEffect(() => {
    if (props.defaultValue) {
      onSelectedOption(props.defaultValue, '');
    }
  }, [props.defaultValue]);

  return (
    <SelectFieldStyled className='select-field'>
      <SelectorInputContainerStyled onClick={showSelectorOptions}>
        <SelectorInputStyled
          type={'text'}
          //@ts-ignore
          placeholder={`${props.placeholder}${props.rules?.required === true || props.rules?.required?.value ? '*' : ''}`}
          id={props.name}
          value={inputLabel}
          readOnly={true}
        />
        <SelectorInputStyled
          ref={ref}
          {...inputProps}
          type={'hidden'}
          value={inputValue}
        />
      </SelectorInputContainerStyled>
      {props.options && showOptions && (
        <OptionsStyled className={`${showOptions && 'active'}`}>
          {props.options.map((item) => (
            <span onClick={onSelectedOption.bind(this, item.value)}>
              {item.label}
            </span>
          ))}
        </OptionsStyled>
      )}

      {props.error && <ErrorStyled>{props.error.message}</ErrorStyled>}
    </SelectFieldStyled>
  );
};
