import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';
import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const HeaderMenuContentStyled = styled.div`
  border-bottom: 1px solid #dadada;
  border-top: 1px solid #dadada;
`;

export const HeaderMenuStyled = styled.ul`
  max-width: ${Breakpoints.ML};
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  padding: 1rem 0;
  li {
    list-style: none;
    text-align: center;
  }
  a {
    font-family: 'BrandonGrotesque-Bold' !important;
    transition: color 0.5s ease-in-out;
    &.active {
      color: ${colors.black};
    }
    :hover {
      color: ${colors.black};
    }
    letter-spacing: 0px;
    font-weight: 900;
    font-size: 2rem;
    color: ${colors.orange};
    text-transform: uppercase;
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    display: none;
  }
`;

export const HeaderMenuMobileStyled = styled.ul`
  padding: 0 24px;

  li {
    list-style: none;
    text-align: left;
    font-weight: 900;
    font-size: 1.5rem;
    font-family: 'BrandonGrotesque-Bold';
    text-transform: uppercase;
    padding: 1rem;

    a {
      text-decoration: none;
      &.active {
        color: ${colors.black};
      }
    }

    &:not(:last-child) {
      border-bottom: 2px solid #d9d9d9;
    }
  }

  .button {
    color: ${colors.white};
    text-align: center;
    display: block;
    border-radius: 32px;
    margin: 32px auto;
    width: 75%;
    background-color: #F47D1F;
    border: none;
    height: 53px;
    line-height: 53px;
    font-size: 1.625rem;
  }

  @media screen and (min-width: ${Breakpoints.XL}) {
    display: none;
  }
`;
