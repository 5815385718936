import { BaseLayout } from "@greencross/pages/shared/base-layout/base-layout";
import { h } from "preact";
import { TERMS_AND_CONDITIONS_BODY, TERMS_AND_CONDITIONS_HEADER } from "./terms-and-conditions.strings";
import { TermsAndConditionsPageStyled } from "./terms-and-conditions.style";

export const TermsAndConditionsPage = () => (
	<BaseLayout>
		<TermsAndConditionsPageStyled>
			<h1>{TERMS_AND_CONDITIONS_HEADER}</h1>
			{TERMS_AND_CONDITIONS_BODY}
		</TermsAndConditionsPageStyled>
	</BaseLayout>
);