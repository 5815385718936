import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';
import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const ContactUsFormStyled = styled.div`
  position: relative;
  width: 1023px;
  margin: 0 auto;
  border: 2px solid #dadada;
  border-radius: 24px;
  padding: 64px 50px;
  @media screen and (max-width: ${Breakpoints.XL}) {
    width: 90%;
    padding: 48px 24px;
  }
  @media screen and (max-width: ${Breakpoints.XS}) {
    width: 93%;
    padding: 32px 15px;
  }
`;

export const ContactUsFormFieldsStyled = styled.div`
  margin: 0 auto;
  h3 {
    color: ${colors.black};
  }
  button {
    min-width: 320px;
    margin: 0 auto;
    background-color: ${colors.orange};
    border: none;
    font-size: 1.625rem;
    margin-top: 32px;
    @media screen and (max-width: ${Breakpoints.XS}) {
      width: 100%;
      min-width: auto;
    }
  }
  .visually-hidden {
    height: 0;
    padding: 0;
    margin: 0;
    border: none;
  }
  .select-field {
    margin-top: 0;
  }
  .message {
    text-align: center;
  }
`;

export const ContactUsFormContentStyled = styled.div`
  z-index: 1;
  position: relative;

  h1 {
    color: ${colors.black};
    font-size: 2.5rem;
    text-align: center;
    margin-top: 0;
    margin-bottom: 2em;
    font-family: 'BrandonGrotesque-Bold';
    @media screen and (max-width: ${Breakpoints.XL}) {
      font-size: 2.8rem;
    }
    @media screen and (max-width: ${Breakpoints.S}) {
      font-size: 2rem;
      margin-bottom: 1em;
    }
  }

  .disclaimer {
    margin: 38px 0;
  }

  p {
    color: #666666;
    font-size: 1.375rem;
    margin: 0.5em 0;
  }
  h3 {
    font-size: 2rem;
    font-family: 'OpenSans-Bold';
    margin: 0;
  }

  form {
    margin-top: 2em;
    > div {
      position: relative;
    }

    .select-field {
      width: 47.5%;
      border: none;
      outline: none;
      font-size: 1.625rem;
      background-color: transparent;
      background-image: url('../../../../assets/icons/chevron-down.svg');
      background-repeat: no-repeat;
      background-position: calc(100% - 0px) calc(22px);
      background-size: 15px;

      @media screen and (max-width: ${Breakpoints.S}) {
        width: 100%;
      }
    }
    .message-field {
      margin: 32px 0;
    }
    input {
      background: transparent;
      border: none;
      border-bottom: 2px solid #000;
      font-size: 1.625rem;
      &::placeholder {
        color: #000;
      }
    }
  }
`;

export const PersonalDataContentStyled = styled.div`
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  > div {
    float: left;
    width: 50%;
    min-height: 70px;

    > div {
      border-bottom-width: 0;
    }
    &:nth-child(odd) {
      padding-right: 2em;
    }
    &:nth-child(even) {
      padding-left: 2em;
    }
    &.membershipnumber-field {
      @media screen and (max-width: ${Breakpoints.L}) {
        width: 100%;
        padding-right: 0;
      }
    }
    @media screen and (max-width: ${Breakpoints.S}) {
      width: 100%;
      min-height: auto;
      &:nth-child(odd) {
        padding-right: 0em;
      }
      &:nth-child(even) {
        padding-left: 0em;
      }
    }
  }
`;

export const MessageFieldStyled = styled.textarea`
  width: 100%;
  resize: none;
  outline: none;
`;

export const OverlayStyled = styled.div`
  z-index: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 0.9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 23px;
`;

export const NeedHelpContentStyled = styled.div`
  width: 50%;
  padding: 2em 3em;
  background-color: ${colors.lightblack};
  color: ${colors.white};
  border-radius: 2em;
  margin-top: 6em;
  font-size: 9px;
  h3 {
    text-transform: uppercase;
    padding: 0.5em 0 1em;
    font-size: 1.9em;
  }
  p {
    color: ${colors.white};
    font-family: 'BrandonGrotesque-Medium';
    font-size: 2em;
    padding: 1em 0;
    margin: 0;
    &:nth-child(2) {
      border-bottom: 3px solid #fff;
      border-top: 3px solid #fff;
    }
    &:nth-child(4) {
      padding-top: 0;
    }
    strong {
      font-family: 'BrandonGrotesque-Bold';
    }
  }
  a {
    color: ${colors.white};
    font-size: 1.7rem;
    @media screen and (max-width: ${Breakpoints.S}) {
      font-size: 1.2rem;
    }
  }
  @media screen and (max-width: ${Breakpoints.S}) {
    width: 100%;
    font-size: 7px;
    text-align: center;
  }
`;
