import {
  AuthenticatedRoute,
  ExecRoute,
} from '@authentic/loyalty-pwa/components';
import { SignInPage } from '@authentic/loyalty-pwa/Pages/unauthenticated/sign-in/sign-in.page';
import { SIGN_IN_URL } from '@authentic/loyalty-pwa/Pages/unauthenticated/sign-in/sign-in.strings';

import { UserProvider } from '@authentic/loyalty-pwa/providers';
import { HomePage } from '@greencross/pages/unauthenticated/home/home.page';
import { HOME_URL } from '@greencross/pages/unauthenticated/home/home.strings';
import { ForgotPasswordPage } from '@greencross/pages/unauthenticated/forgot-password/forgot-password.page';
import { h, toChildArray } from 'preact';
import { Route } from 'preact-router';
import { SignupPage } from '../pages/unauthenticated/sign-up/sign-up.page';
import { PageNotFound } from '@greencross/overrides/Pages/page-not-found/PageNotFound';
import { MY_REWARDS_URL } from '@greencross/pages/authenticated/my-rewards/my-rewards.strings';
import { MyRewardsPage } from '@greencross/pages/authenticated/my-rewards/my-rewards.page';
import { SIGNUP_URL } from '@greencross/pages/unauthenticated/sign-up/sign-up.strings';
import { VerificationEmailPage } from '@greencross/pages/unauthenticated/verification-email/verification-email.page';
import { AboutUsPage } from '@greencross/pages/unauthenticated/about-us/about-us.page';
import { ABOUT_US_URL } from '@greencross/pages/unauthenticated/about-us/about-us.strings';
import { TERMS_AND_CONDITIONS_URL } from '@greencross/pages/unauthenticated/terms-and-conditions/terms-and-conditions.strings';
import { TermsAndConditionsPage } from '@greencross/pages/unauthenticated/terms-and-conditions/terms-and-conditions.page';
import { LA_ROCHE_POSAY_URL } from '@greencross/pages/unauthenticated/la-roche-posay/la-roche-posay.strings';
import { LaRochePosayPage } from '@greencross/pages/unauthenticated/la-roche-posay/la-roche-posay.page';
import { VERIFICATION_EMAIL_URL } from '@greencross/pages/unauthenticated/verification-email/verification-email.strings';
import { MORE_ABOUT_URL } from '@greencross/pages/unauthenticated/more-about/more-about.strings';
import { MoreAboutPage } from '@greencross/pages/unauthenticated/more-about/more-about.page';
import { PRIVACY_POLICY_URL } from '@greencross/pages/unauthenticated/privacy-policy/privacy-policy.strings';
import { PrivacyPolicyPage } from '@greencross/pages/unauthenticated/privacy-policy/privacy-policy.page';
import { VerificationCompletedPage } from '@greencross/pages/unauthenticated/verification-completed/verification-completed.page';
import { VERIFICATION_COMPLETED_URL } from '@greencross/pages/unauthenticated/verification-completed/verification-completed.strings';
import {ChangePasswordPage} from "@greencross/pages/unauthenticated/change-password/change-password.page";
import {CHANGE_PASSWORD_LINK} from "@greencross/pages/unauthenticated/change-password/change-password.strings";
import { PROFILE_URL } from '@greencross/pages/authenticated/profile/profile.strings';
import { ProfilePage } from '@greencross/pages/authenticated/profile/profile.page';
import { FAQ_URL } from '@greencross/pages/unauthenticated/faq/faq.strings';
import { FAQPage } from '@greencross/pages/unauthenticated/faq/faq.page';
import { ContactUsPage } from '@greencross/pages/unauthenticated/contact-us/contact-us.page';
import { CONTACT_US_URL } from '@greencross/pages/unauthenticated/contact-us/contact-us.strings';
import { CONTACT_US_THANK_YOU_URL } from '@greencross/pages/unauthenticated/contact-us/thank-you/thank-you-info.strings';
import { SUCCESSFUL_SIGNUP_PAGE_URL } from '@greencross/pages/unauthenticated/sign-up/success/success-signup.strings';
import { SuccessfulSignupPage } from '@greencross/pages/unauthenticated/sign-up/success/success-signup.page';
import { SPECIAL_INTERESTS_URL } from '@greencross/pages/authenticated/special-interests/special-interests.strings';
import { SpecialInterestsPage } from '@greencross/pages/authenticated/special-interests/special-interests.page';
import { PHARMACY_LOCATOR_URL } from '@greencross/pages/unauthenticated/pharmacy-locator/pharmacy-locator.strings';
import { PharmacyLocatorPage } from '@greencross/pages/unauthenticated/pharmacy-locator/pharmacy-locator.page';
import { MemberExclusivesPage } from '@greencross/pages/authenticated/member-exclusives/member-exclusives.page';
import { MEMBER_EXCLUSIVES_URL } from '@greencross/pages/authenticated/member-exclusives/member-exclusives.strings';

export const ProjectRoutes = toChildArray([
  <Route path={SIGNUP_URL} component={SignupPage} key='sign-up' />,
  <AuthenticatedRoute
    invert
    path={SIGN_IN_URL}
    component={SignInPage}
    fallback='/'
    key='sign-in'
  />,
  <Route
    path='/forgot-password'
    component={ForgotPasswordPage}
    key='forgot-password'
  />,
  <AuthenticatedRoute
    path={CHANGE_PASSWORD_LINK}
    component={ChangePasswordPage}
    key="change-password"
    fallback={SIGN_IN_URL}
  />,
  <AuthenticatedRoute
    path={SPECIAL_INTERESTS_URL}
    component={SpecialInterestsPage}
    key="special-interests"
    fallback={SIGN_IN_URL}
  />,
  <AuthenticatedRoute
    path={MEMBER_EXCLUSIVES_URL}
    component={MemberExclusivesPage}
    key='member-exclusives'
    fallback={SIGN_IN_URL}
  />,
  <Route
    path={VERIFICATION_EMAIL_URL}
    component={VerificationEmailPage}
    key='verification-email'
  />,
  <Route
    path={PHARMACY_LOCATOR_URL}
    component={PharmacyLocatorPage}
    key='pharmacy-locator'
  />,
  <Route
    path={CONTACT_US_URL}
    component={ContactUsPage}
    key='contact-us'
  />,
  <Route
    path={CONTACT_US_THANK_YOU_URL}
    component={ContactUsPage}
    key='contact-us-thank-you'
  />,
  <Route path={HOME_URL} component={HomePage} key='home' />,
  <Route path={TERMS_AND_CONDITIONS_URL} component={TermsAndConditionsPage} key='terms-and-conditions' />,
  <Route path={LA_ROCHE_POSAY_URL} component={LaRochePosayPage} key='la-roche-posay' />,
  <Route path={ABOUT_US_URL} component={AboutUsPage} key='about-us' />,
  <Route path={MORE_ABOUT_URL} component={MoreAboutPage} key='more-about' />,
  <Route path={PRIVACY_POLICY_URL} component={PrivacyPolicyPage} key='privacy-policy' />,
  <Route path={FAQ_URL} component={FAQPage} key='faqs-subpages' />,
  <Route path={`${FAQ_URL}/:subpage`} component={FAQPage} key='faqs-subpages' />,
  <AuthenticatedRoute path={VERIFICATION_COMPLETED_URL} component={VerificationCompletedPage} key='verify-email' invert />,
  <AuthenticatedRoute path={SUCCESSFUL_SIGNUP_PAGE_URL} component={SuccessfulSignupPage} key='successful-signup' invert />,
  <AuthenticatedRoute
    path={MY_REWARDS_URL}
    component={MyRewardsPage}
    fallback={SIGN_IN_URL}
  />,
  <AuthenticatedRoute
    path={PROFILE_URL}
    component={ProfilePage}
    fallback={SIGN_IN_URL}
  />,
  <ExecRoute path='/sign-out' exec={UserProvider.logout} key='sign-out' />,
  <Route default component={PageNotFound} key='not-found' />,
]);
