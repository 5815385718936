import { Fragment, FunctionalComponent, h } from 'preact';
import { BaseLayout } from '@greencross/pages/shared/base-layout/base-layout';
import { Card } from '@greencross/components/card/card.style';
import {
  ButtonContainer,
  Title,
} from '@greencross/overrides/design-system/components/base/cta-info/variant2/cta-info.variant2.style';
import {
  Button,
  ButtonType,
  ButtonVariant,
} from '@greencross/overrides/design-system/components/base/button/button.component';
import { useCallback, useContext, useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';
import { SIGN_IN_URL } from '@greencross/overrides/Pages/unauthenticated/sign-in/sign-in.strings';
import { getImageSrc } from '@authentic/loyalty-pwa/util';
import { BackgroundImage } from '@greencross/components/background-image/background-image.component';
import {
  BACK_TO_SIGNIN_BTN_TEXT,
  SUCCESSFUL_SIGNUP_PAGE_TITLE
} from './success-signup.strings';
import { SuccessfulSignupStyled } from './success-signup.style';

export const SuccessfulSignupPage: FunctionalComponent = () => {
  const handleBackToSignIn = useCallback(() => {
    route(SIGN_IN_URL);
  }, []);

  return (
    <SuccessfulSignupStyled>
      <BaseLayout>
        <div className='content-container'>
          <BackgroundImage
            desktop={`url(${getImageSrc(
              '/assets/images/backgrounds/GXH_Background_Desktop_4_OldCouple_75c5d7v2.jpg'
            )})`}
            mobile={`url(${getImageSrc(
              '/assets/images/backgrounds/GXH_Background_Mobile_4_OldCouple_75c5d7.jpg'
            )})`}
          />
          <Card>
            <Fragment>
              <Title>{SUCCESSFUL_SIGNUP_PAGE_TITLE}</Title>
              <div className='successful-signup-container'>
                <ButtonContainer>
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    type={ButtonType.BUTTON}
                    onClick={handleBackToSignIn}
                  >
                    {BACK_TO_SIGNIN_BTN_TEXT}
                  </Button>
                </ButtonContainer>
              </div>
            </Fragment>
          </Card>
        </div>
      </BaseLayout>
    </SuccessfulSignupStyled>
  );
};
