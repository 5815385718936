import { FORGOT_PASSWORD_URL } from "@authentic/loyalty-pwa/design-system/components/cheetah/unauthenticated/sign-in/sign-in.strings";
import { getImageSrc, route } from "@authentic/loyalty-pwa/util";
import { ABOUT_US_URL } from "../about-us/about-us.strings";
import { SIGNUP_URL } from "../sign-up/sign-up.strings";
import { MORE_ABOUT_URL } from "../more-about/more-about.strings";

export type CarouselImage = {
  path: string;
  onClick: () => void
};

export class HomeCarouselImagesModel {
  private toReplace: string = 'INCREMENTAL_NUMBER';
  private _baseURL: string = '';
  private _path: string = '';
  private _nameFormat: string = '';
  private _imagesQuantity: number = 0;

  constructor() {
    this.baseURL = process.env.HOME_CAROUSEL_IMAGE_BASE_URL || '';
    this.path = process.env.HOME_CAROUSEL_IMAGE_PATH || '';
    this.nameFormat = process.env.HOME_CAROUSEL_IMAGE_NAME_FORMAT || '';
    this.imagesQuantity = parseInt(
      process.env.HOME_CAROUSEL_IMAGE_QUANTITY || ''
    );
  }

  private getImageURL(x: number): string {
    return `${this.baseURL}${this.path}/${this.nameFormat.replace(
      this.toReplace,
      x.toString()
    )}`;
  }

  public getImages(): CarouselImage[] {
    let images = new Array(this.imagesQuantity).fill(0);
    const carouselImages = images.map((item, index) => {
      return {
        path: this.getImageURL(index + 1),
        onClick: () => {
          switch (index) {
            case 0:
              route(ABOUT_US_URL);
              break;
            case 1:
              route(MORE_ABOUT_URL);
              break;
            case 2:
              route(ABOUT_US_URL);
              break;
            case 3:
              route(MORE_ABOUT_URL);
              break;
          }
        }
      };
    });

    // const currentTime = Date.now();
    // if (currentTime > 1674990000000 && currentTime < 1679745600000)
    //   carouselImages.unshift({
    //     path: getImageSrc('/assets/images/home-banners/Maxigesic.jpg'),
    //     onClick: () => window.location.href = 'https://www.lifepharmacy.co.nz/offers-and-promotions/maxigesic/win-a-million/'
    //   });

    return carouselImages;
  }

  get baseURL(): string {
    return this._baseURL;
  }

  set baseURL(baseURL: string) {
    this._baseURL = baseURL;
  }

  get path(): string {
    return this._path;
  }

  set path(path: string) {
    this._path = path;
  }

  get nameFormat(): string {
    return this._nameFormat;
  }

  set nameFormat(nameFormat: string) {
    this._nameFormat = nameFormat;
  }

  get imagesQuantity(): number {
    return this._imagesQuantity;
  }

  set imagesQuantity(imagesQuantity: number) {
    this._imagesQuantity = imagesQuantity;
  }
}
