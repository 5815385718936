import { getImageSrc } from '@authentic/loyalty-pwa/util';
import { BackgroundImage } from '@greencross/components/background-image/background-image.component';
import { BaseLayout } from '@greencross/pages/shared/base-layout/base-layout';
import { FunctionalComponent, h } from 'preact';
import { MyRewardsDetail } from './detail/my-rewards-detail';
import {
  MyRewardsPageContentStyled,
  MyRewardsPageStyled,
} from './my-rewards.style';

interface MyRewardsProps {}
export const MyRewardsPage: FunctionalComponent<MyRewardsProps> = (props) => {
  return (
    <MyRewardsPageStyled>
      <BaseLayout>
        <MyRewardsPageContentStyled className='my-rewards-page-content'>
          {
            <BackgroundImage
              desktop={`url(${getImageSrc(
                '/assets/images/backgrounds/background-myrewards-desktop.jpg'
              )})`}
              mobile={`url(${getImageSrc(
                '/assets/images/backgrounds/background-myrewards-mobile.jpg'
              )})`}
            ></BackgroundImage>
          }

          <MyRewardsDetail></MyRewardsDetail>
        </MyRewardsPageContentStyled>
      </BaseLayout>
    </MyRewardsPageStyled>
  );
};
