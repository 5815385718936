import styled from 'styled-components';

export const breakpoints = {
  xss: '360px',
  xs: '480px',
  sm: '600px',
  ms: '768px',
  md: '960px',
  lg: '1127px',
};

export const GridLayout = styled.div`
  width: ${breakpoints.lg};
  margin: 0 auto;
  padding: 24px;
  @media screen and (max-width: ${breakpoints.lg}) {
    width: 100%;
  }
`;

export type LayoutInterface = {
  display?: string;
  p?: number;
  m?: number;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
  cg?: number; //space between column items
  rg?: number; //space between row items
};

export const Layout = styled.div<LayoutInterface>`
  display: ${(p) => (!p.display ? 'flex' : p.display)};
  align-items: center;
  align-content: center;
  ${({ m }) => m && `padding: ${m}px;`}
  ${({ p }) => p && `padding: ${p}px;`}
  ${({ mt }) => mt && `margin-top: ${mt}px;`}
	${({ mb }) => mb && `margin-bottom: ${mb}px;`}
	${({ ml }) => ml && `margin-left: ${ml}px;`}
	${({ mr }) => mr && `margin-right: ${mr}px;`}
  ${({ pt }) => pt && `padding-top: ${pt}px;`}
	${({ pb }) => pb && `padding-bottom: ${pb}px;`}
	${({ pl }) => pl && `padding-left: ${pl}px;`}
	${({ pr }) => pr && `padding-right: ${pr}px;`}
  ${({ cg }) => cg && `column-gap: ${cg}px;`}
  ${({ rg }) => rg && `row-gap: ${rg}px;`}
`;

export type FormLayoutInterface = {
  headingAlign: string;
} & LayoutInterface;
export const FormLayout = styled.form<{ customStyle: FormLayoutInterface }>`
  width: 480px;
  margin: 0 auto;
  h1 {
    text-align: ${(p) => p.customStyle.headingAlign};
  }
  @media screen and (max-width: ${breakpoints.xs}) {
    width: 100%;
    padding: 24px;
  }
`;
