import { Fragment, FunctionalComponent, h } from 'preact';
import { UserServiceContext } from '@authentic/loyalty-pwa/providers';
import {
  ForgotPasswordFormContentStyled,
  ForgotPasswordFormFieldsStyled,
  ForgotPasswordFormStyled,
  OverlayStyled,
} from '../form/forgot-password-form.style';
import {
  TextField,
  TextFieldType,
} from '@authentic/loyalty-pwa/design-system/components/base/text-field/text-field.component';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useContext, useState } from 'preact/hooks';
import {
  Button,
  ButtonType,
  ButtonVariant,
} from '@greencross/overrides/design-system/components/base/button/button.component';
import {
  FIELD_CONFIRMPASSWORD_ID,
  FormValues,
  FIELD_PASSWORD_ID,
  FIELD_PASSWORD_LABEL,
  VALIDATION_MESSAGES,
  FIELD_CONFIRMPASSWORD_LABEL,
  SUCCESSFUL_MESSAGE_FORGOT_PASSWORD,
} from './forgot-password-update.constants';
import zxcvbn from 'zxcvbn';
import { PasswordStrength } from '@greencross/components/password-strength/password-strength.component';
import {
  BACK_TO_SIGNIN_BTN_TEXT,
  FORGOT_PASSWORD_BUTTON,
  FORGOT_PASSWORD_HEADER,
} from '../form/forgot-password-form.strings';
import { Link } from 'preact-router';
import { SIGN_IN_URL } from '@authentic/loyalty-pwa/Pages/unauthenticated/sign-in/sign-in.strings';

export const ForgotPasswordUpdateForm: FunctionalComponent<{
  token: string;
}> = ({ token }) => {
  const userService = useContext(UserServiceContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [completed, setCompleted] = useState<false | string>(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setError,
    watch,
  } = useForm();
  const password = watch('password');

  const onSubmit: SubmitHandler<any> = (data: FormValues) => {
    console.log('data: ', data);
    setLoading(true);
    userService
      ?.changePassword({
        password: data[FIELD_PASSWORD_ID],
        passwordConfirmation: data[FIELD_CONFIRMPASSWORD_ID],
        resetPasswordToken: token,
      })
      .then((res: any) => {
        if (res.success) {
          formReset();
          setCompleted(SUCCESSFUL_MESSAGE_FORGOT_PASSWORD);
        } else {
          setError(FIELD_PASSWORD_ID, { message: res.message });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const onError = (error: any) => {
    console.log('error: ', error);
  };

  const formReset = () => {
    reset({
      [FIELD_PASSWORD_ID]: '',
      [FIELD_CONFIRMPASSWORD_ID]: '',
    });
  };

  return (
    <ForgotPasswordFormStyled className='forgotpassword-form'>
      <ForgotPasswordFormContentStyled>
        <h1>{FORGOT_PASSWORD_HEADER}</h1>

        <ForgotPasswordFormFieldsStyled>
          {completed && (
            <Fragment>
              <p>{completed}</p>
              <Link href={SIGN_IN_URL}>
                <Button
                  variant={ButtonVariant.PRIMARY}
                  type={ButtonType.SUBMIT}
                >
                  {BACK_TO_SIGNIN_BTN_TEXT}
                </Button>
              </Link>
            </Fragment>
          )}
          {!completed && (
            //@ts-ignore
            <form onSubmit={handleSubmit(onSubmit, onError)} autoComplete='off'>
              <TextField
                label={FIELD_PASSWORD_LABEL}
                name={FIELD_PASSWORD_ID}
                id={FIELD_PASSWORD_ID}
                type={TextFieldType.PASSWORD}
                variant='password'
                control={control}
                error={errors[FIELD_PASSWORD_ID]}
                disabled={loading}
                rules={{
                  required: {
                    value: true,
                    message: VALIDATION_MESSAGES.REQUIRED,
                  },
                  validate: (value: string) =>
                    zxcvbn(value).score >= 2 || 'Password is not strong enough',
                }}
                description={<PasswordStrength password={password} />}
              />

              <TextField
                label={FIELD_CONFIRMPASSWORD_LABEL}
                name={FIELD_CONFIRMPASSWORD_ID}
                id={FIELD_CONFIRMPASSWORD_ID}
                type={TextFieldType.PASSWORD}
                variant='password'
                control={control}
                error={errors[FIELD_CONFIRMPASSWORD_ID]}
                disabled={loading}
                rules={{
                  required: {
                    value: true,
                    message: VALIDATION_MESSAGES.REQUIRED,
                  },
                  validate: (val: string) =>
                    val == password || VALIDATION_MESSAGES.DIFFERENT_PASSWORD,
                }}
              />

              <Button
                disabled={loading}
                variant={ButtonVariant.PRIMARY}
                type={ButtonType.SUBMIT}
              >
                {FORGOT_PASSWORD_BUTTON}
              </Button>
            </form>
          )}
        </ForgotPasswordFormFieldsStyled>
      </ForgotPasswordFormContentStyled>
      <OverlayStyled></OverlayStyled>
    </ForgotPasswordFormStyled>
  );
};
