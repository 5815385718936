import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import {
  ErrorStyled,
  TextFieldStyled,
} from '@authentic/loyalty-pwa/design-system/components/base/text-field/text-field.style';
import { Text2 } from '@authentic/loyalty-pwa/design-system/style-guide/typography';
import { addressLookup } from '@greencross/utils/api';
import { default as ReactAutocomplete } from 'react-autocomplete';
import { colors } from '@authentic/loyalty-pwa/util/variables';
import {
  AddressFieldItemStyled,
  AddressFieldStyled,
} from './address-field.style';
import { VALIDATION_MESSAGE } from './address-field.strings';
import {
  Control,
  FieldError,
  FieldErrors,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form';
import { InputHTMLAttributes } from 'react';

export type AddressFieldProps = {
  name: string;
  onSelect?: (value: string) => void;
  setValidationMessage?: (message: string | true) => void;
  id?: string;
  label: string;
  error?: FieldError | FieldErrors<FieldValues> | undefined;
  control?: Control<any>;
} & UseControllerProps &
  InputHTMLAttributes<HTMLInputElement>;

let debounceTimer: string | false = false;

const debounce = (delay: number, fn: () => void) => {
  if (debounceTimer) clearTimeout(debounceTimer);
  debounceTimer = setTimeout(() => fn(), delay) as unknown as string;
};

export const AddressField = (props: AddressFieldProps) => {
  const [options, setOptions] = useState<Record<string, string>>({});
  const [inputValue, setInputValue] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(false);

  const queryAddresses = (address: string) => () => {
    addressLookup(address).then((addresses) => {
      setOptions(addresses);
    });
  };

  const onKeyUp = (event: any) => {
    const address = event.target.value;
    if (address !== inputValue && address.length > 3) {
      debounce(300, queryAddresses(address));
    }
    if (props.setValidationMessage && address.length > 0)
      props.setValidationMessage(VALIDATION_MESSAGE);
    else if (props.setValidationMessage && address.length === 0)
      props.setValidationMessage(true);
    setInputValue(address);
  };

  useEffect(() => {
    if (props.defaultValue) {
			setIsActive(true);
			setInputValue(props.defaultValue);
      addressLookup(props.defaultValue).then((addresses) => {
        console.log('get defaultValue: ', addresses);
      });
    }
  }, [props.defaultValue]);

  return (
    <AddressFieldStyled>
      <TextFieldStyled className={`${props.name}-field ${props.error && props.error.message && 'error'}`}>
        <Text2
          htmlFor={`${props.name}-text-input`}
          className={`${isActive || props.value ? 'active' : ''}`}
        >
          {props.label}
          {props.required ? '*' : ''}
        </Text2>
        <ReactAutocomplete
          items={Object.keys(options)}
          value={inputValue}
          renderItem={(item, isHighlighted) => (
            <AddressFieldItemStyled
              className={isHighlighted ? 'active' : 'inactive'}
            >
              {options[item]}
            </AddressFieldItemStyled>
          )}
          renderInput={(inputProps) => (
            <input
              id={`${props.name}-text-input`}
              {...(inputProps as any)}
              onFocus={props.onFocus}
              onKeyUp={onKeyUp}
            />
          )}
          onSelect={(value) => {
            setInputValue(options[value]);
            if (props.setValidationMessage) props.setValidationMessage(true);
            if (props.onSelect) props.onSelect(value);
            setOptions({});
          }}
          getItemValue={(item) => item}
          menuStyle={{
            borderRadius: '3px',
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
            background: colors.white,
            padding: '2px 0',
            fontSize: '90%',
            position: 'absolute',
            maxHeight: '200px',
            top: '35px',
            left: 0,
            overflowY: 'scroll',
            overflowX: 'hidden',
            zIndex: 1
          }}
          wrapperStyle={{}}
        />
        {props.error && <ErrorStyled>{props.error.message}</ErrorStyled>}
      </TextFieldStyled>
    </AddressFieldStyled>
  );
};
