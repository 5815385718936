import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const MemberExclusivesPageStyled = styled.div`
  .background-image {
    @media screen and (max-width: ${Breakpoints.XL}) {
      background-size: auto;
    }
    @media screen and (max-width: ${Breakpoints.S}) {
      background-size: cover;
    }
  }
`;
export const MemberExclusivesPageContentStyled = styled.div`
  position: relative;
  min-height: 720px;
  padding: 48px 0;
  .background-image {
    background-color: #f99dac;
  }
  @media screen and (max-width: ${Breakpoints.M}) {
    min-height: 600px;
  }
  @media screen and (max-width: ${Breakpoints.S}) {
    min-height: 500px;
  }
`;

export const MemberExclusivesDetailStyled = styled.div`
  position: relative;
  max-width: ${Breakpoints.ML};
  margin: 0 auto;
`;

export const MemberExclusivesContentDetailStyled = styled.div`
  position: relative;
  width: 1024px;
  margin: 0 auto;
  border: 2px solid #dadada;
  border-radius: 24px;
  padding: 55px;
  @media screen and (max-width: ${Breakpoints.XL}) {
    width: 96%;
    padding: 48px 24px;
  }
`;

export const MemberExclusivesLayoutStyled = styled.div`
  z-index: 1;
  position: relative;
  text-align: center;
  p {
    font-size: 2rem;
    font-family: BrandonGrotesque-Medium;
    width: 640px;
    margin: 0 auto;
    @media screen and (max-width: ${Breakpoints.M}) {
      width: 100%;
      font-size: 1.5rem;
    }
  }
`;
