export const getQueryString = () => {
  const baseQueryString = window.location.search.substring(1);
  const qs = baseQueryString.replace(/&nbsp;/g, '');
  if (qs.length === 0) return {};
  return JSON.parse(
    '{"' +
      decodeURI(qs)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
};
