import { SnackbarHandler, SnackbarType } from "@authentic/loyalty-pwa/design-system/components/base/snackbar/snackbar";
import { SignInForm } from "@authentic/loyalty-pwa/design-system/components/cheetah/unauthenticated/sign-in/sign-in.component";
import { getImageSrc, route } from "@authentic/loyalty-pwa/util";
import { FunctionalComponent, h } from "preact";
import { SignInContentStyled, SignInFormStyled, SignInPageStyled } from "@authentic/loyalty-pwa/Pages/unauthenticated/sign-in/sign-in.style";
import { BaseLayout } from '@greencross/pages/shared/base-layout/base-layout';
import { BackgroundImage } from "@greencross/components/background-image/background-image.component";
import { MY_REWARDS_URL } from '@greencross/pages/authenticated/my-rewards/my-rewards.strings';

export interface SignInPageProps {
}

export const SignInPage: FunctionalComponent<SignInPageProps> = (props) => {
  const onChangeSubmit = (res: any) => {
    console.log('onChangeSubmit: ', res);
    if (res.success) {
      route(MY_REWARDS_URL);
    } else {
      SnackbarHandler.open(false, SnackbarType.Error, res.message, 6000);
    }
  };

  return (
    <SignInPageStyled>
    	<BaseLayout>
    		<SignInContentStyled>
    			{
					<BackgroundImage
						desktop={`url(${getImageSrc('/assets/images/backgrounds/Background-Signin-desktop.jpg')})`}
						mobile={`url(${getImageSrc('/assets/images/backgrounds/Background-Signin-mobile.jpg')})`}
					></BackgroundImage>

				}
					<SignInFormStyled>
	  				<SignInForm
				        onSubmit={onChangeSubmit}
	      			/>
	      		</SignInFormStyled>
  			</SignInContentStyled>
  		</BaseLayout>
	</SignInPageStyled>
  )
};