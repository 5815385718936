import styled from 'styled-components';
import { colors } from '@greencross/overrides/design-system/style-guide/color';

interface IProgressBarBaseStyledProps {
  color?: string;
  height?: string;
}

interface IProgressBarStyledProps extends IProgressBarBaseStyledProps {
  percentage?: number;
}

export const ProgressBarStyled = styled.div<IProgressBarStyledProps>`
  background-color: ${(props) => (props.color ? props.color : colors.primary)};
  border-radius: 50px;
  transition: all 0.5s;
  height: ${(props) => (props.height ? props.height : '1em')};
  width: ${(props) => (props.percentage ? props.percentage : 0)}%;
`;

export const ProgressBarContainerStyled = styled.div<IProgressBarBaseStyledProps>`
  height: calc(${(props) => (props.height ? props.height : '1em')} + 2px);
  border: 1px solid ${(props) => (props.color ? props.color : colors.primary)};
  border-radius: 50px;
  margin-top: 0.4em !important;
`;
