import styled from 'styled-components';

export const TextAreaFieldStyled = styled.div<{ error: any }>`
  textarea {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: ${({ error }) => (error ? 'red' : '')};
  }
  label {
    font-size: 1.6rem;
    margin-bottom: 0.5em;
    display: block;
  }
`;
export const TextAreaStyled = styled.textarea`
  width: 100%;
  resize: none;
  outline: none;
  padding: 1em;
  font-family: OpenSans-Regular;
  font-size: 1.3rem;
`;

export const ErrorStyled = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 6px;
`;
