import { Fragment, FunctionalComponent, h } from 'preact';
import { BackgroundImage } from '../../../components/background-image/background-image.component';
import { UnauthenticatedWrapper } from '../unauthenticated.wrapper';
import { SignupPageContentStyled, SignupPageStyled } from './sign-up.style';
import { getImageSrc } from '@authentic/loyalty-pwa/util';
import { BaseLayout } from '../../shared/base-layout/base-layout';
import { SignupForm } from './form/sign-up-form';

interface SignupProps {}
export const SignupPage: FunctionalComponent<SignupProps> = (props) => {
  return (
    <SignupPageStyled className='signup-page'>
      <BaseLayout>
        <UnauthenticatedWrapper>
          <SignupPageContentStyled className='signup-content'>
            {
              <BackgroundImage
                desktop={`url(${getImageSrc('/assets/images/backgrounds/Background-Signup-desktop.jpg')})`}
                mobile={`url(${getImageSrc('/assets/images/backgrounds/Background-Signup-mobile.jpg')})`}
              ></BackgroundImage>
            }

            <SignupForm></SignupForm>
          </SignupPageContentStyled>
        </UnauthenticatedWrapper>
      </BaseLayout>
    </SignupPageStyled>
  );
};
