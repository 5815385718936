import { OfferResponsesResult } from '@authentic/loyalty-pwa/interfaces';
import { OfferServiceContext, useUser } from '@authentic/loyalty-pwa/providers';
import { Submenu } from '@greencross/components/submenu/submenu.component';
import { CHANGE_PASSWORD_LINK } from '@greencross/pages/unauthenticated/change-password/change-password.strings';
import { FunctionalComponent, h } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { PROFILE_URL } from '../../profile/profile.strings';
import { SPECIAL_INTERESTS_URL } from '../../special-interests/special-interests.strings';
import {
  MY_REWARDS_PAGE_AVAILABLE_TEXT,
  MY_REWARDS_PAGE_DONT_FORGET_TEXT,
  MY_REWARDS_PAGE_MEMBERSHIP_TEXT,
  MY_REWARDS_PAGE_MY_POINTS_BALANCE_TEXT,
  MY_REWARDS_PAGE_MY_VOUCHERS_TEXT,
  MY_REWARDS_PAGE_REMAINING_POINTS_TEXT,
  MY_REWARDS_PAGE_TITLE,
  MY_REWARDS_PAGE_TOTAL_POINTS_TEXT,
  MY_REWARDS_PAGE_WELCOME_TEXT,
} from './my-rewards-detail.strings';
import {
  CardBalanceStyled,
  CardContentStyled,
  CardsContainerStyled,
  CardTitleStyled,
  CircularOfferLabelStyled,
  CircularOfferStyled,
  CircularOfferTextStyled,
  DescriptionStyled,
  LevelLineStyled,
  MyRewardsDetailContentStyled,
  MyRewardsDetailStyled,
  OverlayStyled,
  ProgressBarWrapperStyled,
  TitleStyled,
} from './my-rewards-detail.style';

interface MyRewardsDetailProps {}
export const MyRewardsDetail: FunctionalComponent<MyRewardsDetailProps> = (
  props
) => {
  // this is a default value but it's calculated by the getVoucherValue function
  const defaultVoucherValue = 10;
  const [pointsInPercentage, setPointsInPercentage] = useState<number>(0);
  const [pointsToEarnVoucher, setPointsToEarnVoucher] = useState(150); //TODO: Ask to the cheetah team where could we get this value.
  const [remainingPoints, setRemainingPoints] = useState(0);
  const [voucherValue, setVoucherValue] = useState(defaultVoucherValue);
  const [remainingPointsText, setRemainingPointsText] = useState('');
  const offerService = useContext(OfferServiceContext);

  const user = useUser();

  useEffect(() => {
    if (user.summary) {
      let userBalance = user.summary.points;
      if (userBalance > 0) {
        setPointsInPercentage((userBalance / pointsToEarnVoucher) * 100);
      }
      setRemainingPoints(pointsToEarnVoucher - userBalance);

      setRemainingPointsText(
        MY_REWARDS_PAGE_REMAINING_POINTS_TEXT.replace(
          '%voucherValue%',
          voucherValue + '' || ''
        )
      );
      getVoucherValue();
    }
  }, [user]);

  useEffect(() => {
    if (remainingPointsText) {
      setRemainingPointsText(
        remainingPointsText.replace(
          '%remainingPoints%',
          remainingPoints + '' || ''
        )
      );
    }
  }, [remainingPointsText]);

  const getVoucherValue = () => {
    offerService
      ?.getOffersResponses({
        page: 1,
        results_per_page: 100,
        sort_by: 'usage_end',
        sort_dir: 'desc',
      })
      .then((res: OfferResponsesResult) => {
        if (res.success) {
          if (res.offers.length > 0) {
            let offerResponses = res.offers.filter(
              (offer: any) => offer.status === 'pending'
            );
            console.log('offerResponses: ', res.offers, offerResponses);
            setVoucherValue(
              defaultVoucherValue * (offerResponses && offerResponses.length)
            );
          } else {
            setVoucherValue(0);
          }
        }
      });
  };

  return (
    <MyRewardsDetailStyled className='my-rewards-detail'>
      <MyRewardsDetailContentStyled>
        <TitleStyled>{MY_REWARDS_PAGE_TITLE}</TitleStyled>
        <DescriptionStyled className='bold username'>
          {MY_REWARDS_PAGE_WELCOME_TEXT} {user.profile?.first_name}!
        </DescriptionStyled>
        <DescriptionStyled>
          {MY_REWARDS_PAGE_MEMBERSHIP_TEXT} {user.profile?.card_id}
        </DescriptionStyled>

        <CardsContainerStyled>
          <CardContentStyled>
            <CardTitleStyled>
              {MY_REWARDS_PAGE_TOTAL_POINTS_TEXT}
            </CardTitleStyled>
            <CardBalanceStyled>{user.summary?.points}</CardBalanceStyled>
            <ProgressBarWrapperStyled
              leftValue={0}
              rightValue={pointsToEarnVoucher}
            >
              <LevelLineStyled
                percentage={pointsInPercentage + '%'}
              ></LevelLineStyled>
            </ProgressBarWrapperStyled>
            <CardTitleStyled>
              {MY_REWARDS_PAGE_MY_POINTS_BALANCE_TEXT}
            </CardTitleStyled>
            <DescriptionStyled
              dangerouslySetInnerHTML={{ __html: remainingPointsText }}
            ></DescriptionStyled>
          </CardContentStyled>
          <CardContentStyled>
            <CircularOfferStyled>
              <CircularOfferTextStyled>${voucherValue}</CircularOfferTextStyled>
              <CircularOfferLabelStyled>
                {MY_REWARDS_PAGE_AVAILABLE_TEXT}
              </CircularOfferLabelStyled>
            </CircularOfferStyled>
            <CardTitleStyled>
              {MY_REWARDS_PAGE_MY_VOUCHERS_TEXT}
            </CardTitleStyled>
            <DescriptionStyled>
              {MY_REWARDS_PAGE_DONT_FORGET_TEXT}
            </DescriptionStyled>
          </CardContentStyled>
        </CardsContainerStyled>

        <Submenu>
          <li>
            <a href={PROFILE_URL}>Edit my profile</a>
          </li>
          <li>
            <a href={CHANGE_PASSWORD_LINK}>Change my password</a>
          </li>
          <li>
            <a href={SPECIAL_INTERESTS_URL}>Edit my special interests </a>
          </li>
        </Submenu>
      </MyRewardsDetailContentStyled>
      <OverlayStyled></OverlayStyled>
    </MyRewardsDetailStyled>
  );
};
