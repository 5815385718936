import styled from "styled-components";

export const IESupportStyled = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  color: black;
  text-align: center;

  h2 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    
  }

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
  }

  .browser-icon-content {
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      display: block;
      align-items: center;
      justify-content: center;
      margin: 24px 16px;
      a {
        display: block;
        color: black;
        text-decoration: none;
        border-bottom: 1px solid black;
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
`;