import {FunctionalComponent, h} from "preact";
import {useEffect, useState} from "preact/hooks";
import {ProgressBar} from "@greencross/components/progress-bar/progress-bar.component";
import zxcvbn from "zxcvbn";
import {SECURITY_LEVEL} from "@greencross/components/password-strength/password-strength.constants";
import {PasswordStrengthStyled} from "@greencross/components/password-strength/password-strength.style";

interface PasswordStrengthProps {
    password?: string;
}

export const PasswordStrength: FunctionalComponent<PasswordStrengthProps> = ({ password = "" }) => {

    const [securityLevel, setSecurityLevel] = useState<number>(0);
    const [percentage, setPercentage] = useState<number>(0);

    useEffect(() => {
        const security = zxcvbn(password);
        setSecurityLevel(security.score);
        setPercentage(password ? (security.score + 1) * 20 : 0);
    }, [password]);

    return (
        <PasswordStrengthStyled>
            <span>Password strength: { password ? SECURITY_LEVEL[securityLevel].title : "" }</span>
            <ProgressBar color={SECURITY_LEVEL[securityLevel].color} percentage={percentage} />
        </PasswordStrengthStyled>
    );
}