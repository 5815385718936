import { FunctionalComponent, h } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import {
  Control,
  FieldError,
  FieldErrors,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { Text2 } from '@authentic/loyalty-pwa/design-system/style-guide/typography';

import { ReactNode } from 'react';
import { ErrorStyled, TextAreaFieldStyled, TextAreaStyled } from './text-area-field.style';

export type TextAreaFieldProps = {
  id?: string;
  label: string;
  error?: FieldError | FieldErrors<FieldValues> | undefined;
  placeholder?: string;
  value?: string;
  rows?: number;
  cols?: number;
  required?: string;
  mask?: string;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  onChange?: (ev: any) => void;
  control?: Control<any>;
  description?: ReactNode;
  disabled?: boolean;
  prefix?: ReactNode;
  suffix?: ReactNode;
} & UseControllerProps;

export const TextAreaField: FunctionalComponent<TextAreaFieldProps> = ({
  onFocus,
  onBlur,
  onChange,
  disabled,
  prefix,
  suffix,
  ...props
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const {
    field: { value, ref, ...inputProps },
  } = useController(props);

  const handleOnChange = useCallback(
    ($event: any) => {
      console.log('handleOnChange: ', $event);
      if (onChange && typeof onChange === 'function') {
        onChange($event);
      }
      inputProps.onChange($event);
    },
    [onChange]
  );

  useEffect(() => {
    if (value) {
      setIsActive(true);
    }
  }, [value]);

  return (
    <TextAreaFieldStyled className={`${props.name}-field`} error={props.error}>
      <Text2
        htmlFor={props.name}
        className={`${isActive || props.value ? 'active' : ''}`}
      >
        {props.label}
        {
          //@ts-ignore
          props.rules?.required.value ? '*' : ''
        }
      </Text2>
      <TextAreaStyled
        ref={ref}
        rows={props.rows}
        cols={props.cols}
        {...inputProps}
        placeholder={props.placeholder}
        onChange={handleOnChange}
        id={props.name}
        value={value}
        disabled={disabled}
      />
      {props.error && <ErrorStyled>{props.error.message}</ErrorStyled>}
      {props.description}
    </TextAreaFieldStyled>
  );
};
