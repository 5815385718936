import { Fragment, h } from "preact";

export const ABOUT_US_URL = "/about-us";
export const ABOUT_US_FAQ = "About us / FAQ";
export const ABOUT_US_SUBHEAD = "Shop + Swipe = Reward";
export const ABOUT_US_BODY = (
	<Fragment>
		<p>Living Rewards is accepted at over 350 Unichem and Life Pharmacies nationwide. It’s easy and convenient to earn points instantly and redeem vouchers on your everyday health and beauty needs, prescriptions and in-store health checks and see how quickly they build.</p>
		<p>Be Rewarded for doing Life well.</p>
		<p>Ensure your correct mail, address and phone number are saved on your account to hear about these member benefits.</p>
	</Fragment>
);

export const FAQS = "FAQs";