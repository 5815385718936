import { ContentPageServiceInterface } from "@authentic/loyalty-pwa/interfaces/content-page.interface";
import { CheetahShared } from "@authentic/loyalty-pwa/services/cheetah/cheetah.shared";

export class CheetahContentPageService extends CheetahShared implements ContentPageServiceInterface {
  async getContentPageBySlug(slug: string): Promise<{ success: boolean }> {
    return this.fetch({
      method: 'GET',
      url: `/api/content_pages/${slug}`
    }).then((response) => {
      if (!response.ok) {
        return response.json().then((res) => ({ error: { ...res }, success: false }));
      }
      return response.json().then((res) => ({
        success: true,
        data: {
          url: res.data?.url
        }
      }));
    });
  }

}