import { h } from 'preact';

export const CircleCheckIcon = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Account-Deleted-Confirmation---Phase-2"
        transform="translate(-407.000000, -931.000000)"
        fill="#364FC8"
        fill-rule="nonzero"
      >
        <g id="notification" transform="translate(376.000000, 915.000000)">
          <g id="check-icon" transform="translate(29.000000, 14.000000)">
            <path
              d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
