import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const SignupPageStyled = styled.div``;

export const SignupPageContentStyled = styled.div`
  position: relative;
  min-height: 2160px;
  padding: 48px 0;
  .background-image {
    background-color: #F7AD29;
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    padding: 63px 0;
  }
`;
