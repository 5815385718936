import {BaseLayout} from "@greencross/pages/shared/base-layout/base-layout";
import { h } from 'preact';
import {Button, ButtonVariant} from "@greencross/overrides/design-system/components/base/button/button.component";
import {ButtonContainer, Description, Title} from "@greencross/overrides/design-system/components/base/cta-info/variant2/cta-info.variant2.style";
import {PageNotFoundStyled} from "@greencross/overrides/Pages/page-not-found/PageNotFound.style";
import {Card} from "@greencross/components/card/card.style";
import {useCallback} from "preact/hooks";
import {
	ABOUT_US_BTN_TEXT,
	CONTACT_US_BTN_TEXT, CONTACT_US_URL,
	DESCRIPTION_TEXT,
	GO_BACK_BTN_TEXT
} from "@greencross/overrides/Pages/page-not-found/PageNotFound.strings";
import { MouseEvent} from "react";
import {getImageSrc} from "@authentic/loyalty-pwa/util";
import {BackgroundImage} from "@greencross/components/background-image/background-image.component";
import { ABOUT_US_URL } from "@greencross/pages/unauthenticated/about-us/about-us.strings";
import { Link } from "preact-router";

export const PageNotFound = () => {

	const redirectTo = useCallback(($event: MouseEvent<HTMLButtonElement>) => {
		const target = $event.currentTarget as HTMLElement;
		window.location = target?.dataset?.to as (string | Location) & Location;
	}, []);

	return (
		<PageNotFoundStyled>
			<BaseLayout>
				<div className="content-container">
					<BackgroundImage
						desktop={`url(${getImageSrc('/assets/images/backgrounds/GXH_Background_Desktop_8_GuyPointing_afccd4.jpg')})`}
						mobile={`url(${getImageSrc('/assets/images/backgrounds/GXH_Background_Mobile_8_GuyPointing_afccd4.jpg')})`}
					/>
					<Card>
						<Title>404</Title>
						<Description>{DESCRIPTION_TEXT}</Description>
						<ButtonContainer>
							<Button variant={ButtonVariant.PRIMARY} data-to="/" onClick={redirectTo}>
								{GO_BACK_BTN_TEXT}
							</Button>
						</ButtonContainer>
						<ButtonContainer className="links-container">
							<Link href={CONTACT_US_URL}>
								{CONTACT_US_BTN_TEXT}
							</Link>
							<Link href={ABOUT_US_URL}>
								{ABOUT_US_BTN_TEXT}
							</Link>
						</ButtonContainer>
					</Card>
				</div>
			</BaseLayout>
		</PageNotFoundStyled>
	)
}
