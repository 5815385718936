import {ProgressBarContainerStyled, ProgressBarStyled} from "@greencross/components/progress-bar/progress-bar.style";
import {FunctionalComponent, h} from "preact";

interface ProgressBarProps {
    color: string;
    height?: string;
    percentage?: number;
}

export const ProgressBar: FunctionalComponent<ProgressBarProps> = ({ color, height, percentage = 100  }) => {
    return (
        <ProgressBarContainerStyled color={color} height={height}>
            <ProgressBarStyled color={color} height={height} percentage={percentage} />
        </ProgressBarContainerStyled>
    );
}