export enum Breakpoints {
  XXL = '1920px',
  ML = '1170px',
  XL = '1024px',
  L = '840px',
  M = '768px',
  S = '640px',
  XS = '480px',
  XS1 = '375px',
  XXS = '320px'
};