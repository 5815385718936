import styled from "styled-components";
import {ProgressBarContainerStyled} from "@greencross/components/progress-bar/progress-bar.style";

export const PasswordStrengthStyled = styled.div`
  margin: 1em 0;
  display: flex;
  justify-content: flex-start;
  span {
    margin: 0;
    font-size: 1.3em;
  }
  ${ProgressBarContainerStyled} {
    min-width: 120px;
    margin: 0 1em;
  }
`;