export const EMAIL_LABEL = 'Email';
export const PASSWORD_LABEL = 'Password';

export const SIGN_IN_BUTTON_NAME = 'Sign in & continue';
export const SIGN_IN_CANCEL_NAME = 'Cancel';

export const EMAIL_REQUIRED_ERROR = 'Please enter your Email';
export const EMAIL_FORMAT_ERROR = 'Entered value does not match email format';
export const PASSWORD_REQUIRED_ERROR = 'Please enter your password';

export const HEADING_TEXT = 'Sign in';

export const FORGOT_PASSWORD_LABEL = 'Forgot Password';
export const SIGN_UP_LABEL = 'Not a Living Rewards Member?';
export const SIGN_UP_BUTTON_LABEL = 'Join Here.';
export const FORGOT_PASSWORD_URL = '/forgot-password'
