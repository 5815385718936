import { ComponentChildren, FunctionalComponent, h } from "preact";
import CloseIcon from "@authentic/loyalty-pwa/icons/Close";
import { ModalContentStyled, ModalOverlayStyled, ModalStyled } from "@authentic/loyalty-pwa/components/Modal/modal.style";

interface ModalProps {
  children?: ComponentChildren,
  showCloseButton?: boolean,
  isModalActive: boolean,
  callbackOnCloseModal: () => void;
}

export const Modal = ({ isModalActive, children, callbackOnCloseModal, showCloseButton = true }: ModalProps) => {
  const closeModal = (e: any) => {
    callbackOnCloseModal();
  };
  return (
    <ModalStyled className={(isModalActive ? 'revealing' : 'revealing out')}>
      <ModalContentStyled className="modal-content">
        {showCloseButton && (
          <span class="modal-close-button" onClick={closeModal}><CloseIcon></CloseIcon></span>
        )}
        {children}
      </ModalContentStyled>
      <ModalOverlayStyled className="modal-overlay"></ModalOverlayStyled>
    </ModalStyled>
  )
}
