export type FetchRequest = Pick<RequestInit, Exclude<keyof RequestInit, 'body' | 'headers'>> & {
    body?: Record<string, any> | FormData
    headers?: Record<string, string>
    queryParams?: Record<string, any>
    url: string
}

export function fetchHelper({ url, method = 'POST', body, headers = {}, queryParams}: FetchRequest): Promise<any> {
    const request: RequestInit = {
        method,
        body: null
    }

    const addedHeaders: Record<string, string> = {
        accept: 'application/json'
    }

    if (body && typeof body === 'object') {
        if (typeof body === 'object' && !(body instanceof FormData)) {
            const formData: string[] = []
            Object.entries(flattenObject(body)).forEach(([key, value]) => {
                formData.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            })
            headers['Content-Type'] = 'application/x-www-form-urlencoded';
            request.body = formData.join('&')
        } else {
            request.body = body
        }
    }

    request.headers = {
        ...addedHeaders,
        ...headers
    }

    if (queryParams) {
        const queryString = Object.keys(queryParams).map(param => `${param}=${queryParams[param]}`).join('&')
        if (url.endsWith('?'))
            url = `${url}${queryString}`
        else if (url.includes('?'))
            url = `${url}&${queryString}`
        else
            url = `${url}?${queryString}`
    }

	return fetch(`${url}`, request)
}

export function flattenObject(value: Record<string, any>, parentKey: string = ''): Record<string, any> {
    const result: Record<string, any> = {}
    Object.entries(value).forEach(([key, child]) => {
        if (parentKey)
            key = `${parentKey}[${key}]`
        if (typeof child === 'object') {
            Object.assign(result, flattenObject(child, key))
        } else {
            result[key] = child
        }
    })
    return result
}
