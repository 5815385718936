export const enum SpecialInterestsCategory {
  BEAUTY = 'beauty',
  HOBBIES_LEISURE = 'hobbies_leisure',
  HEALTH_FITNESS = 'health_fitness',
  HEALTH_CONDITIONS = 'health_conditions',
  FAMILY_LIFE_STAGE = 'family_life_stage',
  FOOD_INGREDIENTS = 'food_ingredients',
}

export const SPECIAL_INTERESTS_CATEGORY_NAMES: {
  code: SpecialInterestsCategory;
  name: string;
}[] = [
  {
    code: SpecialInterestsCategory.BEAUTY,
    name: 'BEAUTY',
  },
  {
    code: SpecialInterestsCategory.HOBBIES_LEISURE,
    name: 'HOBBIES & LEISURE',
  },
  {
    code: SpecialInterestsCategory.HEALTH_FITNESS,
    name: 'HEALTH & FITNESS',
  },
  {
    code: SpecialInterestsCategory.HEALTH_CONDITIONS,
    name: 'HEALTH CONDITIONS',
  },
  {
    code: SpecialInterestsCategory.FAMILY_LIFE_STAGE,
    name: 'FAMILY & LIFE STAGE',
  },
  {
    code: SpecialInterestsCategory.FOOD_INGREDIENTS,
    name: 'FOOD & INGREDIENTS',
  },
];

export const SPECIAL_INTERESTS_DATA = [
  {
    category: SpecialInterestsCategory.BEAUTY,
    items: [
      {
        name: 'Beauty Tips & Advice',
        id: 'beauty_tips_advice',
      },
      {
        name: 'Beauty Devices',
        id: 'beauty_devices',
      },
      {
        name: 'Beauty "How to" makeup tips',
        id: 'beauty_make_up_tips',
      },
      {
        name: 'Beauty spa and/or treatments',
        id: 'beauty_spa_treatments',
      },
      {
        name: 'Beauty Supplements',
        id: 'beauty_supplements',
      },
      {
        name: 'Brow Services',
        id: 'brow_services',
      },
      {
        name: 'Fragrance',
        id: 'fragrance',
      },
      {
        name: 'Gift With Purchase',
        id: 'gift_with_purchase',
      },
      {
        name: 'Hair Care',
        id: 'hair_care',
      },
      {
        name: 'Luxury items',
        id: 'luxury_items',
      },
      {
        name: 'Nail Care',
        id: 'nail_care',
      },
      {
        name: 'Skincare Anti-Aging',
        id: 'skincare_anti_aging',
      },
      {
        name: 'Skincare Brightening',
        id: 'skincare_brightening',
      },
      {
        name: 'Skincare Hormonal Changes',
        id: 'skincare_hormonal_changes',
      },
      {
        name: 'Skincare Sensitive Skin',
        id: 'skincare_sensitive_skin',
      },
      {
        name: 'Skincare Vegan',
        id: 'skincare_vegan',
      },
    ],
  },
  {
    category: SpecialInterestsCategory.HOBBIES_LEISURE,
    items: [
      {
        name: 'Boats and/or Sailing',
        id: 'boats_sailing',
      },
      {
        name: 'Cooking',
        id: 'cooking',
      },
      {
        name: 'Eating out',
        id: 'eating_out',
      },
      {
        name: 'Fashion',
        id: 'fashion',
      },
      {
        name: 'Going to the movies',
        id: 'going_to_the_movies',
      },
      {
        name: 'Going to the theatre',
        id: 'going_to_the_theatre',
      },
      {
        name: 'Golf',
        id: 'golf',
      },
      {
        name: 'Magazines',
        id: 'magazines',
      },
      {
        name: 'Music',
        id: 'music',
      },
      {
        name: 'Pets',
        id: 'pets',
      },
      {
        name: 'Reading',
        id: 'reading',
      },
      {
        name: 'Travel & holidays',
        id: 'travel_holidays',
      },
      {
        name: 'Watching Sport',
        id: 'watching_sport',
      },
      {
        name: 'Yoga and/or Pilates',
        id: 'yoga_pilates',
      },
    ],
  },
  {
    category: SpecialInterestsCategory.HEALTH_FITNESS,
    items: [
      {
        name: 'Food & Nutrition',
        id: 'food_nutrition',
      },
      {
        name: "Getting a better night's sleep",
        id: 'getting_a_better_night_sleep',
      },
      {
        name: 'Going to the gym and/or exercise',
        id: 'going_to_the_gym_exercise',
      },
      {
        name: 'Health & Fitness Technology',
        id: 'health_fitness_technology',
      },
      {
        name: 'Health & Wellness Tips and Advice',
        id: 'health_wellness_tips_advice',
      },
      {
        name: 'Healthy Aging',
        id: 'healthy_aging',
      },
      {
        name: 'Healthy Body',
        id: 'healthy_body',
      },
      {
        name: 'Healthy cooking & eating',
        id: 'healthy_cooking_eating',
      },
      {
        name: 'Healthy Gut',
        id: 'healthy_gut',
      },
      {
        name: 'Healthy Lifestyle',
        id: 'healthy_lifestyle',
      },
      {
        name: 'Healthy Mind',
        id: 'healthy_mind',
      },
      {
        name: 'Healthy Teeth & Gums',
        id: 'healthy_teeth_gums',
      },
      {
        name: 'Holistic wellbeing',
        id: 'holistic_wellbeing',
      },
      {
        name: 'Hormone Health',
        id: 'hormone_health',
      },
      {
        name: 'In home health assistance',
        id: 'in_home_health_assistance',
      },
      {
        name: 'Joints and staying active',
        id: 'joints_staying_active',
      },
      {
        name: 'Latest Health & Wellness Trends',
        id: 'latest_health_wellness_trends',
      },
      {
        name: 'Mens Health',
        id: 'mens_health',
      },
      {
        name: 'Mindfulness & Holistic Wellbeing',
        id: 'mindfulness_holistic_wellbeing',
      },
      {
        name: 'Playing sport',
        id: 'playing_sport',
      },
      {
        name: 'Vaccinations',
        id: 'vaccinations',
      },
      {
        name: 'Vitamins & Supplements',
        id: 'vitamins_supplements',
      },
      {
        name: 'Womens Health',
        id: 'womens_health',
      },
    ]
  },
  {
    category: SpecialInterestsCategory.HEALTH_CONDITIONS,
    items: [
      {
        name: 'Allergies',
        id: 'allergies',
      },
      {
        name: 'Hair loss & regeneration',
        id: 'hair_loss_regeneration',
      },
      {
        name: 'Heart health & blood pressure',
        id: 'heart_health_blood_pressure',
      },
      {
        name: 'Managing Stress',
        id: 'managing_stress',
      },
      {
        name: 'Perimenopause and/or Menopause',
        id: 'perimenopause_menopause',
      },
      {
        name: 'Quit smoking',
        id: 'quit_smoking',
      },
      {
        name: 'Skincare eczema',
        id: 'skincare_eczema',
      },
      {
        name: 'Skincare acne',
        id: 'skincare_acne',
      },
      {
        name: 'Skincare redness',
        id: 'skincare_redness',
      },
      {
        name: 'Weight Management',
        id: 'weight_management',
      },
    ]
  },
  {
    category: SpecialInterestsCategory.FAMILY_LIFE_STAGE,
    items: [
      {
        name: 'Baby & Toddlers',
        id: 'baby_toddlers',
      },
      {
        name: 'Healthy Pregnancy',
        id: 'healthy_pregnancy',
      },
      {
        name: 'Insurance & banking',
        id: 'insurance_banking',
      },
      {
        name: 'Parenting & Kids Health',
        id: 'parenting_kids_health',
      },
      {
        name: 'Planning for a baby',
        id: 'planning_for_a_baby',
      },
      {
        name: 'Retirement planning and/or support',
        id: 'retirement_planning_support',
      },
      {
        name: 'Teenagers',
        id: 'teenagers',
      },
    ]
  },
  {
    category: SpecialInterestsCategory.FOOD_INGREDIENTS,
    items: [
      {
        name: 'Keto',
        id: 'keto',
      },
      {
        name: 'Plant-based',
        id: 'plant_based',
      },
      {
        name: 'Paleo products',
        id: 'paleo_products',
      },
      {
        name: 'Vegan products',
        id: 'vegan_products',
      },
      {
        name: 'Vegetarian products',
        id: 'vegetarian_products',
      },
      {
        name: 'NZ Made',
        id: 'nz_made',
      },
      {
        name: 'Natural products',
        id: 'natural_products',
      },
      {
        name: 'Vegan and Vegetarian products',
        id: 'vegan_and_vegetarian_products',
      },
      {
        name: 'Sustainable and Eco Friendly products',
        id: 'sustainable_eco_friendly_products',
      },
    ]
  }
];
