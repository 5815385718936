import styled from 'styled-components';
import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';

export const CallToActionModel1Styled = styled.div`
  display: flex;
  flex-direction: column;
  border: 4px solid #d9d9d9;
  border-radius: 24px;
  overflow: hidden;
  box-sizing: border-box;
`;
export const ImageStyled = styled.div`
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.black};
`;
export const TitleStyled = styled.div`
  font-size: 20px;
  font-family: 'OpenSans-Bold';
  margin-bottom: 4px;
`;
export const LinkStyled = styled.div`
  a {
    font-size: 1.5em;
  }
`;
export const ContentStyled = styled.div`
  background: #fff;
  padding: 24px 20px;
`;
