import {
  EMAIL_FORMAT_ERROR,
  EMAIL_LABEL,
  EMAIL_REQUIRED_ERROR,
  FORGOT_PASSWORD_LABEL,
  HEADING_TEXT,
  PASSWORD_LABEL,
  PASSWORD_REQUIRED_ERROR,
  SIGN_IN_BUTTON_NAME,
  SIGN_IN_CANCEL_NAME,
  SIGN_UP_BUTTON_LABEL,
  SIGN_UP_LABEL,
} from '@authentic/loyalty-pwa/design-system/components/cheetah/unauthenticated/sign-in/sign-in.strings';

export type DefaultChangeSignInFormProps = {
  style: {
    heading: {
      textAlign: string;
    };
  };
  config: {
    heading: {
      text: string;
    };
    inputs: {
      email: {
        label: string;
        placeholder: string;
        errors: {
          required: string;
          format: string;
        };
      };
      password: {
        label: string;
        placeholder: string;
        errors: {
          required: string;
        };
      };
    };
    submitButton: {
      text: string;
    };
    cancelButton: {
      text: string;
      hide: boolean;
    };
    forgotPassword: {
      label: string;
    };
    signup: {
      subtitle: {
        label: string;
      };
      button: {
        label: string;
      };
    };
  };
};

export const DefaultChangeSignInFormConfig: DefaultChangeSignInFormProps = {
  style: {
    heading: {
      textAlign: 'center',
    },
  },
  config: {
    heading: {
      text: HEADING_TEXT,
    },
    inputs: {
      email: {
        label: EMAIL_LABEL,
        placeholder: EMAIL_LABEL,
        errors: {
          required: EMAIL_REQUIRED_ERROR,
          format: EMAIL_FORMAT_ERROR,
        },
      },
      password: {
        label: PASSWORD_LABEL,
        placeholder: PASSWORD_LABEL,
        errors: {
          required: PASSWORD_REQUIRED_ERROR,
        },
      },
    },
    submitButton: {
      text: SIGN_IN_BUTTON_NAME,
    },
    cancelButton: {
      text: SIGN_IN_CANCEL_NAME,
      hide: false, //callback is required
    },
    forgotPassword: {
      label: FORGOT_PASSWORD_LABEL,
    },
    signup: {
      button: {
        label: SIGN_UP_BUTTON_LABEL,
      },
      subtitle: {
        label: SIGN_UP_LABEL,
      },
    },
  },
};
