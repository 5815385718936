import { FunctionalComponent, h } from 'preact';
import { NavSliderStyled } from '@authentic/loyalty-pwa/design-system/components/base/nav-slider/nav-slider.style';
import { Swiper } from 'swiper/react/swiper-react';
import { SwiperOptions as SwiperClass } from 'swiper/types/';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/autoplay/autoplay.min.css';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
SwiperCore.use([Pagination, Navigation, Autoplay]);

type NavSliderProps = SwiperClass & {
  slideTo?: number;
  onTap?: (swiper: any) => void;
};

export const NavSlider: FunctionalComponent<NavSliderProps> = (props) => {
  return (
    <NavSliderStyled className='nav-slider'>
      <Swiper
        {...props}
        onSlideChange={() => console.log('slide change')}
        onTap={(swiper: SwiperCore) => {
          if (props.onTap) {
            props.onTap(swiper);
          }
        }}
        onSwiper={(swiper) => {
          if (props.slideTo !== undefined && props.slideTo > -1) {
            swiper.slideTo(props.slideTo, 0);
          }
        }}
        grabCursor={true}
      >
        {props.children}
      </Swiper>
    </NavSliderStyled>
  );
};
