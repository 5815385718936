import { getImageSrc } from '@authentic/loyalty-pwa/util';
import { TERMS_AND_CONDITIONS_URL } from '@greencross/pages/unauthenticated/terms-and-conditions/terms-and-conditions.strings';
import { ABOUT_US_URL } from '@greencross/pages/unauthenticated/about-us/about-us.strings';
import { FunctionalComponent, h } from 'preact';
import {
  CONTACT_US,
  COPYRIGHT_TEXT,
  FAQS,
  PRIVACY_POLICY_TEXT,
  SHOP_ONLINE,
  TERMS_AND_CONDITIONS,
} from './footer.strings';
import {
  FooterLogoListStyled,
  FooterMenuStyled,
  FooterStyled,
  RightSideStyled,
  SocialLinksStyled,
  GXHLinksStyled
} from './footer.style';
import { Link } from 'preact-router';
import { PRIVACY_POLICY_URL } from '@greencross/pages/unauthenticated/privacy-policy/privacy-policy.strings';
import { CONTACT_US_URL } from '@greencross/pages/unauthenticated/contact-us/contact-us.strings';

export const Footer: FunctionalComponent = () => {
  return (
    <FooterStyled>
      <li>
        <FooterLogoListStyled>
          <li>
            <Link target='_blank' href='https://www.livingrewards.co.nz/'>
              <img
                src={getImageSrc('/assets/images/LR-Stacked.png')}
                alt='Living Rewards Logo'
              />
            </Link>
          </li>
          <li>
            <Link target='_blank' href='https://www.greencrosshealth.co.nz/'>
              <img
                src={getImageSrc('/assets/images/GreenCrossHealth_Logo.png')}
                alt='Green Cross Health Logo'
              />
            </Link>
          </li>
        </FooterLogoListStyled>
      </li>
      <li className='large'>
        <RightSideStyled>
          <li>
            <GXHLinksStyled>
              <li>
                <a href='https://www.unichem.co.nz/'>
                  <img
                    src={getImageSrc('/assets/images/Unichem.png')}
                    alt='Unichem Icon'
                  />
                </a>
              </li>
              <li>
                <a href='https://www.lifepharmacy.co.nz/'>
                  <img
                    src={getImageSrc('/assets/images/LifePharmacy.png')}
                    alt='LifePharmacy Icon'
                  />
                </a>
              </li>
            </GXHLinksStyled>
          </li>
          <li>
            <SocialLinksStyled>
              <li>
                <a href='https://www.facebook.com/UnichemNZ/'>
                  <img
                    src={getImageSrc('/assets/images/Facebook_Icon.png')}
                    alt='Facebook Icon'
                  />
                  Unichem
                </a>
              </li>
              <li>
                <a href='https://www.facebook.com/LifePharmacyNZ/'>
                  <img
                    src={getImageSrc('/assets/images/Facebook_Icon.png')}
                    alt='Facebook Icon'
                  />
                  Life Pharmacy
                </a>
              </li>
            </SocialLinksStyled>
          </li>
          <li>
            <FooterMenuStyled>
              <li>
                <Link
                  onClick={() =>
                    (window.location.href = `${TERMS_AND_CONDITIONS_URL}`)
                  }
                >
                  {TERMS_AND_CONDITIONS}
                </Link>
              </li>
              <li>|</li>
              <li>
                <Link
                  onClick={() => (window.location.href = `${CONTACT_US_URL}`)}
                >
                  {CONTACT_US}
                </Link>
              </li>
              <li>|</li>
              <li>
                <Link
                  onClick={() => (window.location.href = `${ABOUT_US_URL}`)}
                >
                  {FAQS}
                </Link>
              </li>
              <li>|</li>
              <li>
                <Link
                  onClick={() =>
                    (window.location.href = `${PRIVACY_POLICY_URL}`)
                  }
                >
                  {PRIVACY_POLICY_TEXT}
                </Link>
              </li>
            </FooterMenuStyled>
          </li>
        </RightSideStyled>
      </li>
    </FooterStyled>
  );
};
