import { FunctionalComponent, h } from 'preact';
import { UserServiceContext } from '@authentic/loyalty-pwa/providers';
import {
  ForgotPasswordFormContentStyled,
  ForgotPasswordFormFieldsStyled,
  ForgotPasswordFormStyled,
  OverlayStyled,
} from './forgot-password-form.style';
import {
  TextField,
} from '@authentic/loyalty-pwa/design-system/components/base/text-field/text-field.component';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  DEAFULT_ERROR_MESSAGE,
  EMAIL_FORMAT_ERROR,
  FORGOT_PASSWORD_BUTTON,
  FORGOT_PASSWORD_HEADER,
  FORGOT_PASSWORD_SUCCESSFUL_MESSAGE,
} from './forgot-password-form.strings';
import { useContext, useState } from 'preact/hooks';
import {
  Button,
  ButtonType,
  ButtonVariant
} from "@greencross/overrides/design-system/components/base/button/button.component";

export const TITLE_INPUT_ID = 'title';
export const FIRST_NAME_INPUT_ID = 'firstname';
export const LAST_NAME_INPUT_ID = 'lastname';
export const DATE_OF_BIRTH_INPUT_ID = 'birthdate';
export const GENDER_INPUT_ID = 'gender';
export const MAILING_ADDRESS_INPUT_ID = 'mailingaddress';
export const EMAIL_INPUT_ID = 'email';
export const CONFIRM_EMAIL_INPUT_ID = 'confirmemail';
export const MOBILENUMBER_INPUT_ID = 'mobilenumber';
export const PASSWORD_INPUT_ID = 'password';
export const CONFIRMPASSWORD_INPUT_ID = 'confirmpassword';
export const TERMS_CONDITIONS_CHECKBOX_ID = 'terms_conditions';
export const PRIVACY_POLICY_CHECKBOX_ID = 'privacy_policy';

export type FormValues = {
  [EMAIL_INPUT_ID]: string;
};

interface ForgotPasswordFormProps {}
export const ForgotPasswordForm: FunctionalComponent<
  ForgotPasswordFormProps
> = (props) => {
  const userService = useContext(UserServiceContext);
  const [completed, setCompleted] = useState<false | string>(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setError,
  } = useForm();

  const onFocusInput = (name: string, e: any) => {
    console.log('onInputFocus: ', e.target.value, name);
    document
      .getElementsByClassName(`${name}-field`)[0]
      .getElementsByTagName('label')[0]
      .classList.add('active');
  };

  const onSubmit: SubmitHandler<any> = (data: FormValues) => {
    console.log('data: ', data);
    userService
      ?.passwordReset({
        email: data[EMAIL_INPUT_ID],
      })
      .then((res: any) => {
        if (res.success) {
          //TODO: display the correct message
          formReset();
          setCompleted(FORGOT_PASSWORD_SUCCESSFUL_MESSAGE);
        } else {
          setError(EMAIL_INPUT_ID, { message: res.message });
        }
      });
  };

  const onError = (error: any) => {
    console.log('error: ', error);
  };
  
  const formReset = () => {
    reset({
      [EMAIL_INPUT_ID]: '',
    });
  };

  return (
    <ForgotPasswordFormStyled className='forgotpassword-form'>
      <ForgotPasswordFormContentStyled>
        <h1>{FORGOT_PASSWORD_HEADER}</h1>

        <ForgotPasswordFormFieldsStyled>
          { completed && (
            <p>{completed}</p>
          )}
          { !completed && (
            //@ts-ignore
            <form onSubmit={handleSubmit(onSubmit, onError)} autoComplete='off'>
              <input name='email' class='visually-hidden' type='text' />
              <TextField
                name={EMAIL_INPUT_ID}
                defaultValue={''}
                placeholder={''}
                label={'Email'}
                control={control}
                error={errors[EMAIL_INPUT_ID]}
                onFocus={onFocusInput.bind(this, EMAIL_INPUT_ID)}
                rules={{
                  required: {
                    value: true,
                    message: DEAFULT_ERROR_MESSAGE,
                  },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: EMAIL_FORMAT_ERROR,
                  },
                }}
              />

              <Button variant={ButtonVariant.PRIMARY} type={ButtonType.SUBMIT}>
                {FORGOT_PASSWORD_BUTTON}
              </Button>
            </form>
          )}
        </ForgotPasswordFormFieldsStyled>
      </ForgotPasswordFormContentStyled>
      <OverlayStyled></OverlayStyled>
    </ForgotPasswordFormStyled>
  );
};
