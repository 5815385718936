import { createElement } from "preact"
import { route } from "@authentic/loyalty-pwa/util"
import { Props } from "react-input-mask"
import { useUser } from "@authentic/loyalty-pwa/providers"

type AnyComponent<Props> =
  | preact.FunctionalComponent<Props>
  | preact.ComponentConstructor<Props, any>;

export type AuthenticatedRouteProps = Partial<Props> & {
    component: AnyComponent<Props>,
    fallback?: string,
    invert?: boolean,
    path: string,
}
export const AuthenticatedRoute = ({component, fallback, invert = false, ...props}: AuthenticatedRouteProps): preact.VNode | null => {
    const user = useUser();

    if ((!invert && user.authenticated) || (invert && !user.authenticated))
        return createElement(component, props as unknown as Props)

    setTimeout(() => {
        if (fallback)
            route(fallback)
        else
            route('/')
    }, 1)

    return null
} 