import { Fragment, FunctionalComponent, h } from 'preact';
import { useUser } from '@authentic/loyalty-pwa/providers';
import {
  HeaderContentStyled,
  HeaderStyled,
  LoginButtonStyled,
  LogosStyled,
  MenuButtonContainerStyled,
  MenuButtonStyled,
  UserContainerStyled,
} from './header.style';
import { Link, useRouter } from 'preact-router';
import { JOIN, SIGN_IN, SIGN_OUT, WELCOME } from './header.strings';
import { getImageSrc } from '@authentic/loyalty-pwa/util';
import {
  HeaderMenu,
  HeaderMenuMobile,
} from '../header-menu/header-menu.component';
import { useEffect, useRef, useState } from 'preact/hooks';
import { SIGN_IN_URL } from '@authentic/loyalty-pwa/Pages/unauthenticated/sign-in/sign-in.strings';
import { HOME_URL } from '@greencross/pages/unauthenticated/home/home.strings';
import { SIGNUP_URL } from '@greencross/pages/unauthenticated/sign-up/sign-up.strings';

export const Header: FunctionalComponent = () => {
  const user = useUser();
  const menuRef = useRef(null);
  const [{ url }] = useRouter();
  const [menuActive, setMenuActive] = useState<boolean>(false);

  const mobileMenuClick = () => {
    if (menuRef.current)
      (menuRef.current as HTMLElement).classList.toggle('active');
    setMenuActive(!menuActive);
  };

  useEffect(() => {
    if (menuRef.current)
      (menuRef.current as HTMLElement).classList.remove('active');
    setMenuActive(false);
  }, [url]);

  return (
    <div>
      <HeaderContentStyled>
        <HeaderStyled>
          <LogosStyled>
            <Link href={HOME_URL}>
              <img
                src={getImageSrc('/assets/images/LR-Icons.png')}
                alt='Living Rewards Logo'
              />
              <img
              src={getImageSrc('/assets/images/LR.png')}
              alt='Living Rewards Card'
              />
            </Link>
          </LogosStyled>
          <LoginButtonStyled>
            {(!user || !user.authenticated) && (
              <UserContainerStyled>
                <Link href={SIGN_IN_URL}>{SIGN_IN}</Link>
                <div className='join-button'>
                  <Link href={SIGNUP_URL}>{JOIN}</Link>
                </div>
              </UserContainerStyled>
            )}
            {user && user.authenticated && (
              <UserContainerStyled>
                {WELCOME} {user.profile?.first_name}
                <div>
                  &nbsp;|&nbsp;
                  <Link href='/sign-out'>{SIGN_OUT}</Link>
                </div>
              </UserContainerStyled>
            )}
            <MenuButtonContainerStyled onClick={mobileMenuClick}>
              <MenuButtonStyled ref={menuRef}></MenuButtonStyled>
            </MenuButtonContainerStyled>
          </LoginButtonStyled>
        </HeaderStyled>
      </HeaderContentStyled>
      <HeaderMenu />
      <HeaderMenuMobile active={menuActive} />
    </div>
  );
};
