import { Fragment, FunctionalComponent, h } from 'preact';
import { Header } from '@greencross/components/header/header.component';
import { BaseLayoutStyled } from './base-layout.style';
import { Helmet } from "react-helmet";
import { LIVING_REWARDS } from './base-layout.strings';
import { Footer } from '@greencross/components/footer/footer.component';
import { Alert } from '@greencross/components/alert/alert.component';

interface BaseLayoutProps {}
export const BaseLayout: FunctionalComponent<BaseLayoutProps> = (props) => {
  return (
    <BaseLayoutStyled className='base-layout'>
      <Helmet>
        <title>{LIVING_REWARDS}</title>
      </Helmet>
      <Header />
      <Alert />
      {props.children}
      <Footer />
    </BaseLayoutStyled>
  );
};
