import { route as preactRoute } from 'preact-router';

export function route(url: string, replace?: boolean): boolean {
	if (process.env.CONTEXT_PATH)
		url = `${process.env.CONTEXT_PATH}${url}`
	return preactRoute(url, replace)
}

export function link(url: string): string {
	if (process.env.CONTEXT_PATH)
		url = `${process.env.CONTEXT_PATH}${url}`
	return url
}