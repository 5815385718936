export const colors = {
	black: '#383838',
	lightGray: '#BDBDBD',
	gray: '#858585',
	white: '#FFFFFF',
	red: '#e12424',
	gray1: '#9d9d9d',
};

export const backgroundColors = {
	black: '#272727',
	gray: '#C4C4C4',
	lightGray: '#F6F6F6',
	white: '#FFFFFF',
	lightGreen: '#DDE6CB',
	red : '#DD4647',
};

export const breakpoints = {
	xss: '360px',
  xs: '480px',
  sm: '600px',
  ms: '709px',
  md: '960px',
  lg: '1127px'
};

export const CALENDAR_MONTHS: Record<string, string> = {
	January: 'Jan',
	February: 'Feb',
	March: 'Mar',
	April: 'Apr',
	May: 'May',
	June: 'Jun',
	July: 'Jul',
	August: 'Aug',
	September: 'Sep',
	October: 'Oct',
	November: 'Nov',
	December: 'Dec',
};

export const CALENDAR_DAYS: Record<string, number> = {
	January: 31,
	February: 29,
	March: 31,
	April: 30,
	May: 31,
	June: 30,
	July: 31,
	August: 31,
	September: 30,
	October: 31,
	November: 30,
	December: 31,
};
