export const FIELD_PASSWORD_ID = 'password';
export const FIELD_PASSWORD_LABEL = 'New Password';
export const FIELD_CONFIRMPASSWORD_ID = 'password_confirmation';
export const FIELD_CONFIRMPASSWORD_LABEL = 'Confirm Password';
export const SUCCESSFUL_MESSAGE_FORGOT_PASSWORD = 'Your password has been successfully updated';

export const VALIDATION_MESSAGES = {
    REQUIRED: 'This field is required',
    SAME_OLD_PASSWORD: 'Your new password should be different from the current password',
    DIFFERENT_PASSWORD: 'Confirm password should be equal to the new password',
}

export type FormValues = {
  [FIELD_PASSWORD_ID]: string;
  [FIELD_CONFIRMPASSWORD_ID]: string;
};