import { FunctionalComponent, h } from 'preact';
import { CONTACT_US_URL } from '../contact-us.strings';
import { PAGE_TITLE } from './thank-you-info.strings';
import {
  ContactUsThankYouInfoContentStyled,
  ContactUsThankYouInfoFieldsStyled,
  ContactUsThankYouInfoStyled,
  OverlayStyled,
} from './thank-you-info.style';

interface ContactUsThankYouInfoProps {}
export const ContactUsThankYouInfo: FunctionalComponent<
  ContactUsThankYouInfoProps
> = (props) => {
  return (
    <ContactUsThankYouInfoStyled className='contact-us-form'>
      <ContactUsThankYouInfoContentStyled>
        <h1>{PAGE_TITLE}</h1>
        <ContactUsThankYouInfoFieldsStyled>
          <p>
            Thanks for contacting us. We will be in touch with a response as
            soon as we can.
          </p>
          <p>
            For urgent matters or queries about an online order, please refer to
            the telephone contact details on the{' '}
            <a href={CONTACT_US_URL}>prior page.</a>
          </p>
        </ContactUsThankYouInfoFieldsStyled>
      </ContactUsThankYouInfoContentStyled>
      <OverlayStyled></OverlayStyled>
    </ContactUsThankYouInfoStyled>
  );
};
