import {colors} from "@greencross/overrides/design-system/style-guide/color";

/** Types **/
export type DefaultButtonStyle = {
    color?: string;
    border?: string;
    borderWidth?: string;
    background?: string;
    fontSize?: string;
    letterSpacing?: string;
    fontFamily?: string;
    fontWeight?: string;
    width?: string;
    padding?: string;
    height?: string;
    borderRadius?: string;
    textTransform?: string;
};

export type DefaultButtonConfigProps = {
    style: DefaultButtonStyle;
};

/** Small config */

export const SmallButtonConfig = {
    style: {
        fontSize: '1.375rem',
        borderRadius: "5px",
    }
}

/** Common buttons */
export const PrimaryButtonConfig: DefaultButtonConfigProps = {
    style: {
        color: colors.white,
        border: colors.primary,
        borderWidth: "1px",
        background: colors.primary,
        fontSize: '1.625rem',
        letterSpacing: '0.5',
        fontFamily: 'OpenSans-Bold',
        fontWeight: '600',
        width: 'auto',
        padding: '0 2.654em',
        height: '2.65384em',
        borderRadius: '50px',
        textTransform: 'uppercase',
    },
};

export const PrimarySmallButtonConfig: DefaultButtonConfigProps = {
    style: {
        ...PrimaryButtonConfig.style,
        ...SmallButtonConfig.style,
    },
};

export const SecondaryButtonConfig: DefaultButtonConfigProps = {
    style: {
        ...PrimaryButtonConfig.style,
        background: colors.white,
        border: colors.primary,
        color: colors.primary,
    }
};

export const SecondarySmallButtonConfig: DefaultButtonConfigProps = {
    style: {
        ...SecondaryButtonConfig.style,
        ...SmallButtonConfig.style,
    },
};

/** Outline buttons */
export const PrimaryOutlineButtonConfig: DefaultButtonConfigProps = {
    style: {
        ...PrimaryButtonConfig.style,
        color: colors.primary,
        border: colors.primary,
        borderWidth: "3px",
        background: colors.white,
    },
};

export const PrimaryOutlineSmallButtonConfig: DefaultButtonConfigProps = {
    style: {
        ...PrimaryOutlineButtonConfig.style,
        ...SmallButtonConfig.style,
    },
};

export const SecondaryOutlineButtonConfig: DefaultButtonConfigProps = {
    style: {
        ...PrimaryOutlineButtonConfig.style,
        color: colors.secondary,
        border: colors.secondary,
    },
};

export const SecondaryOutlineSmallButtonConfig: DefaultButtonConfigProps = {
    style: {
        ...SecondaryOutlineButtonConfig.style,
        ...SmallButtonConfig.style,
    },
};

/** Link buttons */
export const PrimaryLinkButtonConfig: DefaultButtonConfigProps = {
    style: {
        ...PrimaryButtonConfig.style,
        borderWidth: "0",
        color: colors.primary,
        background: colors.transparent,
        padding: "0",
        textTransform: 'capitalize',
        height: 'auto'
    },
};

export const PrimaryLinkSmallButtonConfig: DefaultButtonConfigProps = {
    style: {
        ...PrimaryLinkButtonConfig.style,
        ...SmallButtonConfig.style,
    },
};

export const SecondaryLinkButtonConfig: DefaultButtonConfigProps = {
    style: {
        ...PrimaryLinkButtonConfig.style,
        color: colors.secondary,
    },
};

export const SecondaryLinkSmallButtonConfig: DefaultButtonConfigProps = {
    style: {
        ...SecondaryLinkButtonConfig.style,
        ...SmallButtonConfig.style,
    },
};


export const ButtonsConfigs = {
    PrimaryButtonConfig,
    PrimarySmallButtonConfig,
    SecondaryButtonConfig,
    SecondarySmallButtonConfig,
    PrimaryOutlineButtonConfig,
    PrimaryOutlineSmallButtonConfig,
    SecondaryOutlineButtonConfig,
    SecondaryOutlineSmallButtonConfig,
    PrimaryLinkButtonConfig,
    PrimaryLinkSmallButtonConfig,
    SecondaryLinkButtonConfig,
    SecondaryLinkSmallButtonConfig,
}