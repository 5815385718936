import { colors } from "@authentic/loyalty-pwa/src/src/overrides/design-system/style-guide/color";

export type DefaultCtaInfoVariant3ConfigProps = {
  style: {
    containerBackgroundColor: string
  }
};

export let DefaultCtaInfoVariant3Config: DefaultCtaInfoVariant3ConfigProps = {
  style: {
    containerBackgroundColor: colors.grayVariant6
  },
};