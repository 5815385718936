// typically we would use the @greencross alias here, but it doesn't work with the lambda
import { validateAge } from '../../../../utils/birthdate';
import zxcvbn from 'zxcvbn';
import {
  AGE_BIRTHDATE_VALIDATION_ERROR,
  DEFAULT_ERROR_MESSAGE,
  EMAIL_FORMAT_ERROR,
} from './sign-up-form.config';

export const TITLE_INPUT_ID = 'marital_status';
export const FIRST_NAME_INPUT_ID = 'first_name';
export const LAST_NAME_INPUT_ID = 'last_name';
export const DATE_OF_BIRTH_INPUT_ID = 'birthdate';
export const GENDER_INPUT_ID = 'gender';
export const MAILING_ADDRESS_INPUT_ID = 'mailing_address';
export const EMAIL_INPUT_ID = 'email';
export const CONFIRM_EMAIL_INPUT_ID = 'confirmemail';
export const MOBILENUMBER_INPUT_ID = 'mobile_phone';
export const PASSWORD_INPUT_ID = 'password';
export const CONFIRMPASSWORD_INPUT_ID = 'password_confirmation';
export const TERMS_CONDITIONS_CHECKBOX_ID = 'terms_conditions';
export const PRIVACY_POLICY_CHECKBOX_ID = 'privacy_policy';
export const RECAPTCHA_ID = 'recaptcha';
export const COMMUNICATION_PREF_EMAIL_ID = 'suspend_email';
export const COMMUNICATION_PREF_MAIL_ID = 'mail_opt_out';
export const COMMUNICATION_PREF_TEXT_ID = 'suspend_sms';
export const COMMUNICATION_PREF_PHONE_ID = 'phone_opt_out';

export const FIELD_BIRTHDATE = 'birthdate';

export const SIGNUP_TITLES = ['Mr', 'Mrs', 'Ms'];
export const SIGNUP_GENDERS: Record<string, string> = {
  m: 'Male',
  f: 'Female',
  d: 'Other',
};

export type FormValues = {
  [TITLE_INPUT_ID]: 'Mr' | 'Mrs' | 'Ms';
  [FIRST_NAME_INPUT_ID]: string;
  [LAST_NAME_INPUT_ID]: string;
  [DATE_OF_BIRTH_INPUT_ID]: string;
  [GENDER_INPUT_ID]: 'm' | 'f' | 'd';
  [MAILING_ADDRESS_INPUT_ID]: string;
  [EMAIL_INPUT_ID]: string;
  [CONFIRM_EMAIL_INPUT_ID]: string;
  [MOBILENUMBER_INPUT_ID]: string;
  [PASSWORD_INPUT_ID]: string;
  [CONFIRMPASSWORD_INPUT_ID]: string;
  [RECAPTCHA_ID]: string;
  [COMMUNICATION_PREF_EMAIL_ID]: boolean;
  [COMMUNICATION_PREF_MAIL_ID]: boolean;
  [COMMUNICATION_PREF_TEXT_ID]: boolean;
  [COMMUNICATION_PREF_PHONE_ID]: boolean;
};

export const SIGNUP_RULES = {
  [TITLE_INPUT_ID]: {
    required: false,
    validate: (val: string) =>
      !val ||
      SIGNUP_TITLES.indexOf(val) !== -1 ||
      'Please select a title from the list',
  },
  [FIRST_NAME_INPUT_ID]: {
    required: {
      value: true,
      message: DEFAULT_ERROR_MESSAGE,
    },
    maxLength: {
      value: 100,
      message: 'Please use less than 100 characters',
    },
  },
  [LAST_NAME_INPUT_ID]: {
    required: {
      value: true,
      message: DEFAULT_ERROR_MESSAGE,
    },
    maxLength: {
      value: 100,
      message: 'Please use less than 100 characters',
    },
  },
  [GENDER_INPUT_ID]: {
    required: false,
    validate: (val: string) =>
      !val ||
      Object.keys(SIGNUP_GENDERS).indexOf(val) !== -1 ||
      'Please select a gender from the list',
  },
  [DATE_OF_BIRTH_INPUT_ID]: {
    required: {
      value: false,
      message: DEFAULT_ERROR_MESSAGE,
    },
    validate: (val: string) =>
      validateAge(val) || AGE_BIRTHDATE_VALIDATION_ERROR,
  },
  [EMAIL_INPUT_ID]: {
    required: {
      value: true,
      message: DEFAULT_ERROR_MESSAGE,
    },
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: EMAIL_FORMAT_ERROR,
    },
  },
  [MOBILENUMBER_INPUT_ID]: {},
  [PASSWORD_INPUT_ID]: {
    required: {
      value: true,
      message: DEFAULT_ERROR_MESSAGE,
    },
    validate: (value: string) =>
      zxcvbn(value).score >= 2 || 'Password is not strong enough',
  },
  [CONFIRMPASSWORD_INPUT_ID]: {
    required: {
      value: true,
      message: DEFAULT_ERROR_MESSAGE,
    },
  },
  [COMMUNICATION_PREF_EMAIL_ID]: {},
  [COMMUNICATION_PREF_MAIL_ID]: {},
  [COMMUNICATION_PREF_TEXT_ID]: {},
  [COMMUNICATION_PREF_PHONE_ID]: {},
};
