import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';
import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const FAQPageStyled = styled.div`
  max-width: ${Breakpoints.XL};
  position: relative;
  margin: 0 auto;
  padding: 4rem 0;
  color: #666666;
  font-family: BrandonGrotesque-Medium;
  font-size: 2rem;

  h1,
  h2 {
    text-transform: uppercase;
  }

  h1 {
    color: ${colors.black};
    text-align: center;
    font-weight: bold;
    font-size: 2.5rem;
    font-family: BrandonGrotesque-Bold;
    margin-bottom: 3rem;
  }

  a {
    font-family: BrandonGrotesque-Black;
    &:hover {
      text-decoration: none;
    }
  }

  @media screen and (max-width: ${Breakpoints.XL}) {
    padding: 3rem 3rem;
    font-size: 1.375rem;

    h2 {
      text-align: center;
    }
  }
`;

export const FAQSubmenuStyled = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    border: 0.312rem solid #d9d9d9;
    border-radius: 1.5rem;
    padding: 1rem 5rem;
  }
  li {
    padding: 1rem;
    a {
      text-transform: uppercase;
      &.active {
        color: black;
      }
    }
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    ul {
      padding: 1rem 0;
    }
  }
`;

export const FAQAccordionItem = styled.div`
  border-bottom: 2px solid #d9d9d9;
  padding: 1rem;
`;

export const FAQCaret = styled.div`
  position: absolute;
  width: 8px;
  cursor: pointer;
  right: 0;
  top: 30%;

  &:before,
  &:after {
    background-color: ${colors.orange};
    width: 2px;
    height: 1.5rem;
    content: '';
    display: inline-block;
    position: absolute;
  }
  &:before {
    left: 0.75rem;
    transform: rotate(30deg);
  }
  &:after {
    left: 0;
    transform: rotate(-30deg);
  }

  &.up {
    &:before {
      transform: rotate(-30deg);
    }
    &:after {
      transform: rotate(30deg);
    }
  }
`;

export const FAQSubpageStyled = styled.div`
  padding: 1rem 5rem;
  @media screen and (max-width: ${Breakpoints.XL}) {
    padding: 1rem 0;
  }
`;

export const FAQAccordionTitle = styled.div`
  text-transform: uppercase;
  position: relative;
  a {
    display: block;
    font-size: 1.9rem;
    font-weight: 900;
    font-family: BrandonGrotesque-Bold;
    padding-right: 2rem;
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    a {
      font-size: 1.375rem;
    }
  }
`;
export const FAQAccordionBody = styled.div`
  display: none;
  p {
    font-family: BrandonGrotesque-Medium;
    font-size: 2rem;
    color: #666666;
  }
  a {
    font-family: BrandonGrotesque-Medium;
    font-size: 2rem;
  }
  &.expanded {
    display: block;
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    p,
    a {
      font-size: 1.375rem;
    }
  }
`;

export const ContactUsStyled = styled.div`
  background-color: ${colors.lightblack};
  color: white;
  border-radius: 2rem;
  padding: 0 5rem;
  margin: 5rem 0;
  font-family: BrandonGrotesque-Medium;

  h2 {
    font-family: BrandonGrotesque-Bold;
    font-size: 2rem;
    border-bottom: 0.4rem solid white;
    padding: 3rem 0;
    margin: 0;
  }
  h3 {
    font-family: BrandonGrotesque-Bold;
    padding: 0;
    margin: 0;
  }
  div:not(:last-child) {
    border-bottom: 0.4rem solid white;
    @media screen and (max-width: ${Breakpoints.XL}) {
      border-bottom: 3px solid white;
    }
  }
  div {
    padding: 2rem 0;
  }
  p {
    margin: 0;
    span {
      font-family: BrandonGrotesque-Bold;
    }
  }
  a {
    color: ${colors.white};
    &:hover {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    padding: 0 6rem;
		width: 420px;
		margin: 0 auto;
    h2 {
      font-size: 1.375rem;
    }
  }

	@media screen and (max-width: ${Breakpoints.XS}) {
		width: 100%;
	}
`;

export const LostCardStyled = styled.div`
  font-family: BrandonGrotesque-Medium;
  font-size: 2.16rem;
  padding: 0 5rem;
  margin: 5rem 0;
  h2 {
    font-family: OpenSans-Bold;
    font-size: 1.9rem;
    margin: 0 0 3rem 0;
    text-align: center;
  }
  div {
    text-align: center;
    img {
      max-width: 75%;
    }
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    padding: 1rem 0;
    font-size: 1.375rem;
    h2 {
      font-size: 1.375rem;
    }
  }
`;

export const LeftColumnStyled = styled.div`
  width: 45%;
`;

export const RightColumnStyled = styled.div`
  width: 55%;
`;

export const FAQBodyStyled = styled.div`
  display: flex;
  > div {
    margin: 3rem 0;
    &.mobile {
      display: none;
    }
    @media screen and (max-width: ${Breakpoints.XL}) {
      margin: 1rem 0;
      .desktop {
        display: none;
      }
      &.mobile {
        display: inline-block;
        text-align: center;
        h2 {
          border-bottom: 3px solid white;
          padding: 2rem 0;
        }
      }
    }
  }

  @media screen and (max-width: ${Breakpoints.XL}) {
    display: block;
    > div {
      width: 100%;
    }
  }
`;
