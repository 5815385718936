import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';
import { FlexCenter } from '@authentic/loyalty-pwa/design-system/style-guide/utils/flex.helper';
import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const ContactUsThankYouInfoStyled = styled.div`
  position: relative;
  width: 1023px;
  margin: 0 auto;
  border: 2px solid #dadada;
  border-radius: 24px;
  padding: 64px 50px;
  min-height: 604px;
  ${FlexCenter}
  @media screen and (max-width: ${Breakpoints.XL}) {
    width: 90%;
    padding: 48px 24px;
  }
  @media screen and (max-width: ${Breakpoints.XS}) {
    width: 93%;
    padding: 32px 15px;
  }
`;

export const ContactUsThankYouInfoFieldsStyled = styled.div`
  margin: 0 auto;
  h3 {
    color: ${colors.black};
  }
`;

export const ContactUsThankYouInfoContentStyled = styled.div`
  z-index: 1;
  position: relative;

  h1 {
    color: ${colors.black};
    font-size: 2.5rem;
    text-align: center;
    margin-top: 0;
    margin-bottom: 1em;
    font-family: 'BrandonGrotesque-Bold';
    text-transform: uppercase;
    @media screen and (max-width: ${Breakpoints.XL}) {
      font-size: 2.8rem;
    }

    @media screen and (max-width: ${Breakpoints.S}) {
      font-size: 2.3125rem;
    }
  }

  p {
    color: #666666;
    font-size: 1.9rem;
    margin: 1em 0;
    text-align: center;
  }
`;

export const OverlayStyled = styled.div`
  z-index: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 0.9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 23px;
`;
