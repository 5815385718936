import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';
import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const HomePageStyled = styled.div``;

export const WelcomeSliderContainerStyled = styled.div`
  max-width: 1170px;
  margin: 32px auto 0;
  @media screen and (max-width: ${Breakpoints.ML}) {
    padding: 0 24px;
  }
  @media screen and (max-width: ${Breakpoints.L}) {
    padding: 0;
    margin-top: 0;
  }
`;

export const HomePageContentStyled = styled.div`
  position: relative;
  max-width: 1170px;
  margin: 32px auto;
  @media screen and (max-width: ${Breakpoints.ML}) {
    padding: 0 24px;
  }
`;

export const WelcomeLivingRewardsContentStyled = styled.div`
  display: flex;
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  @media screen and (max-width: ${Breakpoints.ML}) {
    /* 
    this measure in pixels allows to handle the font size of the child components that use font-size measures in em (no rem).
    */
    font-size: 10px;
  }
  @media screen and (max-width: ${Breakpoints.L}) {
    font-size: 8px;
    border-radius: 0;
  }
  @media screen and (max-width: ${Breakpoints.M}) {
    font-size: 7px;
  }
  @media screen and (max-width: ${Breakpoints.S}) {
    font-size: 6px;
  }
  @media screen and (max-width: ${Breakpoints.XS}) {
    font-size: 5px;
  }
`;

const LivingRewardsBannerHeight = `
  height: 350px;
  @media screen and (max-width: ${Breakpoints.L}) {
    height: 320px;
  }
  @media screen and (max-width: ${Breakpoints.M}) {
    height: 280px;
  }
  @media screen and (max-width: ${Breakpoints.XS}) {
    height: 150px;
  }
`;
export const BackgroundShadowStyled = styled.div<{
  backgroundImage: string;
}>`
  background-repeat: repeat;
  background-image: ${(p) => p.backgroundImage};
  background-size: contain;
  ${LivingRewardsBannerHeight};
  flex: 1;
`;

export const WelcomLivingRewardsLeftContentStyled = styled.div`
  position: absolute;
  width: 55%;
  left: 0;
  top: 0;
  ${LivingRewardsBannerHeight};
  padding: 2em 0 2em 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: ${Breakpoints.XL}) {
    width: 65%;
  }
  @media screen and (max-width: ${Breakpoints.XS}) {
    width: 78%;
  }
`;

export const CareAdviceLogoStyled = styled.div`
  background: #fff;
  width: 180px;
  padding: 1.5em 2em;
  right: 2.5em;
  position: absolute;
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;
  top: 1px;
  img {
    width: 100%;
  }

  @media screen and (max-width: ${Breakpoints.L}) {
    width: 80px;
    top: 38px;
    right: 2px;
  }
  @media screen and (max-width: ${Breakpoints.XS}) {
    width: 50px;
    top: 27px;
  }
`;

export const BackgroundImageStyled = styled.div<{
  backgroundImage: string;
  MobileBackgroundImage: string;
  MobileBackgroundImage480: string;
}>`
  background-repeat: no-repeat;
  background-image: ${(p) => p.backgroundImage};
  background-size: cover;
  background-position: left;
  ${LivingRewardsBannerHeight};
  width: 960px;
  flex: 2.1;
  @media screen and (max-width: ${Breakpoints.L}) {
    background-image: ${(p) => p.MobileBackgroundImage};
    background-size: contain;
    flex: 6.1;
  }
  @media screen and (max-width: ${Breakpoints.XS}) {
    background-image: ${(p) => p.MobileBackgroundImage480};
  }
`;
export const TitleStyled = styled.div`
  color: ${colors.darkBlue};
  font-family: 'BrandonGrotesque-Black';
  font-size: 3.38em;
  @media screen and (max-width: ${Breakpoints.XS}) {
    font-size: 3.1em;
  }
`;
export const DescriptionStyled = styled.div`
  font-size: 2.41em; // px/14.5
  font-family: 'BrandonGrotesque-Medium';
  padding-right: 3.4em;
  @media screen and (max-width: ${Breakpoints.XS}) {
    font-size: 2.5em;
  }
`;
export const ButtonsStyled = styled.div`
  padding-top: 2em;
  @media screen and (max-width: ${Breakpoints.XS}) {
    font-size: 6px;
  }
  @media screen and (max-width: ${Breakpoints.XS1}) {
    font-size: 5.5px;
  }
  a {
    &:nth-child(1) {
      button {
        background-color: ${colors.orange};
      }
    }
    &:nth-child(2) {
      button {
        margin-left: 2em;
        color: ${colors.orange};
      }
    }
  }
  button {
    padding: 0.615em 1.654em;
    border: 1px solid ${colors.orange};
    font-size: 1.625em;
  }
`;

export const SliderStyled = styled.div`
  margin: 32px 0;
  border-radius: 24px;
  overflow: hidden;
  .nav-slider img {
    border-radius: 24px;
  }
  @media screen and (max-width: ${Breakpoints.L}) {
    border-radius: 0;
    .nav-slider img {
      border-radius: 0;
    }
  }
  @media screen and (max-width: ${Breakpoints.XS}) {
    margin: 16px auto;
  }
`;

export const CallToActionSectionStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5%;
  .call-to-action-model1 {
    width: 21.25%;
    &:nth-child(1) {
      img {
        width: 218px;
        @media screen and (max-width: ${Breakpoints.XS}) {
          width: 100px;
        }
      }
    }
    img {
      width: 130px;
      height: auto;
      @media screen and (max-width: ${Breakpoints.XS}) {
        width: 85px;
      }
    }
    @media screen and (max-width: ${Breakpoints.ML}) {
      width: 47.5%;
      margin-bottom: 5%;
    }
    @media screen and (max-width: ${Breakpoints.XS}) {
      > div:nth-child(1) {
        height: 140px;
      }
      .content {
        padding: 16px 12px;
        .title {
          font-size: 1.1em;
        }
        a {
          font-size: 1em;
        }
      }
    }
  }
`;

export const HowItWorksContainerStyled = styled.div`
  display: flex;
  width: 1170px;
  position: relative;
  margin: 0 auto;
`;

export const HowItWorksSectionStyled = styled.div`
  display: flex;
  position: relative;
  margin: 3em 0;
`;

export const HowItWorksSectionBackgroundShadowStyled = styled.div<{
  backgroundImage: string;
}>`
  background-repeat: repeat;
  background-image: ${(p) => p.backgroundImage};
  background-size: contain;
  height: 800px;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  @media screen and (max-width: ${Breakpoints.XL}) {
    height: 1000px;
  }
  @media screen and (max-width: ${Breakpoints.XS}) {
    height: 650px;
  }
`;

export const HowItWorksSectionBackgroundImageStyled = styled.div<{
  backgroundImage: string;
  MobileBackgroundImage: string;
}>`
  background-repeat: no-repeat;
  background-image: ${(p) => p.backgroundImage};
  background-size: contain;
  background-position: center;
  height: 800px; // px/1.27
  width: 1024px;
  flex: 1.5;
  z-index: 1;
  @media screen and (max-width: ${Breakpoints.XL}) {
    width: 100%;
    background-image: ${(p) => p.MobileBackgroundImage};
    background-size: auto 1000px;
    height: 1000px;
    flex: 1;
  }
  @media screen and (max-width: ${Breakpoints.XS}) {
    background-size: auto 1000px;
    width: 320px;
    height: 1000px;
  }
`;

export const JoinLivingRewardsButtonStyled = styled.a``;
export const SignInButtonStyled = styled.a``;

export const HowItWorksContentStyled = styled.div`
  position: absolute;
  width: 50%;
  left: 0;
  top: 0;
  height: 626.77px;
  padding: 0 3em;
  @media screen and (max-width: ${Breakpoints.XL}) {
    margin-left: -235px;
    width: 470px;
    left: 50%;
    height: 460.77px;
  }
  @media screen and (max-width: ${Breakpoints.XS}) {
    width: 320px;
    margin-left: -160px;
    height: 400px;
  }
`;

export const HowItWorksContentDetailStyled = styled.div`
  z-index: 3;
  position: relative;
  display: flex;
  flex-direction: column;
  button {
    background-color: #00aeef;
    border: 1px solid #00aeef;
    font-size: 1.625rem;
    padding: 0 3em;
    margin: 0 auto;
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    font-size: 9px;
  }
`;

/** Custom gradient: faa61a, fba819, ffcd09 */
export const HowItWorksContentTitleStyled = styled.h2`
  font-size: 2.625em;
  margin: 0;
  padding: 0.5em 0;
  font-family: 'BrandonGrotesque-Bold';
  text-align: center;
  color: #fff;
  border: none;
  background: rgb(250, 166, 26);
  background: linear-gradient(
    100deg,
    rgba(250, 166, 26, 1) 0%,
    rgba(250, 166, 26, 1) 15%,
    rgba(251, 168, 25, 1) 32%,
    rgba(255, 205, 9, 1) 100%
  );
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
  @media screen and (max-width: ${Breakpoints.XS}) {
    font-size: 2em;
  }
`;
export const HowItWorksContentImageStyled = styled.img`
  width: 100%;
  margin: 2em 0;
  @media screen and (max-width: ${Breakpoints.XL}) {
    width: 80%;
    margin: 2em auto 1em;
  }
`;
export const HowItWorksContentSubtitleStyled = styled.h2`
  color: ${colors.orange};
  font-family: 'BrandonGrotesque-Bold';
  font-size: 2.625em;
  text-align: center;
  margin: 0;
  @media screen and (max-width: ${Breakpoints.XS}) {
    font-size: 2em;
  }
`;
export const HowItWorksDescriptionStyled = styled.p`
  font-family: 'BrandonGrotesque-Medium';
  font-size: 2.1em;
  text-align: center;
  margin-top: 0;
  &.strong {
    font-family: 'BrandonGrotesque-Bold';
    font-size: 2.2em;
  }
  @media screen and (max-width: ${Breakpoints.XS}) {
    font-size: 1.7em;
    &.strong {
      font-size: 1.8em;
    }
  }
`;

export const HowItWorksOverlayStyled = styled.div`
  z-index: 2;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 0.6;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
`;
