import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const ContactUsPageStyled = styled.div``;

export const ContactUsPageContentStyled = styled.div`
  position: relative;
  min-height: 1160px;
  padding: 92px 0;
  .background-image {
    background-color: #73c4d7;
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    padding: 48px 0;
  }
`;
