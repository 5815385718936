import styled from 'styled-components';
import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';

export const Heading1 = styled.h1<{ fontSize?: string; color?: string }>`
  color: ${colors.black};
  font-size: 2.5rem;
  text-align: center;
  margin-top: 0;
  font-family: 'BrandonGrotesque-Bold';
  text-transform: uppercase;
`;

export const Heading2 = styled.h2<{ fontSize?: string; color?: string }>`
  font-size: ${(p) => (p.fontSize ? p.fontSize : '24px')};
  color: ${(p) => (p.color ? p.color : colors.secondary)};
`;

export const Heading3 = styled.h3<{ fontSize?: string; color?: string }>`
  font-size: ${(p) => (p.fontSize ? p.fontSize : '20px')};
  color: ${(p) => (p.color ? p.color : colors.primary)};
`;

export const Heading4 = styled.h4<{ fontSize?: string; color?: string }>`
  margin: 0;
  font-size: ${(p) => (p.fontSize ? p.fontSize : '18px')};
  color: ${(p) => (p.color ? p.color : colors.primary)};
`;

export const Heading5 = styled.h4<{ fontSize?: string; color?: string }>`
  margin: 0;
  font-size: ${(p) => (p.fontSize ? p.fontSize : '16px')};
  color: ${(p) => (p.color ? p.color : colors.primary)};
  font-weight: 400;
`;

export const Text1 = styled.label<{ fontSize?: string; color?: string }>`
  font-size: ${(p) => (p.fontSize ? p.fontSize : '16px')};
  color: ${(p) => (p.color ? p.color : colors.primary)};
`;

export const Text2 = styled.label<{ fontSize?: string; color?: string }>`
  font-size: ${(p) => (p.fontSize ? p.fontSize : '14px')};
  color: ${(p) => (p.color ? p.color : colors.secondary)};
`;

export const Text3 = styled.label<{ fontSize?: string; color?: string }>`
  font-size: ${(p) => (p.fontSize ? p.fontSize : '12px')};
  color: ${(p) => (p.color ? p.color : colors.secondary)};
`;

export const Link1 = styled.a<{ fontSize?: string; color?: string }>`
  font-size: ${(p) => (p.fontSize ? p.fontSize : '18px')};
  color: ${(p) => (p.color ? p.color : colors.primary)};
  font-family: UntitledSansBold;
  cursor: pointer;
  text-decoration: none;
`;

export const Link2 = styled(Link1)<{ fontSize?: string }>`
  font-size: ${(p) => (p.fontSize ? p.fontSize : '16px')};
`;

export const Paragraph1 = styled.p<{ fontSize?: string; color?: string }>`
  font-size: ${(p) => (p.fontSize ? p.fontSize : '18px')};
  color: ${(p) => (p.color ? p.color : colors.black)};
  line-height: 28px;
`;
