import { fontFamily } from '@authentic/loyalty-pwa/design-system/style-guide/font';
import { colors } from '../../../style-guide/color';

/**
 * This is a Global Config for TextField
 */
export type DefaultTextFieldStyle = {
  color: string;
  fontSize: string;
  letterSpacing: string;
  fontFamily: string;
  fontWeight: string;
  padding: string;
  height: string;
  borderColor: string;
};

export type DefaultTextFieldConfigProps = {
  style: DefaultTextFieldStyle;
};

export let DefaultTextFieldConfig: DefaultTextFieldConfigProps = {
  style: {
    color: colors.secondary,
    fontSize: '14px',
    letterSpacing: '0.5',
    fontFamily: fontFamily,
    fontWeight: '600',
    padding: '6px',
    height: '47px',
    borderColor: colors.grayVariant3,
  },
};

export const setDefaultTextFieldConfig = (config: DefaultTextFieldConfigProps) => {
  if (config.style) {
    DefaultTextFieldConfig.style = {
      ...DefaultTextFieldConfig.style,
      ...config.style,
    };
  }
};
