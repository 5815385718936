import styled from 'styled-components';
import { Title } from '@greencross/overrides/design-system/components/base/cta-info/variant2/cta-info.variant2.style';
import { Card } from '@greencross/components/card/card.style';
import { Breakpoints } from '@greencross/utils/breakpoints';
import { Paragraph1 } from '@authentic/loyalty-pwa/design-system/style-guide/typography';
import { colors } from '@greencross/overrides/design-system/style-guide/color';

export const ChangePasswordPageStyled = styled.div`
  ${Title} {
    font-family: BrandonGrotesque-Bold, serif;
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    text-transform: uppercase;
    @media screen and (max-width: ${Breakpoints.XS}) {
      font-size: 2rem;
      padding-left: 1.6em;
      padding-right: 1.6em;
    }
  }
  ${Card} {
    max-width: 560px;
    margin: 0 auto;
    padding: 3em 6em;
    max-height: 890px;
    @media screen and (max-width: ${Breakpoints.S}) {
      padding: 1em 2em;
    }
    @media screen and (max-width: ${Breakpoints.XS}) {
      margin: 6em auto;
      max-height: 695px;
      padding: 1em;
      .password-disclaimer {
        font-size: 1.2rem;
      }
    }
  }
  ${Paragraph1} {
    font-size: 1.375rem;
    color: ${colors.text};
    margin-bottom: 2em;
  }
  .change-password-container {
    padding: 2em 7.2em;
    display: flex;
    flex-direction: column;
    position: relative;
    @media screen and (max-width: ${Breakpoints.XS}) {
      padding: 0em 1em 2em;
    }
  }
  & .content-container {
    position: relative;
    padding: 9.727em 0;
    @media screen and (max-width: ${Breakpoints.XS}) {
      padding: 0.5em;
    }
  }

  .confirmPassword-field {
    margin-bottom: 24px;
  }
`;

export const DescriptionStyled = styled.p`
  font-size: 1.3em;
  margin-bottom: 1.5em;
  &.center {
    text-align: center;
  }
`;
