import { getImageSrc } from "@authentic/loyalty-pwa/util";
import { BaseLayout } from "@greencross/pages/shared/base-layout/base-layout";
import { Fragment, FunctionalComponent, h } from "preact";
import { Link, RouterOnChangeArgs } from "preact-router";
import { useState } from "preact/hooks";
import { FAQ_CONTENT, FAQ_HEADER, FAQ_URL, LOST_CARD_CONTENT, LOST_CARD_HEADER, NEED_HELP_HEADER, NEED_HELP_PHONE_CONTENT, NEED_HELP_SERVICE_CONTENT } from "./faq.strings";
import { ContactUsStyled, FAQAccordionBody, FAQAccordionItem, FAQAccordionTitle, FAQBodyStyled, FAQCaret, FAQPageStyled, FAQSubmenuStyled, FAQSubpageStyled, LeftColumnStyled, LostCardStyled, RightColumnStyled } from "./faq.style";

const parseFAQSubpage = (key: string) => key.toLowerCase().replace(/[^A-Za-z_\-]/g, '_');

// This could be done fancier in one line, but I'm opting for ease of reading
// Basically, take the keys from our content and convert them into subpage keys
const parsedFAQSubpages: Record<string, any> = {};
Object.keys(FAQ_CONTENT)
	.forEach(key => {
		parsedFAQSubpages[parseFAQSubpage(key)] = key; 
	});

const ContactUsAndLostCard = () => (
	<Fragment>
		<ContactUsStyled>
			<h2>{NEED_HELP_HEADER}</h2>
			<div>{NEED_HELP_SERVICE_CONTENT}</div>
			<div>{NEED_HELP_PHONE_CONTENT}</div>
		</ContactUsStyled>
		<LostCardStyled>
			<h2>{LOST_CARD_HEADER}</h2>
			<div><img src={getImageSrc('/assets/images/handy-card.png')} /></div>
			<p>{LOST_CARD_CONTENT}</p>
		</LostCardStyled>
	</Fragment>
);

export const FAQPage: FunctionalComponent<RouterOnChangeArgs> = (props) => {
	const subpageKey = props.matches?.subpage 
		&& parsedFAQSubpages[props.matches?.subpage] 
		&& parsedFAQSubpages[props.matches.subpage] in FAQ_CONTENT 
		? props.matches.subpage 
		: parseFAQSubpage(Object.keys(FAQ_CONTENT)[0]);
	const subpageTitle = parsedFAQSubpages[subpageKey];
	const subpageContent = FAQ_CONTENT[subpageTitle];
	const [expanded, setExpanded] = useState<Record<string, boolean>>({});

	const toggleAccordion = (key: string) => () => setExpanded({...expanded, [key]: !expanded[key]});

	return (
		<BaseLayout>
			<FAQPageStyled>
				<h1>{FAQ_HEADER}</h1>
				<FAQBodyStyled>
					<LeftColumnStyled>
						<FAQSubmenuStyled>
							<ul>
								{Object.keys(FAQ_CONTENT).map(faqSubpage => (
									<li key={faqSubpage}><Link className={faqSubpage == subpageTitle ? 'active': ''} href={`${FAQ_URL}/${parseFAQSubpage(faqSubpage)}`}>{faqSubpage}</Link></li>
								))}
							</ul>
						</FAQSubmenuStyled>
						<div className="desktop">
							<ContactUsAndLostCard />
						</div>
					</LeftColumnStyled>
					<RightColumnStyled>
						<FAQSubpageStyled>
							{Object.keys(subpageContent).map(contentKey => (
								<FAQAccordionItem key={contentKey}>
									<FAQAccordionTitle>
										<Link onClick={toggleAccordion(contentKey)}>
											{contentKey}
											<FAQCaret className={expanded[contentKey] ? 'up' : 'down'} />
										</Link>
									</FAQAccordionTitle>
									<FAQAccordionBody className={expanded[contentKey] ? 'expanded' : ''}>
										{subpageContent[contentKey]}
									</FAQAccordionBody>
								</FAQAccordionItem>
							))}
						</FAQSubpageStyled>
					</RightColumnStyled>
					<div className="mobile">
						<ContactUsAndLostCard />
					</div>
				</FAQBodyStyled>
			</FAQPageStyled>
		</BaseLayout>
	);
};