import styled from "styled-components";
import { breakpoints } from "@authentic/loyalty-pwa/util/variables";

export const Card = styled.div`
  padding-top: 44px;
  padding-bottom: 47px;
  background-color: ${props => props.theme.components.cta_info.colors.white};
  margin: auto;
  width:  63.9375rem;
  height: auto;
  display: flex;
  flex-direction: column;
  border: 2px solid #DADADA;
  border-radius: 32px;
  opacity: 0.9;

  @media screen and (max-width: ${breakpoints.md}) {
    width: 98%;
    padding-top: 62px;
  }
  button{
    width: 100%;
  }

  svg{
    margin: 0 auto;
  }
`;