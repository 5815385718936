import { OfferCouponCodeResponse, Offer, WhatsNewOffer, OfferResponse, OfferResponsesQuery, OfferResponsesResult, OfferServiceInterface, OffersQuery, OffersResponse, WhatsNewOffersQuery, WhatsNewOffersResponse } from "@authentic/loyalty-pwa/interfaces";
import { CheetahShared } from "@authentic/loyalty-pwa/services/cheetah/cheetah.shared";

export class CheetahOfferService extends CheetahShared implements OfferServiceInterface {
	async getOffers(data?: OffersQuery): Promise<OffersResponse> {
		return this.fetch({
			method: 'GET',
			url: '/api/offers_with_responses',
			queryParams: data
		}, false).then((response) => {
			if (!response.ok) {
				return response.json().then((res) => ({
					success: false,
					error: res
				}));
			}
			return response.json().then((res) => ({
				success: true,
				total_pages: res.data.total_pages,
				total_entries: res.data.total_entries,
				offers: this.parseOffers(res.data.offers)
			}));
		});
	}

	async getWhatsNewOffers(data?: WhatsNewOffersQuery): Promise<WhatsNewOffersResponse> {
		return this.fetch({
			method: 'GET',
			url: '/api/offers',
			queryParams: data
		}, false).then((response) => {
			if (!response.ok) {
				return response.json().then((res) => ({ ...res, success: false }));
			}
			return response.json().then((res) => ({ ...res, success: true }));
		}).then(response => ({
			success: response.success,
			total_pages: response.data.total_pages,
			total_entries: response.data.total_entries,
			offers: this.parseWhatsNewOffers(response.data.offers)
		}));
	}

	async getOffersResponses(data?: OfferResponsesQuery): Promise<OfferResponsesResult> {
		return this.fetch({
			method: 'GET',
			url: '/api/offers/responses',
			queryParams: {
				result_with_categories: true,
				usable: true,
				page: data?.page,
				sort_by: data?.sort_by,
				per_page: data?.results_per_page,
				sort_dir: data?.sort_dir
			}
		}, false).then((response) => {
			if (!response.ok) {
				return response.json().then((res) => ({ ...res, success: false }));
			}
			return response.json().then((res) => ({ ...res, success: true }));
		}).then(response => ({
			success: response.success,
			total_pages: response.data.total_pages,
			total_entries: response.data.total_entries,
			offers: this.parseOfferRespones(response.data.responses)
		}));
	}

	private parseOffers(offers: any): Offer[] {
		return offers.map((offer: any) => this.parseOffer(offer))
	}

	private parseWhatsNewOffers(offers: any): WhatsNewOffer[] {
		return offers.map((offer: any) => this.parseWhatsNewOffer(offer))
	}

	private parseWhatsNewOffer(offer: any): WhatsNewOffer {
		return {
			id: offer.id,
			internal_name: offer.internal_name,
			type: offer.type,
			campaign_id: offer.campaign_id,
			campaign_rank: offer.campaign_rank,
			heading: offer.heading,
			subheading: offer.subheading,
			action: offer.action,
			image_url: offer.image_url,
			thumbnail_url: offer.thumb_image_url,
			original_image_url: offer.original_image_url,
			respondable: offer.respondable,
			points: offer.metric_amount,
			respondable_info: offer.respondable_info,
			target_url: offer.target_url,
			internal_url: offer.internal_url,
			description: offer.body,
			url: offer.url,
			extra_json: offer.extra_json
		}
	}

	private parseOffer(offer: any): Offer {
		return {
			id: offer.id,
			metric: offer.metric_name,
			start_date: offer.start_date,
			end_date: offer.response_end_date,
			title: offer.heading,
			subtitle: offer.subheading,
			content: offer.body,
			image_url: offer.image_url,
			thumbnail_url: offer.thumb_image_url,
			respondable: !(offer.responses.length > 0 && (offer.responses[0].processing_status === 'pending' || offer.responses[0].processing_status === 'clipped')),
			points: offer.metric_amount,
			respondable_info: offer.respondable_info
		}
	}

	async saveOffer(offerId: number): Promise<{ success: boolean }> {
		return this.fetch({
			url: `/api/offers/${offerId}/respond`,
			body: {}
		}, false).then((response) => {
			if (!response.ok) {
				return response.json().then((res) => ({ ...res, success: false }));
			}
			return response.json().then((res) => ({ ...res, success: true }));
		});
	}

	private parseOfferRespones(offers: any): OfferResponse[] {
		return offers.map((offer: any) => this.parseOfferResponse(offer));
	}

	private parseOfferResponse(offer: any): OfferResponse {
		return {
			id: offer.id,
			metric: offer.offer.metric_name,
			start_date: offer.usage_start_date,
			end_date: offer.usage_end_date,
			title: offer.offer.heading,
			subtitle: offer.offer.subheading,
			content: offer.offer.body,
			image_url: offer.offer.image_url,
			thumbnail_url: offer.offer.thumb_image_url,
			respondable: offer.respondable,
			points: offer.offer.metric_amount,
			respondable_info: offer.offer.respondable_info,
			status: offer.processing_status,
			type: offer.offer.categories[0],
			associated_offer_id: offer.offer.id,
			certificate_code: offer.certificate && offer.certificate.code,
			internal_name: offer.offer.internal_name,
			extra_json: offer.offer.extra_json
		}
	}

	async unclipOfferResponse(offerResponseId: number): Promise<{ success: boolean }> {
		return this.fetch({
			url: `/api/offers/responses/${offerResponseId}/clip`,
			method: 'DELETE',
			body: {}
		}, false).then((response) => {
			if (!response.ok) {
				return response.json().then((res) => ({ ...res, success: false }));
			}
			return response.json().then((res) => ({ ...res, success: true }));
		});
	}

	async clipOfferResponse(offerResponseId: number): Promise<{ success: boolean }> {
		return this.fetch({
			url: `/api/offers/responses/${offerResponseId}/clip`,
			body: {}
		}, false).then((response) => {
			if (!response.ok) {
				return response.json().then((res) => ({ ...res, success: false }));
			}
			return response.json().then((res) => ({ ...res, success: true }));
		});
	}

	async redeemOfferCode(code: string): Promise<OfferCouponCodeResponse> {
		return this.fetch({
			url: `/api/offers/responses`,
			body: {
				code: code
			}
		}, false).then((response) => {
			if (!response.ok) {
				return response.json().then((res) => ({ ...res, success: false }));
			}
			return response.json().then((res) => ({ ...res, success: true }));
		});
	}
}