import { useEffect, useRef, useState } from "preact/hooks";
import { UserData } from "../model/user.data";
import { Events, EventsService } from "../services/events.service";

// Pull initial user state out of local storage
let storedData = localStorage.getItem('userdata')
let userObj: Partial<UserData> = {}
if (storedData && typeof storedData === 'string') {
    try {
        userObj = JSON.parse(storedData)
    } catch (e) {
        console.error('unable to parse stored user data', e)
    }
}

if (!userObj || typeof userObj !== 'object') {
    userObj = {}
}

let userData = new UserData(userObj);
EventsService.before(Events.USER_CHANGED, userData).then(modifiedData => {
    userData = modifiedData
    EventsService.emit(Events.USER_CHANGED, userData)
})

// When user data is updated, update local storage as well
EventsService.on<UserData>(Events.USER_CHANGED)
    .then((updatedData: UserData) => {
        userData = updatedData
        EventsService.before(Events.USER_SAVED, updatedData).then(modifiedData => {
            console.log('Storing', modifiedData)
            localStorage.setItem('userdata', JSON.stringify(modifiedData))
        }).then(modifiedData => EventsService.emit(Events.USER_SAVED, modifiedData))
    })

export class UserProvider {
    static get() {
        return userData
    }
    static logout() {
        // This is a side effect. When new UserData({}) is called, the User_Changed event is emitted
        EventsService.before(Events.USER_LOGOUT, new UserData)
            .then(modifiedData => {
								if (!localStorage.getItem('logOutType')){
									localStorage.setItem('logOutType', 'logout');
								}
                EventsService.emit(Events.USER_LOGOUT, true)
                UserProvider.update(modifiedData)
            })
    }
    static update(updatedData: Partial<UserData>): Promise<UserData> {
        const combinedData = updatedData instanceof UserData ? updatedData : new UserData({...userData, ...updatedData})
        console.log('Updating user', combinedData)
        return EventsService.before(Events.USER_CHANGED, combinedData)
            .then(modifiedUser => {
                userData = modifiedUser
                EventsService.emit(Events.USER_CHANGED, userData)
                return userData
            })
    }
}
export const useUser = () => {
    const [user, setUser] = useState(userData);
    const componentIsMounted = useRef(true);

    useEffect(() => {
        EventsService.on(Events.USER_CHANGED).then(updatedUser => {
            if (componentIsMounted.current) {
                setUser(updatedUser)
            }
        })
        return () => { componentIsMounted.current = false }
    }, [setUser])
    return user;
}
