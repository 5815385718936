import styled from 'styled-components';
import { breakpoints } from '@authentic/loyalty-pwa/util/variables';

export const Title = styled.span`
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  padding-top: 24px;
  text-align: center;
  color: ${(props) => props.theme.components.title.color};
  @media screen and (max-width: ${breakpoints.md}) {
    padding-top: 16px;
  }
`;

export const Description = styled.span`
  padding-top: 2em;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.5rem;

  text-align: center;
  letter-spacing: 0.15px;

  @media screen and (max-width: ${breakpoints.md}) {
    padding-top: 1.5em;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  padding: 72px 50px 0 50px;
  justify-content: space-around;
`;

export const CtaInfoStyled = styled.div`
  padding-top: 44px;
  padding-bottom: 47px;
  background-color: ${(props) => props.theme.components.cta_info.colors.white};
  margin: 10px auto;
  width: 548px;
  height: 395px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    padding-top: 62px;
  }

  button {
    width: 100%;
    height: 56px;
  }

  span {
    margin: 0 auto;
  }

  svg {
    margin: 0 auto;
  }
`;
