import {
	CustomActivityResponse,
	History,
	HistoryQuery, HistoryResponse,
	HistoryServiceInterface,
} from '@authentic/loyalty-pwa/interfaces/history.service.interface';
import { CheetahShared } from '@authentic/loyalty-pwa/services/cheetah/cheetah.shared';

export class HistoryService extends CheetahShared implements HistoryServiceInterface{

	async getHistory(data?: HistoryQuery): Promise<HistoryResponse> {
		return this.fetch({
			method: 'GET',
			url: '/api/activities2',
			queryParams: data
		}, false).then((response) => {
			console.log('data '+data);
			if (!response.ok) {
				return response.json().then((res) => ({ ...{
						success: false,
						error: res
					}}));
			}
			return response.json().then((res) => ({ ...{
					success: true,
					total_pages: res.data?.total_pages,
					total_entries: res.data?.total_entries,
					histories: this.parseHistories(res.data.activities)
				}}));
		})
	}

	private parseHistories(histories: any): History[] {
		return histories.map((history: any) => this.parseHistory(history))
	}

	private parseHistory(history: any): History {
		return {
			activity_ts: history.activity_ts,
			label: history.label,
			metric_amount: history.metric_amount,
			sl_label: history.details.sl_label,
			sl_location: history.details.sl_location,
			type_label: history.type_label,
			type_name: history.type_name,
			sl_submitter: history.details.sl_submitter,
			sl_client: history.details.sl_client,
			total_dollar: history.details.sl_total,
			sl_transaction_id: history.details.sl_transaction_id
		}
	}

	async getCustomActivities(data: HistoryQuery): Promise<CustomActivityResponse> {
		return this.fetch({
			url: '/api/activities2',
			method: 'GET',
			queryParams: data
		}, false).then((response) => {
			if (!response.ok) {
				return response.json().then((res) => ({ ...res, success: false }));
			}
			return response.json().then((res) => ({ ...res, success: true }));
		});
	}

}
