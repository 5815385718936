import { FunctionalComponent, h } from 'preact';
import {
  UserProvider,
  UserServiceContext,
  useUser,
} from '@authentic/loyalty-pwa/providers';
import {
  CommunicationsPreferencesStyle,
  OverlayStyled,
  ProfileFormContentStyled,
  ProfileFormFieldsStyled,
  ProfileFormStyled,
} from './profile-form.style';
import {
  TextField,
  TextFieldType,
} from '@authentic/loyalty-pwa/design-system/components/base/text-field/text-field.component';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  AGE_BIRTHDATE_VALIDATION_ERROR,
  BIRTHDATE_MASK,
  DEAFULT_ERROR_MESSAGE,
  EMAIL_FORMAT_ERROR,
  PAGE_TITLE,
  PHONENUMBER_MASK,
  PHONENUMBER_PLACEHOLDER,
  SUCCESSFUL_MESSAGE,
} from './profile-form.strings';
import { SelectField } from '@greencross/components/select-field/select-field.component';
import { useContext, useEffect, useRef, useState } from 'preact/hooks';
import { AddressField } from '@greencross/components/address-field/address-field.component';
import {
  FormValues,
  TITLE_INPUT_ID,
  FIRST_NAME_INPUT_ID,
  LAST_NAME_INPUT_ID,
  DATE_OF_BIRTH_INPUT_ID,
  GENDER_INPUT_ID,
  MAILING_ADDRESS_INPUT_ID,
  EMAIL_INPUT_ID,
  MOBILENUMBER_INPUT_ID,
  COMMUNICATION_PREF_EMAIL_ID,
  COMMUNICATION_PREF_MAIL_ID,
  COMMUNICATION_PREF_TEXT_ID,
  COMMUNICATION_PREF_PHONE_ID,
} from './profile-form.constants';
import {
  Button,
  ButtonType,
  ButtonVariant,
} from '@authentic/loyalty-pwa/design-system/components/base/button/button.component';
import { validateMyAge } from '@greencross/utils/birthdate';
import { CheckboxField } from '@greencross/components/checkbox/checkbox.component';
import { ProfileUpdateResult } from '@authentic/loyalty-pwa/interfaces';
import {
  parseDateToDisplayOnTheUI,
  parseDateToSendAPI,
} from '@greencross/utils/format-date';
import { cleanString } from '@greencross/utils/format-phone-number';
import { getAddressById } from '@greencross/utils/api';
import { SIGNUP_GENDERS } from '@greencross/pages/unauthenticated/sign-up/form/sign-up-form.constants';
type OptionalDataType = {
  birthdate?: string;
  mailing_address?: string;
  marital_status?: string;
  gender?: string;
  receive_personalized_offers?: boolean;
  receive_mail_offers?: boolean;
  receive_sms_offers?: boolean;
  receive_mobile_app_offers?: boolean;
  suspend_email?: boolean;
  mail_opt_out?: boolean;
  suspend_sms?: boolean;
  phone_opt_out?: boolean;
};

interface ProfileFormProps {}
export const ProfileForm: FunctionalComponent<ProfileFormProps> = (props) => {
  const userService = useContext(UserServiceContext);
  const user = useUser();
  const [userMailingAddress, setUserMailingAddress] = useState('');
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);
  const [displaySuccessfulMessage, setDisplaySuccessfulMessage] =
    useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    getValues,
    setError,
    register,
    watch,
    setValue: setValueManually,
  } = useForm();
  const [addressMessage, setAddressMessage] = useState<string | true>(true);

  const onFocusInput = (name: string, e: any) => {
    console.log('onInputFocus: ', e.target.value, name);
    document
      .getElementsByClassName(`${name}-field`)[0]
      .getElementsByTagName('label')[0]
      .classList.add('active');
  };

  const onSubmit: SubmitHandler<any> = async (data: FormValues) => {
    console.log('data: ', data);
    setIsProcessingRequest(true);

    if (getValues(MAILING_ADDRESS_INPUT_ID)) {
      getAddressById(getValues(MAILING_ADDRESS_INPUT_ID))
        .then((addressResponse) => {
          return {
            mailing_unit_number: addressResponse.flatUnitNumber,
            mailing_street_number: addressResponse.streetNumber,
            mailing_street_name: addressResponse.street,
            mailing_suburb: addressResponse.suburb,
            mailing_postal_code: addressResponse.postcode,
            //@ts-ignore
            mailing_country_code: addressResponse.attributes.CountryIso2,
            mailing_state: addressResponse.townCity,
          };
        })
        .then((addressDetail) => {
          updateProfile(data, addressDetail);
        })
        .catch((err) => {
          console.log('getAddressById error: ', err);
          updateProfile(data, {});
        });
    } else {
      updateProfile(data, {});
    }
  };

  const updateProfile = (data: FormValues, addressDetail: any) => {
    let optionalData: OptionalDataType = {};

    if (data[DATE_OF_BIRTH_INPUT_ID]) {
      optionalData.birthdate = parseDateToSendAPI(data[DATE_OF_BIRTH_INPUT_ID]);
    }

    if (data[TITLE_INPUT_ID]) {
      optionalData.marital_status = data[TITLE_INPUT_ID];
    }

    if (data[GENDER_INPUT_ID]) {
      optionalData.gender = data[GENDER_INPUT_ID];
    }

    if (data[COMMUNICATION_PREF_EMAIL_ID] !== undefined) {
      optionalData.suspend_email = !data[COMMUNICATION_PREF_EMAIL_ID];
      //optionalData.receive_personalized_offers = data[COMMUNICATION_PREF_EMAIL_ID];
    }

    if (data[COMMUNICATION_PREF_MAIL_ID] !== undefined) {
      optionalData.mail_opt_out = !data[COMMUNICATION_PREF_MAIL_ID];
      //optionalData.receive_mail_offers = data[COMMUNICATION_PREF_MAIL_ID];
    }

    if (data[COMMUNICATION_PREF_TEXT_ID] !== undefined) {
      optionalData.suspend_sms = !data[COMMUNICATION_PREF_TEXT_ID];
      //optionalData.receive_sms_offers = data[COMMUNICATION_PREF_TEXT_ID];
    }

    if (data[COMMUNICATION_PREF_PHONE_ID] !== undefined) {
      optionalData.phone_opt_out = !data[COMMUNICATION_PREF_PHONE_ID];
      //optionalData.receive_mobile_app_offers = data[COMMUNICATION_PREF_PHONE_ID];
    }

    userService
      ?.updateProfile({
        first_name: data[FIRST_NAME_INPUT_ID],
        last_name: data[LAST_NAME_INPUT_ID],
        mobile_phone: cleanString(data[MOBILENUMBER_INPUT_ID]),
        email: data[EMAIL_INPUT_ID],
        ...optionalData,
        ...addressDetail,
      })
      .then((res: ProfileUpdateResult) => {
        setIsProcessingRequest(false);
        if (res.success) {
          UserProvider.get().summaryReset();
          setDisplaySuccessfulMessage(true);
        } else {
          if (res.message) {
            if (res.message.indexOf('phone') > -1) {
              setError(
                MOBILENUMBER_INPUT_ID,
                { message: res.message },
                { shouldFocus: true }
              );
              scrollTo(MOBILENUMBER_INPUT_ID);
            } else if (res.message.indexOf('Mobile') > -1) {
              setError(MOBILENUMBER_INPUT_ID, { message: res.message });
              scrollTo(MOBILENUMBER_INPUT_ID);
            } else if (res.message.indexOf('Birthdate') > -1) {
              setError(DATE_OF_BIRTH_INPUT_ID, {
                message: res.message.replace('YYYY-MM-DD', 'MM/DD/YYYY'),
              });
              scrollTo(DATE_OF_BIRTH_INPUT_ID);
            }
          }
        }
      });
  };

  const onError = async (error: any) => {
    console.log('error: ', error);
    if (!(error.terms_conditions || error.privacy_policy)) {
      scrollTo(FIRST_NAME_INPUT_ID);
    }
  };

  const validateAge = () => {
    const birthdateItems = getValues(DATE_OF_BIRTH_INPUT_ID).split('/');
    const yearAsANumber = parseInt(birthdateItems[2]);
    if (typeof yearAsANumber === 'number' && yearAsANumber > 999) {
      return validateMyAge(
        birthdateItems[2],
        birthdateItems[1],
        birthdateItems[0]
      )
        ? true
        : AGE_BIRTHDATE_VALIDATION_ERROR;
    }
    return true;
  };

  const scrollTo = (name: string) => {
    window.scrollTo({
      //@ts-ignore
      top: document.getElementsByClassName(`${name}-field`)[0].offsetTop,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (displaySuccessfulMessage) {
      setTimeout(() => {
        setDisplaySuccessfulMessage(false);
      }, 5000);
    }
  }, [displaySuccessfulMessage]);

  useEffect(() => {
    if (user && user.profile) {
      console.log('profile: ', user.profile);
      const userProfile: any = user.profile;
      let currentMailingAddress = ``;
      if (userProfile.mailing_street_number) {
        currentMailingAddress += userProfile.mailing_street_number;
      }
      if (userProfile.mailing_street_name) {
        currentMailingAddress += ` ${userProfile.mailing_street_name}`;
      }
      if (userProfile.mailing_suburb) {
        currentMailingAddress += `, ${userProfile.mailing_suburb}`;
      }
      if (userProfile.mailing_state) {
        currentMailingAddress += `, ${userProfile.mailing_state}`;
      }
      if (userProfile.mailing_postal_code) {
        currentMailingAddress += ` ${userProfile.mailing_postal_code}`;
      }
      console.log('currentMailingAddress: ', currentMailingAddress);
      setUserMailingAddress(currentMailingAddress);
    }
  }, [user]);

  return (
    <ProfileFormStyled className='profile-form'>
      <ProfileFormContentStyled>
        <h1>{PAGE_TITLE}</h1>
        <ProfileFormFieldsStyled>
          <h3>Personal Details</h3>
          <p>* Mandatory fields</p>
          {
            //@ts-ignore
            <form onSubmit={handleSubmit(onSubmit, onError)} autoComplete='off'>
              <input name='email' class='visually-hidden' type='text' />
              <SelectField
                //@ts-ignore
                defaultValue={user.profile?.marital_status}
                placeholder='Title'
                control={control}
                name={TITLE_INPUT_ID}
                options={[
                  { label: 'Mr', value: 'Mr' },
                  { label: 'Mrs', value: 'Mrs' },
                  { label: 'Ms', value: 'Ms' },
                ]}
                rules={{
                  required: {
                    value: false,
                    message: DEAFULT_ERROR_MESSAGE,
                  },
                }}
              ></SelectField>
              <TextField
                name={FIRST_NAME_INPUT_ID}
                defaultValue={user.profile?.first_name}
                placeholder={''}
                label={'First Name'}
                control={control}
                error={errors[FIRST_NAME_INPUT_ID]}
                onFocus={onFocusInput.bind(this, FIRST_NAME_INPUT_ID)}
                rules={{
                  required: {
                    value: true,
                    message: DEAFULT_ERROR_MESSAGE,
                  },
                }}
              />
              <TextField
                name={LAST_NAME_INPUT_ID}
                defaultValue={user.profile?.last_name}
                placeholder={''}
                label={'Last Name'}
                control={control}
                error={errors[LAST_NAME_INPUT_ID]}
                onFocus={onFocusInput.bind(this, LAST_NAME_INPUT_ID)}
                rules={{
                  required: {
                    value: true,
                    message: DEAFULT_ERROR_MESSAGE,
                  },
                }}
              />

              <SelectField
                placeholder='Gender'
                //@ts-ignore
                defaultValue={user.profile.gender}
                control={control}
                name={GENDER_INPUT_ID}
                options={Object.keys(SIGNUP_GENDERS).map((key) => ({
                  value: key,
                  label: SIGNUP_GENDERS[key],
                }))}
                rules={{
                  required: {
                    value: false,
                    message: DEAFULT_ERROR_MESSAGE,
                  },
                }}
              ></SelectField>

              <TextField
                name={DATE_OF_BIRTH_INPUT_ID}
                defaultValue={parseDateToDisplayOnTheUI(
                  user.profile?.birthdate || ''
                )}
                placeholder={'DD/MM/YYYY'}
                label={'Date of Birth'}
                control={control}
                error={errors[DATE_OF_BIRTH_INPUT_ID]}
                onFocus={onFocusInput.bind(this, DATE_OF_BIRTH_INPUT_ID)}
                rules={{
                  required: {
                    value: false,
                    message: DEAFULT_ERROR_MESSAGE,
                  },
                  validate: validateAge,
                }}
                mask={BIRTHDATE_MASK}
              />

              <AddressField
                label={'Mailing Address'}
                defaultValue={userMailingAddress}
                placeholder={''}
                control={control}
                error={errors[MAILING_ADDRESS_INPUT_ID]}
                setValidationMessage={setAddressMessage}
                onFocus={onFocusInput.bind(this, MAILING_ADDRESS_INPUT_ID)}
                onSelect={(value: any) =>
                  setValueManually(MAILING_ADDRESS_INPUT_ID, value)
                }
                {...register(MAILING_ADDRESS_INPUT_ID, {
                  validate: () => {
                    console.log('Validate called');
                    return addressMessage;
                  },
                })}
              />

              <TextField
                name={EMAIL_INPUT_ID}
                defaultValue={user.profile?.email}
                placeholder={''}
                label={'Email Address'}
                control={control}
                error={errors[EMAIL_INPUT_ID]}
                onFocus={onFocusInput.bind(this, EMAIL_INPUT_ID)}
                rules={{
                  required: {
                    value: true,
                    message: DEAFULT_ERROR_MESSAGE,
                  },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: EMAIL_FORMAT_ERROR,
                  },
                }}
              />

              <TextField
                name={MOBILENUMBER_INPUT_ID}
                defaultValue={user.profile?.mobile_phone}
                placeholder={PHONENUMBER_PLACEHOLDER}
                label={'Mobile Number'}
                control={control}
                error={errors[MOBILENUMBER_INPUT_ID]}
                onFocus={onFocusInput.bind(this, MOBILENUMBER_INPUT_ID)}
                rules={{
                  required: {
                    value: false,
                    message: DEAFULT_ERROR_MESSAGE,
                  },
                }}
                mask={PHONENUMBER_MASK}
              />

              <CommunicationsPreferencesStyle>
                <h3>Communications Preferences</h3>
                <p>
                  Most Living Rewards communications are via email. You can
                  change your communication preferences at any time via the My
                  Profile page on your Living Rewards account.
                </p>
                <p>
                  How would you like to receive marketing communications from Living
                  Rewards?
                </p>

                <div className='communication-pref-checks'>
                  <CheckboxField
                    //@ts-ignore
                    defaultValue={!user.profile[COMMUNICATION_PREF_EMAIL_ID]}
                    control={control}
                    id={COMMUNICATION_PREF_EMAIL_ID}
                    name={COMMUNICATION_PREF_EMAIL_ID}
                    //@ts-ignore
                    checked={!user.profile[COMMUNICATION_PREF_EMAIL_ID]}
                    label={'Email'}
                    rules={{ required: false }}
                  ></CheckboxField>

                  <CheckboxField
                    //@ts-ignore
                    defaultValue={!user.profile[COMMUNICATION_PREF_MAIL_ID]}
                    control={control}
                    id={COMMUNICATION_PREF_MAIL_ID}
                    name={COMMUNICATION_PREF_MAIL_ID}
                    //@ts-ignore
                    checked={!user.profile[COMMUNICATION_PREF_MAIL_ID]}
                    label={'Mail'}
                    rules={{ required: false }}
                  ></CheckboxField>
                  <CheckboxField
                    //@ts-ignore
                    defaultValue={!user.profile[COMMUNICATION_PREF_TEXT_ID]}
                    control={control}
                    id={COMMUNICATION_PREF_TEXT_ID}
                    name={COMMUNICATION_PREF_TEXT_ID}
                    //@ts-ignore
                    checked={!user.profile[COMMUNICATION_PREF_TEXT_ID]}
                    label={'Text'}
                    rules={{ required: false }}
                  ></CheckboxField>
                  <CheckboxField
                    //@ts-ignore
                    defaultValue={!user.profile[COMMUNICATION_PREF_PHONE_ID]}
                    control={control}
                    id={COMMUNICATION_PREF_PHONE_ID}
                    name={COMMUNICATION_PREF_PHONE_ID}
                    //@ts-ignore
                    checked={!user.profile[COMMUNICATION_PREF_PHONE_ID]}
                    label={'Phone'}
                    rules={{ required: false }}
                  ></CheckboxField>
                </div>
              </CommunicationsPreferencesStyle>

              <Button
                disabled={isProcessingRequest}
                variant={ButtonVariant.PRIMARY}
                type={ButtonType.SUBMIT}
              >
                SAVE
              </Button>

              {displaySuccessfulMessage && (
                <p className='message'>{SUCCESSFUL_MESSAGE}</p>
              )}
            </form>
          }
        </ProfileFormFieldsStyled>
      </ProfileFormContentStyled>
      <OverlayStyled></OverlayStyled>
    </ProfileFormStyled>
  );
};
