import { Heading1 } from '@greencross/overrides/design-system/style-guide/typography';
import { getImageSrc, route } from '@authentic/loyalty-pwa/util';
import { BackgroundImage } from '@greencross/components/background-image/background-image.component';
import { BaseLayout } from '@greencross/pages/shared/base-layout/base-layout';
import { FunctionalComponent, h } from 'preact';
import {
  MEMBER_EXCLUSIVES_DESCRIPTION,
  MEMBER_EXCLUSIVES_PAGE_TITLE,
  REGEX_TO_REMOVE_HTML_TAGS,
  REGEX_TO_VALID_URLS,
} from './member-exclusives.strings';
import {
  MemberExclusivesContentDetailStyled,
  MemberExclusivesDetailStyled,
  MemberExclusivesLayoutStyled,
  MemberExclusivesPageContentStyled,
  MemberExclusivesPageStyled,
} from './member-exclusives.style';
import { OverlayStyled } from '../../unauthenticated/sign-up/form/sign-up-form.style';
import { SliderStyled } from '../../unauthenticated/home/home.style';
import { NavSlider } from '@authentic/loyalty-pwa/design-system/components/base/nav-slider/nav-slider';
import { SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore from 'swiper';
import { SliderImage } from '@greencross/components/slider-image/slider-image.component';
import { useContext, useEffect, useState } from 'preact/hooks';
import { useUser } from '@authentic/loyalty-pwa/providers';
import { ContentBlockServiceContext } from '@authentic/loyalty-pwa/providers/content-block.service.context';

interface MemberExclusivesProps {}
export const MemberExclusivesPage: FunctionalComponent<
  MemberExclusivesProps
> = (props) => {
  const ContentBlockService = useContext(ContentBlockServiceContext);
  const [images, setImages] = useState<{ path: string; url: string }[]>([]);
  const user = useUser();
  const onSwiperSlideClick = (swiper: SwiperCore) => {
    if (
      swiper.activeIndex >= 0 &&
      images[swiper.activeIndex] &&
      images[swiper.activeIndex].url
    ) {
      if (images[swiper.activeIndex].url.indexOf('http') > -1) {
        window.open(images[swiper.activeIndex].url, '_blank');
      } else {
        route(images[swiper.activeIndex].url);
      }
    }
  };

  const getContentBlockInfo = () => {
    ContentBlockService?.getContentBlock().then((res: any) => {
      if (res.success) {
        let contentBlocks = res.data.content_blocks;
        let contentBlocksFiltered = contentBlocks.filter(
          (item: { subject: string; original_image_url: string }) =>
            item.subject === 'Member_Exclusive' && {
              image: item.original_image_url,
            }
        );
        console.log('contentBlocksFiltered: ', contentBlocksFiltered);
        setImages(
          contentBlocksFiltered.map(
            (item: {
              subject: string;
              original_image_url: string;
              details: string;
            }) => {
              const detailsField = item.details;
              const basicText = detailsField.replace(REGEX_TO_REMOVE_HTML_TAGS, '');
              const validURLs = basicText.match(REGEX_TO_VALID_URLS) || [];
              return {
                path: item.original_image_url,
                url: validURLs.length > 0 ? validURLs[0] : null,
              };
            }
          )
        );
      }
    });
  };

  useEffect(() => {
    if (user) {
      getContentBlockInfo();
    }
  }, [user]);
  return (
    <MemberExclusivesPageStyled className='member-exclusives-page'>
      <BaseLayout>
        <MemberExclusivesPageContentStyled>
          {
            <BackgroundImage
              desktop={`url(${getImageSrc(
                '/assets/images/backgrounds/background-memberexclusive-desktop.jpg'
              )})`}
              mobile={`url(${getImageSrc(
                '/assets/images/backgrounds/background-memberexclusive-mobile.jpg'
              )})`}
            ></BackgroundImage>
          }
          <MemberExclusivesDetailStyled>
            <MemberExclusivesContentDetailStyled>
              <MemberExclusivesLayoutStyled>
                <Heading1>{MEMBER_EXCLUSIVES_PAGE_TITLE}</Heading1>
                <p>{MEMBER_EXCLUSIVES_DESCRIPTION}</p>

                {/* <SliderStyled>
                  <NavSlider
                    navigation={true}
                    slidesPerView={1}
                    spaceBetween={0}
                    onTap={onSwiperSlideClick}
                    pagination={{
                      clickable: true,
                    }}
                    autoplay={{
                      delay: 6000,
                      disableOnInteraction: false,
                    }}
                  >
                    {images &&
                      images.map((item, index) => (
                        <SwiperSlide>
                          <SliderImage image={item.path}></SliderImage>
                        </SwiperSlide>
                      ))}
                  </NavSlider>
                </SliderStyled> */}
              </MemberExclusivesLayoutStyled>
              <OverlayStyled></OverlayStyled>
            </MemberExclusivesContentDetailStyled>
          </MemberExclusivesDetailStyled>
        </MemberExclusivesPageContentStyled>
      </BaseLayout>
    </MemberExclusivesPageStyled>
  );
};
