import {FunctionalComponent, h} from "preact";
import {ButtonStyled} from "@greencross/overrides/design-system/components/base/button/button.style";
import {ButtonHTMLAttributes} from "react";

export enum ButtonType {
    BUTTON = 'button',
    SUBMIT = 'submit',
    RESET = 'reset',
}

export enum ButtonVariant {
    PRIMARY = 'PrimaryButtonConfig',
    SECONDARY = 'SecondaryButtonConfig',
    PRIMARY_OUTLINE = 'PrimaryOutlineButtonConfig',
    SECONDARY_OUTLINE = 'SecondaryOutlineButtonConfig',
    PRIMARY_LINK = 'PrimaryLinkButtonConfig',
    SECONDARY_LINK = 'SecondaryLinkButtonConfig',
    PRIMARY_SMALL = 'PrimarySmallButtonConfig',
    SECONDARY_SMALL = 'SecondarySmallButtonConfig',
    PRIMARY_OUTLINE_SMALL = 'PrimaryOutlineSmallButtonConfig',
    SECONDARY_OUTLINE_SMALL = 'SecondaryOutlineSmallButtonConfig',
    PRIMARY_LINK_SMALL = 'PrimaryLinkSmallButtonConfig',
    SECONDARY_LINK_SMALL = 'SecondaryLinkSmallButtonConfig',
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    ariaLabel?: string;
    variant: ButtonVariant;
};

export const Button: FunctionalComponent<ButtonProps> = ({ children, ariaLabel, type: buttonType, variant, ...props}) => {
    return (
        <ButtonStyled
            {...props}
            aria-label={ariaLabel}
            role={'button'}
            type={!buttonType ? ButtonType.BUTTON : buttonType}
            buttonType={variant?.toString()}
        >
            {children}
        </ButtonStyled>
    );
};