import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html, body {
    color: ${colors.text};
  }
  a {
    color: ${colors.orange};
    cursor: pointer;
    font-family: 'OpenSans-Bold';
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  input[type="checkbox"]:checked {
    background: ${colors.orange};
    color: ${colors.white};
  }

  input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: lightgray;
    height: 22px;
    width: 22px;
    border: 1px solid ${colors.black} !important;
    color: ${colors.white};
  }

  input[type="checkbox"]:after {
    content: ' ';
    position: relative;
    left: 35%;
    top: 6%;
    width: 23%;
    height: 57%;
    border: solid ${colors.white};
    border-width: 0 2px 2px 0;
    transform: rotate(46deg);
    display: none;
  }

  input[type="checkbox"]:checked:after {
    display: block;
  }
`;
