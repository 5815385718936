import {
	FavoriteLocationResult,
	OffersResponse,
	ProfileResult, RewardsResponse,
	SummaryResult,
} from '@authentic/loyalty-pwa/interfaces';
import { Events, EventsService } from "@authentic/loyalty-pwa/services";

export class UserData {
    private _appdata?: Record<string, any>
    private _token?: {
        access_token: string,
        token_type: string,
        expires_in: number,
        created_at: number,
        refresh_token: string
    }
    private _summary?: SummaryResult | null;
    private _profile?: ProfileResult | null;
    private _offers?: OffersResponse | null;
    private _rewards?: RewardsResponse | null;
    private _mobile_rewards?: RewardsResponse | null;
    private _favorite_location?: FavoriteLocationResult | null ;
    private _redirect?: string | false;
    private _redirect_after_login?: string | false;

    public get appdata() { return this._appdata }
    public get authenticated() { return !!this.access_token }
    public get access_token() { return this._token?.access_token; }
    public get token() { return this._token }
    public get summary() { return this._summary }
    public get profile() { return this._profile }
	public get offers() { return this._offers}
	public get mobile_rewards() {return this._mobile_rewards}
	public get rewards() { return this._rewards}
    public get favorite_location() { return this._favorite_location }
    public get redirect() {
        // For convenience, we first check if redirect_after_login is set and return that if the user has logged in
        if (this._redirect_after_login && this.authenticated)
            return this._redirect_after_login
        return this._redirect
    }
    public get redirect_after_login() { return this._redirect_after_login }

    public get isExpired() {
        return (!this._token || !this._token.access_token)
            || ((Date.now() / 1000) > (this._token.created_at + this._token.expires_in))
    }

    constructor(data?: Partial<UserData>) {
        if (!data)
            data = {}

        Object.entries(data).forEach(([key, value]) => {
            let privateKey = `_${key}`
            if (key.startsWith('_'))
                privateKey = key

            /* this line will always work, since key must be a property of UserData, and _key is
            /*   always going to be valid. typescript doesn't understand it though so... */
            /* @ts-ignore */
            this[privateKey] = value
        })
    }

    public summaryReset() {
        this._summary = null;
        setTimeout(()=> {
            EventsService.emit(Events.USER_CHANGED, this);
        }, 1000);
    }
}
