export const PHONENUMBER_MASK = '(+64) 99999999999';
export const PHONENUMBER_PLACEHOLDER = '(+64)            ';
export const DEAFULT_ERROR_MESSAGE = 'This field is required';
export const BIRTHDATE_MASK = '99/99/9999';
export const EMAIL_FORMAT_ERROR = 'Entered value does not match email format';
export const AGE_BIRTHDATE_VALIDATION_ERROR =
  'The user must be 13 years old and over';
export const PAGE_TITLE = 'CONTACT US';
export const PHONENUMBER_ERROR_MESSAGE =
  'Mobile Phone is not valid, only (+64) New Zealand numbers are allowed';
