import { FunctionalComponent, h } from 'preact';
import { ErrorStyled } from '@authentic/loyalty-pwa/design-system/components/base/text-field/text-field.style';
import {
  CommunicationsPreferencesStyle,
  OverlayStyled,
  SignupFormContentStyled,
  SignupFormFieldsStyled,
  SignupFormStyled,
  TermsAndConditionsStyle,
} from './sign-up-form.style';
import {
  TextField,
  TextFieldType,
} from '@authentic/loyalty-pwa/design-system/components/base/text-field/text-field.component';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  BIRTHDATE_MASK,
  DEFAULT_ERROR_MESSAGE,
  EMAIL_FORMAT_ERROR,
  PASSWORD_DISCLAIMER,
  PHONENUMBER_MASK,
  PHONENUMBER_PLACEHOLDER,
} from './sign-up-form.config';
import { SelectField } from '@greencross/components/select-field/select-field.component';
import { useEffect, useRef, useState } from 'preact/hooks';
import { AddressField } from '@greencross/components/address-field/address-field.component';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  FormValues,
  TITLE_INPUT_ID,
  FIRST_NAME_INPUT_ID,
  LAST_NAME_INPUT_ID,
  DATE_OF_BIRTH_INPUT_ID,
  GENDER_INPUT_ID,
  MAILING_ADDRESS_INPUT_ID,
  EMAIL_INPUT_ID,
  CONFIRM_EMAIL_INPUT_ID,
  MOBILENUMBER_INPUT_ID,
  PASSWORD_INPUT_ID,
  CONFIRMPASSWORD_INPUT_ID,
  TERMS_CONDITIONS_CHECKBOX_ID,
  PRIVACY_POLICY_CHECKBOX_ID,
  RECAPTCHA_ID,
  COMMUNICATION_PREF_EMAIL_ID,
  COMMUNICATION_PREF_MAIL_ID,
  COMMUNICATION_PREF_TEXT_ID,
  COMMUNICATION_PREF_PHONE_ID,
  SIGNUP_RULES,
  SIGNUP_TITLES,
  SIGNUP_GENDERS,
} from './sign-up-form.constants';
import {
  Button,
  ButtonType,
  ButtonVariant,
} from '@authentic/loyalty-pwa/design-system/components/base/button/button.component';
import { TERMS_AND_CONDITIONS_URL } from '../../terms-and-conditions/terms-and-conditions.strings';
import { Link } from 'preact-router';
import { route } from '@authentic/loyalty-pwa/util';
import { VERIFICATION_EMAIL_URL } from '../../verification-email/verification-email.strings';
import { PRIVACY_POLICY_URL } from '@greencross/pages/unauthenticated/privacy-policy/privacy-policy.strings';
import { CheckboxField } from '@greencross/components/checkbox/checkbox.component';
import { PasswordStrength } from '@greencross/components/password-strength/password-strength.component';
import { cleanString } from '@greencross/utils/format-phone-number';
import { SUCCESSFUL_SIGNUP_PAGE_URL } from '../success/success-signup.strings';

type OptionalDataType = {
  mobile_phone?: string;
  birthdate?: string;
  mailing_address?: string;
  marital_status?: string;
  gender?: string;
};

interface SignupFormProps {}
export const SignupForm: FunctionalComponent<SignupFormProps> = () => {
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    getValues,
    setError,
    register,
    watch,
    setValue: setValueManually,
  } = useForm();
  const password = watch('password');
  const [addressMessage, setAddressMessage] = useState<string | true>(true);

  useEffect(() => {
    register('recaptcha', {
      required: DEFAULT_ERROR_MESSAGE,
    });
  }, []);

  useEffect(() => {
    if (addressMessage) {
      console.log('Got addressMessage', addressMessage);
    }
  }, [addressMessage]);

  const onFocusInput = (name: string, e: any) => {
    console.log('onInputFocus: ', e.target.value, name);
    document
      .getElementsByClassName(`${name}-field`)[0]
      .getElementsByTagName('label')[0]
      .classList.add('active');
  };

  const onSubmit: SubmitHandler<any> = (data: FormValues) => {
    console.log('data: ', data);
    let optionalData: OptionalDataType = {};

    if (data[MOBILENUMBER_INPUT_ID]) {
      optionalData.mobile_phone = cleanString(data[MOBILENUMBER_INPUT_ID]);
    }

    if (data[DATE_OF_BIRTH_INPUT_ID]) {
      optionalData.birthdate = parseBirthDateToSendAPI(
        data[DATE_OF_BIRTH_INPUT_ID]
      );
    }

    if (data[MAILING_ADDRESS_INPUT_ID]) {
      optionalData[MAILING_ADDRESS_INPUT_ID] = data[MAILING_ADDRESS_INPUT_ID];
    }

    if (data[TITLE_INPUT_ID]) {
      optionalData[TITLE_INPUT_ID] = data[TITLE_INPUT_ID];
    }

    if (data[GENDER_INPUT_ID]) {
      optionalData.gender = data[GENDER_INPUT_ID];
    }

    fetch('/api/signup', {
      method: 'POST',
      body: JSON.stringify({
        email: data[EMAIL_INPUT_ID],
        password: data[PASSWORD_INPUT_ID],
        password_confirmation: data[CONFIRMPASSWORD_INPUT_ID],
        first_name: data[FIRST_NAME_INPUT_ID],
        last_name: data[LAST_NAME_INPUT_ID],
        recaptcha: data[RECAPTCHA_ID],
        suspend_email: !data[COMMUNICATION_PREF_EMAIL_ID],
        mail_opt_out: !data[COMMUNICATION_PREF_MAIL_ID],
        suspend_sms: !data[COMMUNICATION_PREF_TEXT_ID],
        phone_opt_out: !data[COMMUNICATION_PREF_PHONE_ID],
        /*receive_personalized_offers: data[COMMUNICATION_PREF_EMAIL_ID],
        receive_mail_offers: data[COMMUNICATION_PREF_MAIL_ID],
        receive_sms_offers: data[COMMUNICATION_PREF_TEXT_ID],
        receive_mobile_app_offers: data[COMMUNICATION_PREF_PHONE_ID],*/
        ...optionalData,
      }),
    })
      .then((res) => res.json())
      .then((res: any) => {
        console.log('Response', res);
        if (res.success) {
          formReset();
          //route(`${VERIFICATION_EMAIL_URL}?email=${data[EMAIL_INPUT_ID]}`);
          route(SUCCESSFUL_SIGNUP_PAGE_URL);
        } else {
          if (res.errors && res.errors.mailingaddress) {
            console.log('Mailing Address message: ', res.errors.mailingaddress);
            setError(MAILING_ADDRESS_INPUT_ID, {
              message: res.errors.mailingaddress,
            });
            scrollTo(MAILING_ADDRESS_INPUT_ID);
          } else {
            if (reCaptchaRef && reCaptchaRef.current)
              (reCaptchaRef.current as any).reset();
            if (res.message.indexOf('Email') > -1) {
              setError(EMAIL_INPUT_ID, { message: res.message });
              scrollTo(EMAIL_INPUT_ID);
            } else if (res.message.indexOf('Password') > -1) {
              setError(PASSWORD_INPUT_ID, { message: res.message });
              scrollTo(PASSWORD_INPUT_ID);
            } else if (res.message.indexOf('Mobile') > -1) {
              setError(MOBILENUMBER_INPUT_ID, { message: res.message });
              scrollTo(MOBILENUMBER_INPUT_ID);
            } else if (res.message.indexOf('ReCAPTCHA') > -1) {
              setError(RECAPTCHA_ID, { message: res.message });
              scrollTo(RECAPTCHA_ID);
            } else if (res.message.indexOf('Birthdate') > -1) {
              setError(DATE_OF_BIRTH_INPUT_ID, {
                message: res.message.replace('YYYY-MM-DD', 'MM/DD/YYYY'),
              });
              scrollTo(DATE_OF_BIRTH_INPUT_ID);
            }
          }
        }
      });
  };

  const onError = (error: any) => {
    console.log('error: ', error);

    if (!(error.terms_conditions || error.privacy_policy)) {
      scrollTo(FIRST_NAME_INPUT_ID);
    }
  };

  const titleSelectorFieldRef = (element: any, e: any) => {
    console.log('titleSelectorFieldRef: ', element, e);
  };

  // parse from DD/MM/YYYY to YYYY-MM-DD
  const parseBirthDateToSendAPI = (birthdate: string) => {
    const dateItems = birthdate.split('/');
    return `${dateItems[2]}-${dateItems[1]}-${dateItems[0]}`;
  };

  const scrollTo = (name: string) => {
    window.scrollTo({
      //@ts-ignore
      top: document.getElementsByClassName(`${name}-field`)[0].offsetTop,
      behavior: 'smooth',
    });
  };

  const onRecpatchaChange = (value: string | null) =>
    setValueManually(RECAPTCHA_ID, value);

  const formReset = () => {
    reset({
      [TITLE_INPUT_ID]: '',
      [FIRST_NAME_INPUT_ID]: '',
      [LAST_NAME_INPUT_ID]: '',
      [DATE_OF_BIRTH_INPUT_ID]: '',
      [GENDER_INPUT_ID]: '',
      [MAILING_ADDRESS_INPUT_ID]: '',
      [EMAIL_INPUT_ID]: '',
      [CONFIRM_EMAIL_INPUT_ID]: '',
      [MOBILENUMBER_INPUT_ID]: '',
      [PASSWORD_INPUT_ID]: '',
      [CONFIRMPASSWORD_INPUT_ID]: '',
    });
  };

  return (
    <SignupFormStyled className='signup-form'>
      <SignupFormContentStyled>
        <h1>JOIN</h1>
        {/*<Disclaimer>
          <p>{DISCLAIMER_DESCRIPTION}</p>
          <a href=''>{DISCLAIMER_LINK}</a>
        </Disclaimer>*/}

        <SignupFormFieldsStyled>
          <h3>Personal Details</h3>
          <p>* Mandatory fields</p>
          {
            //@ts-ignore
            <form onSubmit={handleSubmit(onSubmit, onError)} autoComplete='off'>
              <input name='email' class='visually-hidden' type='text' />
              <input
                name='fake_password'
                class='visually-hidden'
                type='password'
              />
              <SelectField
                placeholder='Title'
                control={control}
                name={TITLE_INPUT_ID}
                options={SIGNUP_TITLES.map((val) => ({
                  label: val,
                  value: val,
                }))}
                rules={SIGNUP_RULES[TITLE_INPUT_ID]}
              ></SelectField>
              <TextField
                name={FIRST_NAME_INPUT_ID}
                defaultValue={''}
                placeholder={''}
                label={'First Name'}
                control={control}
                error={errors[FIRST_NAME_INPUT_ID]}
                onFocus={onFocusInput.bind(this, FIRST_NAME_INPUT_ID)}
                rules={SIGNUP_RULES[FIRST_NAME_INPUT_ID]}
              />
              <TextField
                name={LAST_NAME_INPUT_ID}
                defaultValue={''}
                placeholder={''}
                label={'Last Name'}
                control={control}
                error={errors[LAST_NAME_INPUT_ID]}
                onFocus={onFocusInput.bind(this, LAST_NAME_INPUT_ID)}
                rules={SIGNUP_RULES[LAST_NAME_INPUT_ID]}
              />

              <SelectField
                placeholder='Gender'
                control={control}
                name={GENDER_INPUT_ID}
                options={Object.keys(SIGNUP_GENDERS).map((key) => ({
                  value: key,
                  label: SIGNUP_GENDERS[key],
                }))}
                rules={SIGNUP_RULES[GENDER_INPUT_ID]}
              ></SelectField>

              <TextField
                name={DATE_OF_BIRTH_INPUT_ID}
                defaultValue={''}
                placeholder={'DD/MM/YYYY'}
                label={'Date of Birth'}
                control={control}
                error={errors[DATE_OF_BIRTH_INPUT_ID]}
                onFocus={onFocusInput.bind(this, DATE_OF_BIRTH_INPUT_ID)}
                rules={SIGNUP_RULES[DATE_OF_BIRTH_INPUT_ID]}
                mask={BIRTHDATE_MASK}
              />

              <AddressField
                label={'Mailing Address'}
                required={false}
                defaultValue={''}
                placeholder={''}
                control={control}
                error={errors[MAILING_ADDRESS_INPUT_ID]}
                setValidationMessage={setAddressMessage}
                onFocus={onFocusInput.bind(this, MAILING_ADDRESS_INPUT_ID)}
                onSelect={(value: any) => {
                  setError(MAILING_ADDRESS_INPUT_ID, { message: '' });
                  setValueManually(MAILING_ADDRESS_INPUT_ID, value);
                }}
                {...register(MAILING_ADDRESS_INPUT_ID, {
                  required: {
                    value: false,
                    message: DEFAULT_ERROR_MESSAGE,
                  },
                  validate: () => {
                    console.log('Validate called');
                    return addressMessage;
                  },
                })}
              />

              <TextField
                name={EMAIL_INPUT_ID}
                defaultValue={''}
                placeholder={''}
                label={'Email'}
                control={control}
                error={errors[EMAIL_INPUT_ID]}
                onFocus={onFocusInput.bind(this, EMAIL_INPUT_ID)}
                rules={SIGNUP_RULES[EMAIL_INPUT_ID]}
              />

              <TextField
                name={CONFIRM_EMAIL_INPUT_ID}
                defaultValue={''}
                placeholder={''}
                label={'Confirm Email'}
                control={control}
                error={errors[CONFIRM_EMAIL_INPUT_ID]}
                onFocus={onFocusInput.bind(this, CONFIRM_EMAIL_INPUT_ID)}
                rules={{
                  required: {
                    value: true,
                    message: DEFAULT_ERROR_MESSAGE,
                  },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: EMAIL_FORMAT_ERROR,
                  },
                }}
              />

              <TextField
                name={MOBILENUMBER_INPUT_ID}
                defaultValue={''}
                placeholder={PHONENUMBER_PLACEHOLDER}
                label={'Mobile Number'}
                control={control}
                error={errors[MOBILENUMBER_INPUT_ID]}
                onFocus={onFocusInput.bind(this, MOBILENUMBER_INPUT_ID)}
                rules={SIGNUP_RULES[MOBILENUMBER_INPUT_ID]}
                mask={PHONENUMBER_MASK}
              />

              <TextField
                name={PASSWORD_INPUT_ID}
                type={TextFieldType.PASSWORD}
                variant={TextFieldType.PASSWORD}
                label={'Password'}
                control={control}
                error={errors[PASSWORD_INPUT_ID]}
                description={<PasswordStrength password={password} />}
                onFocus={onFocusInput.bind(this, PASSWORD_INPUT_ID)}
                rules={SIGNUP_RULES[PASSWORD_INPUT_ID]}
              />

              <TextField
                name={CONFIRMPASSWORD_INPUT_ID}
                type={TextFieldType.PASSWORD}
                variant={TextFieldType.PASSWORD}
                label={'Confirm Password'}
                control={control}
                error={errors[CONFIRMPASSWORD_INPUT_ID]}
                onFocus={onFocusInput.bind(this, CONFIRMPASSWORD_INPUT_ID)}
                rules={{
                  required: {
                    value: true,
                    message: DEFAULT_ERROR_MESSAGE,
                  },
                  validate: (value) =>
                    value === getValues('password') || 'Passwords do not match',
                }}
              />
              <p
                class='password-disclaimer'
                dangerouslySetInnerHTML={{ __html: PASSWORD_DISCLAIMER }}
              ></p>

              <CommunicationsPreferencesStyle>
                <h3>Communications Preferences</h3>
                <p>
                  Most Living Rewards communications are via email. You can
                  change your communication preferences at any time via the My
                  Profile page on your Living Rewards account.
                </p>
                <p>
                  How would you like to receive marketing communications from
                  Living Rewards?
                </p>

                <div className='communication-pref-checks'>
                  <CheckboxField
                    defaultValue={true}
                    control={control}
                    id={COMMUNICATION_PREF_EMAIL_ID}
                    name={COMMUNICATION_PREF_EMAIL_ID}
                    checked={false}
                    label={'Email'}
                    rules={{}}
                  ></CheckboxField>

                  <CheckboxField
                    defaultValue={true}
                    control={control}
                    id={COMMUNICATION_PREF_MAIL_ID}
                    name={COMMUNICATION_PREF_MAIL_ID}
                    checked={false}
                    label={'Mail'}
                    rules={{}}
                  ></CheckboxField>
                  <CheckboxField
                    defaultValue={true}
                    control={control}
                    id={COMMUNICATION_PREF_TEXT_ID}
                    name={COMMUNICATION_PREF_TEXT_ID}
                    checked={false}
                    label={'Text'}
                    rules={{}}
                  ></CheckboxField>
                  <CheckboxField
                    defaultValue={true}
                    control={control}
                    id={COMMUNICATION_PREF_PHONE_ID}
                    name={COMMUNICATION_PREF_PHONE_ID}
                    checked={false}
                    label={'Phone'}
                    rules={{}}
                  ></CheckboxField>
                </div>
              </CommunicationsPreferencesStyle>

              <TermsAndConditionsStyle className='terms-conditions'>
                <h3>Terms and Conditions</h3>
                <label htmlFor={TERMS_CONDITIONS_CHECKBOX_ID}>
                  <input
                    type='checkbox'
                    id={TERMS_CONDITIONS_CHECKBOX_ID}
                    {...register(TERMS_CONDITIONS_CHECKBOX_ID, {
                      required: {
                        value: true,
                        message: DEFAULT_ERROR_MESSAGE,
                      },
                    })}
                  />
                  <p>
                    I agree to the{' '}
                    <Link target='_blank' href={TERMS_AND_CONDITIONS_URL}>
                      Living Rewards Terms & Conditions
                    </Link>
                    . Please tick to agree our Terms and conditions
                    {errors[TERMS_CONDITIONS_CHECKBOX_ID] && (
                      <ErrorStyled>
                        {errors[TERMS_CONDITIONS_CHECKBOX_ID]['message']}
                      </ErrorStyled>
                    )}
                  </p>
                </label>
                <label htmlFor={PRIVACY_POLICY_CHECKBOX_ID}>
                  <input
                    type='checkbox'
                    id={PRIVACY_POLICY_CHECKBOX_ID}
                    {...register(PRIVACY_POLICY_CHECKBOX_ID, {
                      required: {
                        value: true,
                        message: DEFAULT_ERROR_MESSAGE,
                      },
                    })}
                  />
                  <p>
                    I have read and accept the{' '}
                    <Link target='_blank' href={PRIVACY_POLICY_URL}>
                      Green Cross Health Privacy Policy
                    </Link>
                    . Please read and tick to agree our Green Cross Health and
                    Privacy Policy
                    {errors[PRIVACY_POLICY_CHECKBOX_ID] && (
                      <ErrorStyled>
                        {errors[PRIVACY_POLICY_CHECKBOX_ID]['message']}
                      </ErrorStyled>
                    )}
                  </p>
                </label>
              </TermsAndConditionsStyle>

              <ReCAPTCHA
                sitekey={process.env.RECAPTCHA_SITE_KEY || ''}
                onChange={onRecpatchaChange}
                className={`${RECAPTCHA_ID}-field`}
                //@ts-ignore the types for recaptcha doesn't include ref though ref works
                ref={reCaptchaRef}
              />
              {errors[RECAPTCHA_ID] && (
                <ErrorStyled>{errors[RECAPTCHA_ID].message}</ErrorStyled>
              )}

              <Button variant={ButtonVariant.PRIMARY} type={ButtonType.SUBMIT}>
                JOIN LIVING REWARDS
              </Button>
            </form>
          }
        </SignupFormFieldsStyled>
      </SignupFormContentStyled>
      <OverlayStyled></OverlayStyled>
    </SignupFormStyled>
  );
};
