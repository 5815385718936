import { h, Fragment, ComponentChildren } from 'preact';
import { LocationServiceInterface, OfferServiceInterface, RewardServiceInterface, UserServiceInterface } from '@authentic/loyalty-pwa/interfaces';
import { ContentPageServiceInterface } from '@authentic/loyalty-pwa/interfaces/content-page.interface';
import {
	HistoryServiceContext,
	LocationServiceContext,
	OfferServiceContext,
	RewardServiceContext,
	UserServiceContext,
	ReceiptServiceContext,
	ReferrerServiceContext,
} from '@authentic/loyalty-pwa/providers';
import { ContentPageServiceContext } from '@authentic/loyalty-pwa/providers/content-page.service.context';
import { HistoryServiceInterface } from '@authentic/loyalty-pwa/interfaces/history.service.interface';
import { ReceiptServiceInterface } from '@authentic/loyalty-pwa/interfaces/receipt.service.interface';
import { useState } from 'preact/hooks';
import { Modal } from '@authentic/loyalty-pwa/components/Modal/modal';
import FirefoxIcon from '@authentic/loyalty-pwa/icons/Firefox';
import ChromeIcon from '@authentic/loyalty-pwa/icons/Chrome';
import { IESupportStyled } from '@authentic/loyalty-pwa/app/app.style';
import { ReferrerServiceInterface } from '@authentic/loyalty-pwa/interfaces/refferer.service.interface';
import { GlobalStyle } from '@authentic/loyalty-pwa/design-system/style-guide/global-style';
import { ContentBlockServiceInterface } from '../interfaces/content-block.interface';
import { ContentBlockServiceContext } from '../providers/content-block.service.context';

export type AppProps = {
	children: ComponentChildren,
	locationService?: LocationServiceInterface,
	userService?: UserServiceInterface,
	rewardsService?: RewardServiceInterface,
	offersService?: OfferServiceInterface,
	contentPageService?: ContentPageServiceInterface,
	historyService?: HistoryServiceInterface,
	receiptService?: ReceiptServiceInterface,
	referrerService?: ReferrerServiceInterface,
	contentBlockService: ContentBlockServiceInterface
};

export const App = (props: AppProps) => {
	const [isIE, setIsIE] = useState(isIEBrowser())
	const [isModalActive, setIsModalActive] = useState(true);
	let app = props.children

	if (props.userService) {
		app = (<UserServiceContext.Provider value={props.userService}>{app}</UserServiceContext.Provider>)
	}

	if (props.locationService) {
		app = (<LocationServiceContext.Provider value={props.locationService}>{app}</LocationServiceContext.Provider>)
	}

	if (props.rewardsService) {
		app = (<RewardServiceContext.Provider value={props.rewardsService}>{app}</RewardServiceContext.Provider>)
	}

	if (props.offersService) {
		app = (<OfferServiceContext.Provider value={props.offersService}>{app}</OfferServiceContext.Provider>)
	}

	if (props.contentPageService) {
		app = (<ContentPageServiceContext.Provider value={props.contentPageService}>{app}</ContentPageServiceContext.Provider>)
	}

	if (props.contentBlockService) {
		app = (<ContentBlockServiceContext.Provider value={props.contentBlockService}>{app}</ContentBlockServiceContext.Provider>)
	}

	if (props.historyService) {
		app = (<HistoryServiceContext.Provider value={props.historyService}>{app}</HistoryServiceContext.Provider>)
	}
	if (props.receiptService) {
		app = (<ReceiptServiceContext.Provider value={props.receiptService}>{app}</ReceiptServiceContext.Provider>)
	}
	if (props.referrerService) {
		app = (<ReferrerServiceContext.Provider value={props.referrerService}>{app}</ReferrerServiceContext.Provider>)
	}

	const hideModal = () => {
		setIsModalActive(false);
	}

	return (
		<Fragment>
			<GlobalStyle/>
			{!isIE && (
				app
			)}
			{isIE && (
				<Modal isModalActive={isModalActive} callbackOnCloseModal={hideModal} showCloseButton={false}>
					<IESupportStyled>
						<h2>Your browser is unsupported</h2>
						<p>Looks like you’re using a browser that isn’t supported by our site. We recommend using one of these options for the best experience:</p>

						<div class="browser-icon-content">
							<div>
								<FirefoxIcon></FirefoxIcon>
								<a target="_blank" href="https://www.mozilla.org/en-US/firefox/browsers/">Mozilla Firefox</a>
							</div>

							<div>
								<ChromeIcon></ChromeIcon>
								<a target="_blank" href="https://www.google.com/chrome/">Google Chrome</a>
							</div>
						</div>
					</IESupportStyled>
				</Modal>
			)}
		</Fragment>
	)
}

const isIEBrowser = () => {
	const ua = navigator.userAgent;
	return ua.indexOf('MSIE') > -1 || ua.indexOf('Trident') > -1;
};
