import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';
import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const SpecialInterestsPageStyled = styled.div``;
export const SpecialInterestsPageContentStyled = styled.div`
  position: relative;
  padding: 24px 0;
  @media screen and (max-width: ${Breakpoints.XS}) {
  }
`;

export const SpecialInterestsDetailStyled = styled.div`
  position: relative;
  max-width: ${Breakpoints.ML};
  margin: 0 auto;
`;

export const SpecialInterestsContentDetailStyled = styled.div`
  position: relative;
  width: 1024px;
  margin: 0 auto;
  padding: 55px 24px;
  @media screen and (max-width: ${Breakpoints.XL}) {
    width: 100%;
    padding: 24px;
  }
  @media screen and (max-width: ${Breakpoints.XS}) {
    padding: 12px;
  }
`;

export const SpecialInterestsLayoutStyled = styled.div`
  z-index: 1;
  position: relative;
  text-align: center;
  p {
    font-size: 2rem;
    font-family: BrandonGrotesque-Medium;
    width: 640px;
    margin: 0 auto;
    @media screen and (max-width: ${Breakpoints.M}) {
      width: 100%;
      font-size: 1.7rem;
    }
    @media screen and (max-width: ${Breakpoints.XS}) {
      font-size: 1.5rem;
    }
  }
`;

export const SpecialInterestDetailStyled = styled.div`
  margin: 3em 0;
  h2 {
    text-align: left;
    font-size: 2em;
    color: ${colors.black};
    font-family: OpenSans-Bold;
    @media screen and (max-width: ${Breakpoints.L}) {
      text-align: center;
    }
  }
  .checkbox-field {
    flex: 0 0 33%;
    height: 5em;
    input {
      background: ${colors.white};
      border: 1px solid ${colors.grayVariant1} !important;
    }
    label span {
      color: ${colors.grayVariant1};
      /** Requirement: Reduce font of special interests by 2px.
        previous value: 1.2em ~ 15.7px
        new value: 1.05em ~ 13.7px
       */
      font-size: 1.05em;
      text-align: left;
      font-weight: 600;
      flex: 1;
    }
    @media screen and (max-width: ${Breakpoints.L}) {
      flex: 1 1 50%;
    }
    @media screen and (max-width: ${Breakpoints.XS}) {
      label span {
        font-size: 0.8em;
      }
    }
  }
`;

export const SpecialInterestItemsStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 1.5em;
  padding-left: 1.5em;
`;

export const SpecialInterestFormStyled = styled.form`
  .message {
    font-family: inherit;
    font-size: 1.5rem;
  }

  button {
    margin: 1em 0;
  }
`;
