import { h, RenderableProps, toChildArray } from 'preact';
import { Router as PreactRouter, RouterProps } from 'preact-router';
import { route } from '@authentic/loyalty-pwa/util';

export type props = {};
export function Router({children, ...props}: RenderableProps<RouterProps> & props) {
	if (process.env.CONTEXT_PATH) {
		toChildArray(children).forEach(child => {
			if (typeof child === 'object' && child.props && 'path' in child.props) {
				const path = (child.props as any).path as string
				(child.props as any).path = `${process.env.CONTEXT_PATH}${path}`
			}
		})
		if (!window.location.pathname.startsWith(process.env.CONTEXT_PATH)) {
			console.log('Redirecting to proper context path', window.location.pathname, window.location.pathname.startsWith(process.env.CONTEXT_PATH))
			setTimeout(() => route(window.location.pathname, true), 10)
		}
	}
	return <PreactRouter {...props}>
		{children}
	</PreactRouter>;
}