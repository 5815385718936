import { Reward, RewardServiceInterface, RewardsQuery, RewardsResponse } from "../../interfaces/reward.service.interface";
import { CheetahShared } from './cheetah.shared';

export class CheetahRewardService extends CheetahShared implements RewardServiceInterface {
    async getRewards(data: RewardsQuery): Promise<RewardsResponse> {
        return this.fetch({
			method: 'GET',
			url: '/api/rewards',
			queryParams: data 
		}, false).then((response) => {
			if (!response.ok) {
				return response.json().then((res) => ({ ...{
					success: false,
					error: res
				}}));
			}
			return response.json().then((res) => ({ ...{
				success: true,
				total_pages: res.data?.total_pages,
				total_entries: res.data?.total_entries,
				rewards: this.parseRewards(res.data.rewards)
			}}));
		})}

    setAccessToken(token: string) {
        this.access_token = token
    }

    private parseRewards(rewards: any): Reward[] {
        return rewards.map((reward: any) => this.parseReward(reward))
    }

    async redeemReward(rewardId: number): Promise<{ success: boolean }> {
		return this.fetch({
			url: `/api/rewards/${rewardId}/respond`,
		}, false).then((response) => {
			if (!response.ok) {
				return response.json().then((res) => ({ ...res, success: false }));
			}
			return response.json().then((res) => ({ ...res, success: true }));
		});
	}

	private parseReward(reward: any): Reward {
		return {
			id: reward.id,
			metric: reward.metric_name,
			start_date: reward.start_date,
			end_date: reward.response_end_date,
			title: reward.heading,
			subtitle: reward.subheading,
			content: reward.body,
			image_url: reward.image_url,
			thumbnail_url: reward.thumb_image_url,
			redeemable: reward.respondable,
			points: reward.metric_amount,
			respondable_info: reward.respondable_info,
			categories: (reward.categories && reward.categories.length > 0 ? reward.categories : []),
			detail: reward.extra_json,
			internal_name: reward.internal_name
		}
	}
}