import { getImageSrc } from "@authentic/loyalty-pwa/util";
import { BackgroundImage } from "@greencross/components/background-image/background-image.component";
import { Card } from "@greencross/components/card/card.style";
import { BaseLayout } from "@greencross/pages/shared/base-layout/base-layout";
import { h } from "preact";
import { PRIVACY_POLICY_BODY, PRIVACY_POLICY_HEADER } from "./privacy-policy.strings";
import { PrivacyPolicyPageStyled } from "./privacy-policy.style";

export const PrivacyPolicyPage = () => (
	<BaseLayout>
    	<PrivacyPolicyPageStyled>
    	    <BackgroundImage
	            desktop={`url(${getImageSrc('/assets/images/backgrounds/GXH_Background_Desktop_7_Family_8fc1d8.jpg')})`}
	            mobile={`url(${getImageSrc('/assets/images/backgrounds/GXH_Background_Mobile_7_Family_8fc1d8.jpg')})`}
	        />
			<Card>
	    		<h1>{PRIVACY_POLICY_HEADER}</h1>
	    		<p>{PRIVACY_POLICY_BODY}</p>
		    </Card>
    	</PrivacyPolicyPageStyled>
	</BaseLayout>
);