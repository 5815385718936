import { colors } from "@authentic/loyalty-pwa/design-system/style-guide/color";
import { LocationServiceContext } from "@authentic/loyalty-pwa/providers";
import { h } from "preact";
import { useContext, useEffect, useState } from "preact/hooks";
import styled from "styled-components";

export const AlertStyled = styled.div`
 	background-color: ${colors.orange};
 	color: ${colors.white};
 	text-align: center;
	font-size: 2rem;
	padding: 5px;
`;

export const Alert = () => {
	const start = 1684378800000
	const end = 1684407600000
	const now = Date.now()

	if (now < start || now > end)
		return null;

	return (
		<AlertStyled>
			Living Rewards is undergoing scheduled maintenance on Monday 18th May.
			<br />You can still sign up to join, but there’ll be a delay in receiving emails from us. Sorry for the inconvenience.
		</AlertStyled>
	)
}