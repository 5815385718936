import { CheetahShared } from "@authentic/loyalty-pwa/services/cheetah/cheetah.shared";

import {
	ReferrerServiceInterface,
	SubmitReferrerRequest, SubmitReferrerResponse,
} from '@authentic/loyalty-pwa/interfaces/refferer.service.interface';

export class ReferrerService extends CheetahShared implements ReferrerServiceInterface{

	async submitReferrer(referrer: SubmitReferrerRequest): Promise<{ success: SubmitReferrerResponse }> {
		return this.fetch({
			url: '/api/profile/referral_code',
			method: 'PUT',
			body: {
				...referrer
			}
		}, false).then((response) => {
			if (!response.ok) {
				return response.json().then((res) => ({ ...res, success: false }));
			}
			return response.json().then((res) => ({ ...res, success: true }));
		});
	}


}
