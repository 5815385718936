import { h } from 'preact';
import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';

export const BoldCloseIcon = () => (
  <svg
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Account-Deleted-Confirmation---Phase-2"
        transform="translate(-1023.000000, -935.000000)"
        fill={colors.primary}
        fill-rule="nonzero"
      >
        <g id="notification" transform="translate(376.000000, 915.000000)">
          <g id="X" transform="translate(642.000000, 15.000000)">
            <polygon
              id="Path"
              points="19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
