import styled from "styled-components";
import { breakpoints } from "@authentic/loyalty-pwa/util/variables";


export const ModalStyled = styled.div`
  background-color: gray;
  z-index: 3000;
  position: fixed;
  display: table;
  height:100%;
  width:100%;
  top: 0;
  left: 0;
  transform:scale(0);
  @keyframes fadeIn {
    0% {
      background:rgba(0,0,0,.0);
    }
    100% {
      background:rgba(0,0,0,.8);
    }
  }

  @keyframes fadeOut {
    0% {
      background:rgba(0,0,0,.8);
    }
    100% {
      background:rgba(0,0,0,.0);
    }
  }

  @keyframes scaleUp {
    0% {
      transform:scale(.8) translateY(1000px);
      opacity:0;
    }
    100% {
      transform:scale(1) translateY(0px);
      opacity:1;
    }
  }

  @keyframes scaleDown {
    0% {
      transform:scale(1) translateY(0px);
      opacity:1;
    }
    100% {
      transform:scale(.8) translateY(1000px);
      opacity:0;
    }
  }

  &.revealing {
    transform:scale(1);
    .modal-overlay {
      background: rgba(0,0,0,.0);
      animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }

    .modal-content {
      opacity:0;
      animation: scaleUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }

    &.out {
      animation: quickScaleDown 0s .5s linear forwards;
      .modal-overlay {
        animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
      .modal-content {
        animation: scaleDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
     }
    }
  }
`;

export const ModalContentStyled = styled.div`
  box-sizing: border-box;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 360px;
  max-width: 100%;
  min-height: 360px;
  padding: 24px;
  overflow: initial;
  display: block;
  margin-left: -180px;
  margin-top: -180px;

  .modal-close-button {
    cursor: pointer;
    svg {
      position: absolute;
      top: -28px;
      right: 0;
      display: block;
      width: 16px;
      height: 16px;
    }
  }
  @media screen and (max-width: ${breakpoints.xss} ) {
    width: 320px;
    margin-left: -160px;
  }
`;

export const ModalOverlayStyled = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.8);
  display:table-cell;
`;