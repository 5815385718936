import { CheetahShared } from "@authentic/loyalty-pwa/services/cheetah/cheetah.shared";
import {
	ReceiptRequest, ReceiptResponse,
	ReceiptServiceInterface,
	SubmitReceiptRequest,
} from '@authentic/loyalty-pwa/interfaces/receipt.service.interface';

export class ReceiptService extends CheetahShared implements ReceiptServiceInterface {
	async submitReceipt(data: FormData): Promise<ReceiptResponse> {
		return this.fetch({
			url: '/api/receipts',
			method: 'POST',
			body: data
		}, false).then((response) => {
			if (!response.ok) {
				return response.json().then((res) => ({ ...res, success: false }));
			}
			return response.json().then((res) => ({ ...res, success: true }));
		});
	}

	async registerClaim(data: ReceiptRequest): Promise<ReceiptResponse> {
		return this.fetch({
			url: '/api/orders/claim',
			body: {
				...data
			}
		}, false).then((response) => {
			if (!response.ok) {
				return response.json().then((res) => ({ ...res, success: false }));
			}
			return response.json().then((res) => ({ ...res, success: true }));
		});
	}
}
