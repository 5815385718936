import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';
import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const PharmacyLocatorPageStyled = styled.div``;
export const PharmacyLocatorPageContentStyled = styled.div`
  position: relative;
  padding: 24px 0;
  @media screen and (max-width: ${Breakpoints.XS}) {
  }
`;

export const PharmacyLocatorDetailStyled = styled.div`
  position: relative;
  max-width: ${Breakpoints.ML};
  margin: 0 auto;
`;

export const PharmacyLocatorContentDetailStyled = styled.div`
  position: relative;
  width: 1024px;
  margin: 0 auto;
  padding: 3rem;
  @media screen and (max-width: ${Breakpoints.XL}) {
    width: 96%;
    padding: 48px 24px;
  }
  @media screen and (max-width: ${Breakpoints.XL}) {
    width: 100%;
    padding: 24px;
  }
`;

export const PharmacyLocatorLayoutStyled = styled.div``;

export const PharmacyLocationSearchFormStyled = styled.form`
  display: flex;
  margin: 4em 0;
  > div {
    flex: 1;
  }
  > button {
    flex: 0.5;
    margin-left: 2em;
  }
  > div > div {
    margin-bottom: 0;
  }
  > div > div > div {
    border-bottom-width: 0;
  }
  input {
    border-top: 2px solid #d9d9d9;
    border-bottom: 2px solid #d9d9d9;
    font-family: BrandonGrotesque-Medium;
  }
  #enter_postocode::placeholder,
  #enter_service::placeholder {
    color: #666666;
    font-size: 1.9rem;
  }
`;

export const EnterPostcodeInputStyled = styled.div`
  input {
    border-left: 2px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-radius: 8px 0 0 8px;
  }
`;
export const EnterServiceInputStyled = styled.div`
  input {
    border-right: 2px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
    border-radius: 0 8px 8px 0;
  }
`;

export const PharmacyLocatorResultStyled = styled.div`
  display: flex;
`;

export const PharmacyLocatorResultLeftSectionStyled = styled.div`
  flex: 0 0 320px;
  text-align: left;
`;
export const PharmacyLocatorResultRightSectionStyled = styled.div`
  text-align: left;
  padding: 0 2em;
  flex: 1;
  .right-section--header {
    display: flex;
    h2 {
      font-size: 2rem;
      color: ${colors.black};
      font-family: BrandonGrotesque-Medium;
      margin: 0.5em;
    }
    a {
      font-size: 1.5rem;
      text-transform: uppercase;
      margin-left: auto;
      margin-top: 1em;
    }
  }
`;

export const PlaceItemStyled = styled.div`
  border-bottom: 3px solid #d9d9d9;
  .place-item--header {
    display: flex;
    align-items: center;
    h2 {
      font-family: OpenSans-Bold;
      color: ${colors.primary};
    }
    span {
      font-family: BrandonGrotesque-Medium;
      font-size: 1.5rem;
      margin-left: auto;
      display: block;
      color: ${colors.black};
    }
  }
  p {
    font-family: BrandonGrotesque-Medium;
    font-size: 1.7rem;
    width: 90%;
    margin-top: 0;
  }
  &.active {
    h2 {
      color: ${colors.black};
    }
  }
`;

export const PharmacyLocatorResultDetailStyled = styled.div`
  display: flex;
`;

export const PharmacyLocatorResultDetailLeftSectionStyled = styled.div`
  flex: 1;
`;
export const PharmacyLocatorResultDetailRightSectionStyled = styled.div`
  flex: 1;
`;

export const ResultDetailItem = styled.div`
  padding: 1em;
  h2 {
    font-family: OpenSans-Bold;
    color: ${colors.black};
    padding: 0.5em 0;
    margin: 0;
  }
  p {
    font-family: BrandonGrotesque-Medium;
    color: #666666;
    font-size: 1.5em;
    margin: 0;
  }
`;
