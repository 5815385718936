import { Fragment, FunctionalComponent, h } from 'preact';

interface UnauthenticatedWrapperProps {}
export const UnauthenticatedWrapper: FunctionalComponent<UnauthenticatedWrapperProps> = (props) => {
  
  return (
    <Fragment>
      {/** TODO: add here the website header */}
      {/** TODO: add here the website unauthenticated menu */}
      {props.children}
      {/** TODO: add here the website footer */}
    </Fragment>
  );
};
