import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';
import styled from 'styled-components';

export const SelectFieldStyled = styled.div``;

export const SelectorInputStyled = styled.input`
  width: 100%;
  border-radius: 0;
  outline: none;
  height: 52px;
  position: relative;
`;

export const SelectorInputContainerStyled = styled.div`
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 52px;
    cursor: pointer;
  }
`;

export const OptionsStyled = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.grayVariant7};
  padding: 0 0.8em;
  display: none;
  &.active {
    display: block;
  }
  span {
    cursor: pointer;
    width: 100%;
    display: block;
    padding: 1em;
    padding: 0.5em 0;
    border-bottom: 1px solid ${colors.black};
    color: #000000;
    &:nth-last-child(1) {
      border-bottom: none;
    }
  }
`;

export const ErrorStyled = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 6px;
`;