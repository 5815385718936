export const colors = {
  primary: '#F47D1F',
  primaryVariant: '#e0e3f7',
  secondary: '#0D0729',
  secondaryVariant: '#BE81EB',
  lightBlue: '#00AEEF',
  darkBlue: '#009FE1',
  orange: '#F47D1F',
  text: '#666666',
  grandmaBackground: '#349d5c',
	// we need meaningful color names
	white: '#FFFFFF', // surface, background
  black: '#000000',
  lightblack: '#272727',
  error: '#c2302f',
  gray: '#E5E9EB', // borders
  grayVariant1: '#666666',
  grayVariant2: '#D8D8D8',
  grayVariant3: '#CFCDD4',
  grayVariant4: '#D4D8E3',
  grayVariant5: '#F6F6F6',
  grayVariant6: '#E7EAF3',
  grayVariant7: '#707070',
  grayVariant8: '#dadada',
  grayVariant9: '#d9d9d9',
  green: '#018647',
  pink: '#ff00c1',
  steelBlue: '#4583ab',
  steelBlueVariant1: '#4488a4',
  steelBlueVariant2: '#4583ab',
  steelBlueVariant3: '#457cb1',
  darkSlateBlue: '#2a3387',
  darkSlateBlueVariant1: '#2e6daa',
  fuchsia: '#ff00c2',
  accentPurple: '#BE81EB',
  transparent: 'transparent',
};
