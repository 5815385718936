export const CHANGE_PASSWORD_TITLE = 'Change my Password';

export const FIELDS = {
    CURRENT_PASSWORD: {
        LABEL: 'Current Password',
        ID: 'currentPassword'
    },
    NEW_PASSWORD: {
        LABEL: 'New Password',
        ID: 'newPassword'
    },
    CONFIRM_PASSWORD: {
        LABEL: 'Confirm Password',
        ID: 'confirmPassword'
    }
};

export const VALIDATION_MESSAGES = {
    REQUIRED: 'This field is required',
    SAME_OLD_PASSWORD: 'Your new password should be different from the current password',
    DIFFERENT_PASSWORD: 'Confirm password should be equal to the new password',
}
export const SUCCESSFUL_MESSAGE = 'Your Password has been changed successfully!';
export const CHANGE_PASSWORD_LINK = '/change-password';
